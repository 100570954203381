var moment = require("moment");

var quarterSelectionModule = (function () {

    var init = function (monthNames, firstYear, lastYear) {
        this.firstYear = firstYear;
        this.lastYear = lastYear;
        this.monthNames = monthNames;
    }

    var getQuarters = function (quarter) {
        var m = moment.utc(quarter);
        m.subtract(12, "months");

        if (m.year() < this.firstYear) {
            m = moment.utc([this.firstYear - 1, 9, 1]);
        } else if (m.year() + 2 > this.lastYear - 1) {
            m = moment.utc([this.lastYear - 2, 9, 1]);
        }

        var quarters = [];

        for (var i = 0; i < 7; i++) {
            m.add(3, "months");
            quarters.push({ "text": serverReference.abbreviatedMonthNames[m.month()] + " '" + m.year() % 100, "isCurrent": m.isSame(quarter), "value": m.toISOString() });
        }

        return quarters;
    }

    return {
        getPages: getQuarters,
        init: init
    }
}());

export { quarterSelectionModule }

//module.exports = {
//    quarterSelectionModule
//}

