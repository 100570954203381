/*global formObj */

function getUTCDateFromWeek(inputweek, inputyear) {
    var dayspassed = ((inputweek - 1) * 7); //number of days passed in new year

    var newYear = new Date(Date.UTC(inputyear, 0, 1)); //ga naar de eerste dag in het jaar

    var firstMonday = new Date(Date.UTC(inputyear, 0, 1)); //ga naar de eerste dag in het jaar
    do {
        firstMonday.setUTCDate(firstMonday.getUTCDate() + 1);
    } while (firstMonday.getUTCDay() !== 1);

    var daysBetween = (firstMonday.getTime() - newYear.getTime()) / 864e5;
    if (daysBetween >= 4) {
        firstMonday.setUTCDate(firstMonday.getUTCDate() - 7);
    }

    var datefromweek = firstMonday;
    datefromweek.setUTCDate(datefromweek.getUTCDate() + dayspassed);

    return datefromweek;
}

soneTools.periodFilter_OnChange = function () {
    var divSelectionDate = document.getElementById("divSelectionDate");

    var i;

    var selectionStart = Start.GetValue();
    var selectionEnd = End.GetValue();

    var selPeriodFilter = document.getElementById("Period");
    var valPeriodFilter = parseInt(selPeriodFilter.options[selPeriodFilter.selectedIndex].value, 10);
    if (isNaN(valPeriodFilter)) {
        valPeriodFilter = 0;
    }

    var selQuarter_Holder = document.getElementById("quarter_Holder");
    var selQuarter = document.getElementById("quarter");
    var dtQuarter = parseInt(selQuarter.options[selQuarter.selectedIndex].value, 10);

    var selMonth_Holder = document.getElementById("month_Holder");
    var selMonth = document.getElementById("month");
    var dtMonth = parseInt(selMonth.options[selMonth.selectedIndex].value, 10);

    var selWeek_Holder = document.getElementById("week_Holder");
    var selWeek = document.getElementById("week");
    var dtWeek = parseInt(selWeek.options[selWeek.selectedIndex].value, 10);

    var selYear_Holder = document.getElementById("year_Holder");
    var selYear = document.getElementById("year");
    var dtYear = parseInt(selYear.options[selYear.selectedIndex].value, 10);

    var StartHolderDiv = document.getElementById("StartHolderDiv");
    var EndHolderDiv = document.getElementById("EndHolderDiv");

    switch (valPeriodFilter) {
        case 1: //year
            selQuarter_Holder.style.display = "none";
            selMonth_Holder.style.display = "none";
            selWeek_Holder.style.display = "none";
            selYear_Holder.style.display = "";
            StartHolderDiv.style.display = "none";
            EndHolderDiv.style.display = "none";

            Start.setYear(dtYear);
            End.setYear(dtYear + 1);

            if (divSelectionDate) {
                divSelectionDate.style.display = "";
            }

            break;
        case 2: //quarter
            selQuarter_Holder.style.display = "";
            selMonth_Holder.style.display = "none";
            selWeek_Holder.style.display = "none";
            selYear_Holder.style.display = "";
            StartHolderDiv.style.display = "none";
            EndHolderDiv.style.display = "none";

            Start.setYear(dtYear);
            Start.setMonth(dtQuarter - 1);
            Start.setDate(1);
            End.setValue(Start.getUnixEpoch());
            End.addMonths(3);

            if (divSelectionDate) {
                divSelectionDate.style.display = "";
            }

            break;
        case 3: //month
            selQuarter_Holder.style.display = "none";
            selMonth_Holder.style.display = "";
            selWeek_Holder.style.display = "none";
            selYear_Holder.style.display = "";
            StartHolderDiv.style.display = "none";
            EndHolderDiv.style.display = "none";

            Start.setYear(dtYear);
            Start.setMonth(dtMonth - 1);
            Start.setDate(1);
            End.setValue(Start.getUnixEpoch());
            End.addMonths(1);

            if (divSelectionDate) {
                divSelectionDate.style.display = "";
            }

            break;
        case 4: //week
            selQuarter_Holder.style.display = "none";
            selMonth_Holder.style.display = "none";
            selWeek_Holder.style.display = "";
            selYear_Holder.style.display = "";
            StartHolderDiv.style.display = "none";
            EndHolderDiv.style.display = "none";

            Start.setWeek(dtWeek, dtYear);
            End.setWeek(dtWeek + 1, dtYear);

            if (divSelectionDate) {
                divSelectionDate.style.display = "";
            }

            break;
        case 5: //day
            selQuarter_Holder.style.display = "none";
            selMonth_Holder.style.display = "none";
            selWeek_Holder.style.display = "none";
            selYear_Holder.style.display = "none";
            StartHolderDiv.style.display = "";
            EndHolderDiv.style.display = "none";

            End.setValue(Start.getUnixEpoch());
            End.addDays(1);

            if (divSelectionDate) {
                divSelectionDate.style.display = "";
            }

            break;
        case 6: //from
            selQuarter_Holder.style.display = "none";
            selMonth_Holder.style.display = "none";
            selWeek_Holder.style.display = "none";
            selYear_Holder.style.display = "none";
            StartHolderDiv.style.display = "";
            EndHolderDiv.style.display = "none";

            End.setValue(Start.getUnixEpoch());

            if (divSelectionDate) {
                divSelectionDate.style.display = "";
            }

            break;
        case 7: //until
            selQuarter_Holder.style.display = "none";
            selMonth_Holder.style.display = "none";
            selWeek_Holder.style.display = "none";
            selYear_Holder.style.display = "none";
            StartHolderDiv.style.display = "";
            EndHolderDiv.style.display = "none";

            End.setValue(Start.getUnixEpoch());

            if (divSelectionDate) {
                divSelectionDate.style.display = "";
            }

            break;
        case 8: //FromUntilAndIncluding
            selQuarter_Holder.style.display = "none";
            selMonth_Holder.style.display = "none";
            selWeek_Holder.style.display = "none";
            selYear_Holder.style.display = "none";
            StartHolderDiv.style.display = "";
            EndHolderDiv.style.display = "";

            //Set JQuery boxes
            selectionStart = Start.GetValue();
            selectionEnd = End.GetValue();

            if (divSelectionDate) {
                divSelectionDate.style.display = "";
            }

            break;

        case 9: //FromUntil
            selQuarter_Holder.style.display = "none";
            selMonth_Holder.style.display = "none";
            selWeek_Holder.style.display = "none";
            selYear_Holder.style.display = "none";
            StartHolderDiv.style.display = "";
            EndHolderDiv.style.display = "";

            //Set JQuery boxes
            selectionStart = Start.GetValue();
            selectionEnd = End.GetValue();

            if (divSelectionDate) {
                divSelectionDate.style.display = "";
            }

            break;

        case 10: //FromToday
        case 12: //Current year
        case 15: //Current quarter
        case 13: //Current month
        case 14: //Current day
            selQuarter_Holder.style.display = "none";
            selMonth_Holder.style.display = "none";
            selWeek_Holder.style.display = "none";
            selYear_Holder.style.display = "none";
            StartHolderDiv.style.display = "none";
            EndHolderDiv.style.display = "none";

            //Set JQuery boxes
            Start.setValue(Date.nowAsUTC().getTime());
            End.setValue(Date.nowAsUTC().getTime());

            if (divSelectionDate) {
                divSelectionDate.style.display = "none";
            }

            break;
        default: //Nofilter
            selQuarter_Holder.style.display = "none";
            selMonth_Holder.style.display = "none";
            selWeek_Holder.style.display = "none";
            selYear_Holder.style.display = "none";
            StartHolderDiv.style.display = "none";
            EndHolderDiv.style.display = "none";
            if (divSelectionDate) {
                divSelectionDate.style.display = "none";
            }
            break;
    }

    //Set the year select
    if (valPeriodFilter != 4) {
        for (i = 0; i <= selYear.length - 1; i = i + 1) {
            if (parseInt(selYear.options[i].value, 10) == Start.getYear()) {
                selYear.selectedIndex = i;
                break;
            }
        }
    }

    //Set the quarter select
    if ((Start.getMonth() + 1) < 4) {
        selQuarter.selectedIndex = 0;
    }
    if (((Start.getMonth() + 1) >= 4) && ((Start.getMonth() + 1) < 7)) {
        selQuarter.selectedIndex = 1;
    }
    if (((Start.getMonth() + 1) >= 7) && ((Start.getMonth() + 1) < 10)) {
        selQuarter.selectedIndex = 2;
    }
    if ((Start.getMonth() + 1) >= 10) {
        selQuarter.selectedIndex = 3;
    }

    //Set the month select
    for (i = 0; i <= selMonth.length - 1; i = i + 1) {
        if (parseInt(selMonth.options[i].value, 10) === (Start.getMonth() + 1)) {
            selMonth.selectedIndex = i;
            break;
        }
    }

    //Set the week select
    selWeek.selectedIndex = Start.getWeek() - 1;

}
