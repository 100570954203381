import React from "react";
import { createRoot } from "react-dom/client";
import Sidebar from "./supportbeacon/sidebar";

// TODO: This is currently only used by MainWebUI. We can remove this after we get rid of MainWebUI.
jQuery(() => {
  const sidebarEl = document.getElementById("support-beacon-sidebar");

  if (sidebarEl) {
    const root = createRoot(sidebarEl);
    root.render(
      <Sidebar />
    );
  }
});
