
declare const $: any;

interface JQuery {
    appendA(options: any): JQuery;
}

$.fn.appendA = function (options: any) {
    const obj$ = $("<a />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendButton(options: any): JQuery;
}

$.fn.appendButton = function (options: any): JQuery {
    const obj$ = $("<button />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendDiv(options: any): JQuery;
    appendFieldset(options: any): JQuery;
    appendH1(options: any): JQuery;
    appendH2(options: any): JQuery;
    appendH3(options: any): JQuery;
    appendH4(options: any): JQuery;
}

$.fn.appendDiv = function (options: any): JQuery {
    const obj$ = $("<div />", options);
    this.append(obj$);
    return obj$;
};

$.fn.appendFieldset = function (options: any): JQuery {
    const obj$ = $("<fieldset />", options);
    this.append(obj$);
    return obj$;
};

$.fn.appendH1 = function (options: any): JQuery {
    const obj$ = $("<h1 />", options);
    this.append(obj$);
    return obj$;
};

$.fn.appendH2 = function (options: any): JQuery {
    const obj$ = $("<h2 />", options);
    this.append(obj$);
    return obj$;
};

$.fn.appendH3 = function (options: any): JQuery {
    const obj$ = $("<h3 />", options);
    this.append(obj$);
    return obj$;
};

$.fn.appendH4 = function (options: any): JQuery {
    const obj$ = $("<h4 />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendI(options: any): JQuery;
}

$.fn.appendI = function (options: any): JQuery {
    const obj$ = $("<i />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendIAfter(options: any): JQuery;
}

$.fn.appendIAfter = function (options: any): JQuery {
    const obj$ = $("<i />", options);
    this.after(obj$);
    return obj$;
};

interface JQuery {
    appendImg(options: any): JQuery;
}

$.fn.appendImg = function (options: any): JQuery {
    const obj$ = $("<img />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendInput(options: any): JQuery;
}

$.fn.appendInput = function (options: any): JQuery {
    const obj$ = $("<input />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendLabel(options: any): JQuery;
}

$.fn.appendLabel = function (options: any): JQuery {
    const obj$ = $("<label />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendLi(options: any): JQuery;
}

$.fn.appendLi = function (options: any): JQuery {
    const obj$ = $("<li />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendOption(options: any): JQuery;
}

$.fn.appendOption = function (options: any): JQuery {
    const obj$ = $("<option />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendSelect(options: any): JQuery;
}

$.fn.appendSelect = function (options: any): JQuery {
    const obj$ = $("<select />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendSpan(options: any): JQuery;
}

$.fn.appendSpan = function (options: any): JQuery {
    const obj$ = $("<span />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendStrong(options: any): JQuery;
}

$.fn.appendStrong = function (options: any): JQuery {
    const obj$ = $("<strong />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendTable(options: any): JQuery;
}

$.fn.appendTable = function (options: any): JQuery {
    const obj$ = $("<table />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendTbody(options: any): JQuery;
}

$.fn.appendTbody = function (options: any): JQuery {
    const obj$ = $("<tbody />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendTd(options: any): JQuery;
}

$.fn.appendTd = function (options: any): JQuery {
    const obj$ = $("<td />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendThead(options: any): JQuery;
}

$.fn.appendThead = function (options: any): JQuery {
    const obj$ = $("<thead />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendTextarea(options: any): JQuery;
}

$.fn.appendTextarea = function (options: any): JQuery {
    const obj$ = $("<textarea />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendTh(options: any): JQuery;
}

$.fn.appendTh = function (options: any): JQuery {
    const obj$ = $("<th />", options);
    this.append(obj$);
    return obj$;
};



$.fn.appendTr = function (options: any) {
    const obj$ = $("<tr />", options);
    this.append(obj$);
    return obj$;
};

interface JQuery {
    appendUl(options: any): JQuery;
}

$.fn.appendUl = function (options: any): JQuery {
    const obj$ = $("<ul />", options);
    this.append(obj$);
    return obj$;
};

// Turn it into a module to satisfy `isolatedModules`
export { }