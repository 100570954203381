$.widget("sone.documentphaseedit", {
    options: {

    },
    _create: function () {

        var dateCompleteHolder$ = $("#DateCompleteHolderDiv");
        if (!dateCompleteHolder$.length) {
            throw "Could not bind to #DateCompleteHolderDiv";
        }

        var phaseReasonHolderDiv$ = $("#PhaseReasonHolderDiv");
        if (!phaseReasonHolderDiv$.length) {
            throw "Could not bind to #PhaseReasonHolderDiv";
        }

        $(this.element).change(function () {
            var phaseId = parseInt($(this).val(), 10);

            switch (phaseId) {
            case 1: //Proforma
            case 2: //Open
                $(dateCompleteHolder$).hide();
                $(phaseReasonHolderDiv$).hide();
                break;

            case 3: //Closed
            case 4: //Cancelled
                $(dateCompleteHolder$).show();
                $(phaseReasonHolderDiv$).show();
                break;

            default:
                throw 'documentphaseedit received an unexpected reponse.';
            }
        });
    }
});