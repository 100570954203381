declare const $: any;

$.fn.appendDotLabel = function (settings) {
  const config = {
    hexColor: "#000000",
    label: "label",
    smalltext: false
  };

  if (settings) {
    $.extend(config, settings);
  }

  function appendDot(element, hexColor) {
    const dotHolder$ = $("<div />", {
      class: "s1_cell",
      style: "padding-right:5px;"
    });
    element.append(dotHolder$);

    dotHolder$.appendSpan({
      class: "icon-circle",
      style: "color:" + hexColor + ";"
    });
  }

  function appendLabel(element, label) {
    const holder$ = $("<div />", {
      class: "s1_cell"
    });
    element.append(holder$);

    if (config.smalltext) {
      holder$.addClass("s1_small");
    }

    holder$.append($("<span />", {
      text: label
    }));
  }

  return this.each(() => {
    const this$ = $(this);

    this$.css("display", "table");
    appendDot(this$, config.hexColor);
    appendLabel(this$, config.label);
  });
};

// Turn it into a module to satisfy `isolatedModules`
export {};