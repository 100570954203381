$.widget("sone.resetpassword", {
    options: {
        values: []
    },
    _create: function () {
        var that = this;

        this.dialog$ = null;

        this.element.click(function() {
            
            that.dialog$ = $("<div />", {
                title: RESX.SoUser.ResetPassword,
                text: RESX.GeneralWarnings.AreYouSure
                })
                .dialog2({
                    autoOpen: true,
                    width: 400,
                    height: 300,
                    closeOutside: true,
                    buttons: [
                            {
                            text: RESX.SoUser.ResetPassword,
                            "class": "button-new primary",
                            click: function () {
                                that._doReset();
                            }
                        }]
                });

        });
    },
    _doReset: function () {
        var that = this;

        that.dialog$.dialog2('option', 'buttons', {});

        var userId = that.element.attr("data-userid");
        
        WebApi.Users.ResetPassword(userId).done(
            function (mssg) {
                that.dialog$.html(mssg);
                setTimeout(function () { that.dialog$.remove(); }, 4000);
            }
        );
    }
}); 