declare const $: any;
declare const viewModel: server.viewmodel.ClientDetailViewModel;

$.widget("sone.customtranslations", $.sone.panelwidgetbase, {
    options: {
    },
    _create: function () {
        this._super();
        this._setHeaderLabel("Custom translations");
    },
    _openDialog: function () {
        const that = this;

        if (!this.dialog$) {

            this.dialog$ = $("<div />", {
                title: "Translation",
                "style": "display:none;"
            }).dialog2({
                width: 600,
                height: 500,
                buttons: [{
                    text: RESX.Buttons.BtnOk,
                    "class": "button-new primary",
                    click: function () {
                        that._store();
                    }
                }]
            });
            this._addDialogContent();
        }

        this.dialog$.dialog2("open");
    },
    _addDialogContent: function () {
        const that = this;

        this._appendTranslation();
        this._appendLanguage();
        this._appendValue();

        this.translation$.change(function () {
            that._fillData();
        });

        this.language$.change(function () {
            that._fillData();
        });

        this._fillData();
    },
    _appendTranslation: function () {
        const div$ = this.dialog$.appendDiv({
            "style": "margin-bottom:10px;"
        });

        div$.appendLabel({
            "for": "translation",
            "text": "Translation for:"
        });
        this.translation$ = this._getTranslationSelect();
        div$.append(this.translation$);

    },
    _getTranslationSelect: function () {
        const that = this;
        const select$ = $("<select />", {
            "id": "translation"
        });

        $.each(viewModel.translationOptions, function (index, o) {
            select$.appendOption({
                "value": o.value,
                "text": `${o.description} (${o.value})`
            });
        });

        return select$;
    },
    _appendLanguage: function () {
        const div$ = this.dialog$.appendDiv({
            "style": "margin-bottom:10px;"
        });

        div$.appendLabel({
            "for": "language",
            "text": "Language:"
        });
        this.language$ = this._getLanguageSelect();
        div$.append(this.language$);
    },
    _getLanguageSelect: function () {

        const select$ = $("<select />", {
            "id": "language"
        });

        $.each(window.serverReference.languages, function (index, lang) {
            select$.appendOption({
                "value": lang.value,
                "text": lang.localizedName
            });
        });

        return select$;
    },
    _appendValue: function () {
        const div$ = this.dialog$.appendDiv({
            "style": "margin-bottom:10px;"
        });

        div$.appendLabel({
            "for": "value",
            "text": "Value:"
        });
        this.value$ = this._getValue();
        div$.append(this.value$);
    },
    _getValue: function () {
        const that = this;
        const input$ = $("<input />", {
            "type": "text",
            "id": "value",
            "keypress": function (e) {
                if (e.which === 13) {
                    that._store();
                    return false;
                }
            }
        });

        return input$;
    },
    _fillData: function () {

        const that = this;
        const clientId = viewModel.client.clientId;
        const translation = this.translation$.val();
        const language = this.language$.val();

        window.CoreApi.CustomTranslation.GetTranslation(clientId, translation, language).then(
            function (result) {
                that.value$.val(result.data.value);
                that.value$.attr("placeholder", result.data.default);
            });
    },
    _store: function () {
        const that = this;
        const clientId = viewModel.client.clientId;

        const instruction: server.dto.StoreCustomTranslationInstruction = {
            clientId: clientId,
            translation: that.translation$.val(),
            language: that.language$.val(),
            value: that.value$.val()
        };

        window.CoreApi.CustomTranslation.Store(instruction)
            .then(
                function (data) {
                    location.reload();
                });
    },
    _onAddButtonClick: function () {
        this._openDialog();
    },
    requeryServer: function () {
        /**/
    }
});

// Turn it into a module to satisfy `isolatedModules`
export { }