require("./base.panel");

$.widget('sone.artistsinwebform', $.sone.panelwidgetbase, {
    options: {
        labels: {
            "nodata": RESX.SoUser.AtLeast1ArtistShould
        },
        parentId: null,
        chooseUrl: null,
        required: true,
        apiGet: "WebApi.Webforms.ListArtists",
        apiLink: "WebApi.Webforms.InsertArtistLink",
        apiUnlink: "WebApi.Webforms.DeleteArtistLink",
        webformLink: "provide"
    },
    _create: function () {
        this._super();
    },
    _drawNoData: function () {
        this.content$.appendDiv({
            'text': this.options.labels.nodata,
            'class': 'ui message red'
        });
    },
    _appendBodyRow: function (tbody$, companyObj) {
        var that = this;

        var tr$ = $('<tr />', {});
        tbody$.append(tr$);

        var tdLabel$ = tr$.appendTd();

        tdLabel$.appendDiv({
            'text': companyObj.name
        });

        tdLabel$.appendA({
            "text": that._getArtistLink(companyObj),
            "href": that._getArtistLink(companyObj),
            "class": "s1_small",
            "target": "_blank",
        });

        var tdValue$ = tr$.appendTd({
            'class': 's1_button_cell',
            "click": function () {
                that._unlink(companyObj.artistId);
            }
        });

        tdValue$.appendA({
            "class": "icon-trash"
        });
    },
    getKeys: function () {
        return this.collection.map(function (obj) {
            return obj.artistId;
        });
    },
    _getArtistLink: function (obj) {
        return this.options.webformLink + '&artist=' + obj.artistId;
    },
    _onAddButtonClick: function () {
        var that = this;

        if (!this.chooseDialog$) {
            this.chooseDialog$ = $("<div />").chooseartistdialog();
        }

        this.chooseDialog$.chooseartistdialog("open", this.getKeys()).done(
            function (artist) {
                that._link(artist.artistId);
            });
    },
    _link: function (id) {
        var that = this;

        soneTools.executeFunctionByName(this.options.apiLink, window, this.options.parentId, id)
            .done(
                function () {
                    that.requeryServer();
                }
            );
    },
    _unlink: function (id) {
        var that = this;

        soneTools.executeFunctionByName(this.options.apiUnlink, window, this.options.parentId, id)
            .done(
                function () {
                    that.requeryServer();
                }
            );
    },
    validate: function () {
        var that = this;

        var companyIds = this.getKeys();
        if (that.options.required && companyIds.length === 0) {

            this.alert$ = $("<div />", {
                title: RESX.GeneralWarnings.Warning,
                text: RESX.SoUser.AtLeast1ArtistShould
            }).dialog2({
                width: 350,
                height: 250,
                autoOpen: true,
                buttons: [{
                    text: RESX.Buttons.BtnOk,
                    "class": "button-new primary",
                    click: function () {
                        $(this).dialog2("close");
                    }
                }],
                close: function () {
                    $(this).dialog2('destroy').remove();
                }
            });

            formObj.setHasError();
            return false;
        }

        return true;
    },
    requeryServer: function () {
        var that = this;

        this._displaySpinner();

        soneTools.executeFunctionByName(this.options.apiGet, window, this.options.parentId)
            .done(
                function (collection) {
                    that._setData(collection);
                }
            );
    }
});