$.widget("sone.companiesmodifydialog", $.sone.dialog2, {
    options: {
        showTitle: false,
        closeOutside: true,
        autoHeight: true,
        width: 500
    },
    _create: function () {
        this._super();

        this._appendKeepLink();
        this._appendReactivateLink();
        this._appendRemoveLink();
    },
    _appendKeepLink: function () {

        var that = this;

        this.keepButton$ = $("<button />", {
            "type":"button",
            "class": "button-new primary",
            "text": RESX.Company.KeepTheLink,
            "style": "margin-bottom:10px !important;",
            "click": function () {
                that.close();

                window.CoreApi.CompanyToContact.SetLink({ 
                    companyId: that.companyId,
                    contactId: that.options.contactId,
                    isActive: false,
                    defaultSelect: false
                }).then(
                    function () {
                        that._triggerSaveCompleted();
                    });
            }
        });

        this.element.append(this.keepButton$);
    },
    _appendReactivateLink: function () {
        var that = this;

        this.reactivateButton$ = $("<button />", {
            "type": "button",
            "class": "button-new primary",
            "text": RESX.Company.ReactivateTheLink,
            "style": "margin-bottom:10px !important;",
            "click": function () {
                that.close();

                window.CoreApi.CompanyToContact.SetLink({ 
                    companyId: that.companyId,
                    contactId: that.options.contactId,
                    isActive: true,
                    defaultSelect: false
                }).then(
                    function () {
                        that._triggerSaveCompleted();
                    });
            }
        });

        this.element.append(this.reactivateButton$);
    },
    _appendRemoveLink: function () {
        var that = this;

        var button$ = $("<button />", {
            "type": "button",
            "class": "button-new tertiary",
            "text": RESX.Company.RemoveLinkBetweenThisCompanyAndContact,
            "click": function () {
                that.close();

                window.CoreApi.CompanyToContact.DeleteLink({ 
                    companyId: that.companyId,
                    contactId: that.options.contactId
                }).then(
                    function () {
                        that._triggerSaveCompleted();
                    });
            }
        });

        this.element.append(button$);
    },
    openModify: function (item) {
        this.keepButton$.toggle(item.isActive);
        this.reactivateButton$.toggle(!item.isActive);

        this.setTitle(item.name);
        this.companyId = item.companyId;
        this.open();
    }
});