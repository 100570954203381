// This file has top-level exports
// Webpack will add these to the global scope
// Like SystemOneLibrary.exports.Utils etc
// This happens due to the "library" prop in webpack.base.js

import { Utils } from "./common/utils";
import { DocumentIndicationsCounter } from "../helpers/documentindicationshelper";

export const exports = {
  Utils,
  DocumentIndicationsCounter
};