import React from "react";
import SystemOneModal, { type ModalWidth } from "../components/modal";

/**
 * This component is used as a non-React way of displaying a deletion confirmation modal
 * This should be a simple replacement for the "Are you sure you want to delete" that was done in jQuery
 */

const defaultSize: ModalWidth = "mini";

export interface DeleteModalConfig {
  title?: string
  message?: string
  size?: ModalWidth
  onDeleteHandler: () => Promise<any>
}

const DeleteConfirmationModal = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [config, setConfig] = React.useState<DeleteModalConfig>({
    title: RESX.Buttons.btnDelete,
    message: RESX.GeneralWarnings.DeleteSure,
    size: defaultSize,
    onDeleteHandler: undefined
  });
  const [isDeleting, setIsDeleting] = React.useState(false);

  (window as any).displayDeleteConfirmationModal = (config: DeleteModalConfig) => {
    const newConfig: DeleteModalConfig = {
      title: config.title || RESX.Buttons.btnDelete,
      message: config.message || RESX.GeneralWarnings.DeleteSure,
      size: config.size || defaultSize,
      ...config
    };

    setConfig(newConfig);
    setIsOpen(true);
    setIsDeleting(false);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onDelete = async () => {
    setIsDeleting(true);
    try {
      await config.onDeleteHandler();
    } catch {
    }
    setIsDeleting(false);
    setIsOpen(false);
  };

  const { title, size, message } = config;

  return <SystemOneModal
    isOpen={isOpen}
    title={title}
    width={size}
    handleClose={onClose}
  >
    <div className="grow1">
      {message}
    </div>
    <div className="flex margin-top-big justify-end">
      <div>
        <button
          type="button"
          className="button-new warning"
          onClick={onDelete}
          disabled={isDeleting}
        >
          {RESX.Buttons.btnDelete}
        </button>
      </div>
    </div>
  </SystemOneModal>;
};

export default DeleteConfirmationModal;
