declare const $: any;

$.fn.appendToggleIcon = function (cssClass: string, value: string, title: string) {
  let selected = true;
  const internalValue = value;

  const icon$ = $(this).appendA({
    title,
    class: cssClass,
    click() {
      toggle();
    }
  });

  const val = () => ({
    selected,
    value: internalValue
  });

  const set = (isSelected) => {
    selected = isSelected;
    icon$.toggleClass("s1_transparent", !selected);
  };

  function toggle() {
    set(!selected);
  }

  this.val = val;
  this.set = set;
  this.icon$ = icon$;
  return this;
};

// Turn it into a module to satisfy `isolatedModules`
export {};