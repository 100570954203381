/*global UI, formObj, DateTools */
UI.StartEndDateTimeFn = function (options) {
    var settings;

    // Set the defaults and apply supplied options
    var defaults = {
        DisplayIsAllDayInput: false,
        IsAllDay: false,
        UseIsNightInput: false,
        DisplayIsNightInput: false,
        DisplayTimeOfPerformanceInput: false
    };

    settings = $.extend({}, defaults);

    if (options) {
        settings = $.extend(settings, options);
    }

    $.extend(this, settings);

    this.IsAllDayInput = null;

    this.IsNight = false;
    this.IsNightInput = null;
    this.isNightBookingThresholdHour = 7;

    this.TimeOfPerformanceInput = null;

    this.Labels = {};
};

UI.StartEndDateTimeFn.prototype.Init = function (datePicker1, datePicker2) {
    var obj = this;
    this.date1 = datePicker1;
    this.date2 = datePicker2;

    this.IsAllDayInput = document.getElementById('IsAllDay');

    this.TimeOfPerformanceInput = document.getElementById('TimeOfPerformance');

    this.IsNightInputInit();

    if (this.DisplayIsAllDayInput) {
        $(this.IsAllDayInput).on('click', function (e) {
            obj.IsAllDay_OnClick(e.checked);
            obj.OnChange();
        });
    }

    if (this.UseIsNightInput) {
        $(this.IsNightInput).on('click', function () {
            obj.TryCorrectEnd();
            obj.SetLabels();
            obj.OnChange();
        });
    }

    $(this.date1).on('change', function (e) {
        obj.Dt1_OnChange(e.source);
        obj.OnChange();
    });

    $(this.date2).on('change', function (e) {
        obj.Dt2_OnChange(e.source);
        obj.OnChange();
    });

    this.SetLabels();
};
UI.StartEndDateTimeFn.prototype.SetLabels = function () {
    this.SetTimeOfPerformance();
    this.SetRealDateDiv();
    this.SetDurationDiv();

    this.RenderIsNightInput();
};
UI.StartEndDateTimeFn.prototype.IsAllDay_OnClick = function () {
    var dt1, parsedTimes;

    this.IsAllDay = this.IsAllDayInput.checked;
    this.DisplayIsNightInput = false;

    this.date1.HideWarning();
    this.date2.HideWarning();

    //Render objects
    if (this.IsAllDay) {
        this.IsNight = false;
        this.DisplayIsNightInput = false;
    } else {
        parsedTimes = this.ParseTimesFromTimeOfPerformance(this.TimeOfPerformanceInput ? this.TimeOfPerformanceInput.value : null);
        if (parsedTimes && parsedTimes.length === 1) {
            this.date1.setTimeOfDay(parsedTimes[0].hours, parsedTimes[0].minutes);
            this.date2.setValue(this.date1.getUnixEpoch());

            //dt1 = this.date1.getLocalisedDate();
            dt1 = this.date1.getDateTime();

            if (dt1.getUTCHours() <= this.isNightBookingThresholdHour) {
                this.IsNight = true;
                this.DisplayIsNightInput = true;
            }

            this.TryCorrectEnd();
        } else if (parsedTimes && parsedTimes.length === 2) {
            this.date1.setTimeOfDay(parsedTimes[0].hours, parsedTimes[0].minutes);
            //dt1 = this.date1.getLocalisedDate();
            dt1 = this.date1.getDateTime();

            this.date2.setTimeOfDay(parsedTimes[1].hours, parsedTimes[1].minutes);

            if (parsedTimes[1] === '00:00') {
                this.date2.addDays(1);
            }

            if (dt1.getUTCHours() <= this.isNightBookingThresholdHour) {
                this.IsNight = true;
                this.DisplayIsNightInput = true;
            }

            this.TryCorrectEnd();
        } else {
            this.date1.setTimeOfDay(10, 0);
            this.date2.setTimeOfDay(11, 0);
        }
    }

    //Set Date1 parameters
    this.date1.showTimeInput(!this.IsAllDay);

    //Set Date2 parameters
    this.date2.showTimeInput(!this.IsAllDay);

    this.SetLabels();
};
UI.StartEndDateTimeFn.prototype.ParseTimesFromTimeOfPerformance = function (timeOfPerformance) {
    if (!this.DisplayTimeOfPerformanceInput) { return; }

    var result = [], i, d, firstRegex, time;

    firstRegex = timeOfPerformance.match(/\d{1,2}[:h.]?\d{0,2}\s*[pPaA]?/g);
    if (!firstRegex) {
        return null;
    }
    firstRegex = firstRegex.slice(0, 2);

    for (i = 0; i < firstRegex.length; i++) {
        d = new Date();
        time = firstRegex[i].match(/(\d{1,2})[:h.]?(\d\d)?\s*([pPaA]?)/);

        if (!time) {
            result[i] = null;
            continue;
        }
        if (time[3]) {
            if ((time[3].toLowerCase() === 'a' && parseInt(time[1], null) === 12)) {
                d.setHours(parseInt(time[1], null) - 12);
            } else if (time[3].toLowerCase() === 'p') {
                d.setHours(parseInt(time[1], null) + 12);
            } else {
                d.setHours(parseInt(time[1], null));
            }
        } else {
            d.setHours(parseInt(time[1], null));
        }

        d.setMinutes(parseInt(time[2], null) || 0);

        result[i] = {
            timeString: ("00" + d.getHours()).slice(-2) + Date.TimeSeparator + ("00" + d.getMinutes()).slice(-2),
            hours: d.getHours(),
            minutes: d.getMinutes()
        };
    }

    function isNull(element) {
        return (!element);
    }

    if (result.every(isNull)) {
        return null;
    }

    return result;
};
UI.StartEndDateTimeFn.prototype.Dt1_OnChange = function (source) {
    this.date1.HideWarning();
    //var dt1 = source.getLocalisedDate();
    var dt1 = source.getDateTime();

    if (this.IsAllDay) {
        this.date2.setValue(source.getUnixEpoch());
    } else {
        this.date2.setValue(source.getUnixEpoch());
        this.date2.addHours(1);

        if (this.UseIsNightInput) {
            if (!this.DisplayIsNightInput && (dt1.getUTCHours() <= this.isNightBookingThresholdHour)) {
                this.IsNight = true;
                this.DisplayIsNightInput = true;
            } else if (dt1.getUTCHours() > this.isNightBookingThresholdHour) {
                this.IsNight = false;
                this.DisplayIsNightInput = false;
            }
        }
    }

    this.SetLabels();
};
UI.StartEndDateTimeFn.prototype.Dt2_OnChange = function () {
    this.date2.HideWarning();
    this.TryCorrectEnd();
    this.SetLabels();
};
UI.StartEndDateTimeFn.prototype.OnChange = function () {
    var e = jQuery.Event("mychange");
    $("#StartEndDateTime_row").trigger(e);
};
UI.StartEndDateTimeFn.prototype.TryCorrectEnd = function () {
    if (this.IsAllDay) { return; }

    var dt1, dt2, maxDiff, diff;

    // Gather dates
    //dt1 = this.date1.getLocalisedDate();
    dt1 = this.date1.getDateTime();
    if (this.IsNight) { return; }

    //dt2 = this.date2.getLocalisedDate();
    dt2 = this.date2.getDateTime();

    // See if the endDate should be corrected with a day.
    if (dt2 > dt1) { return; }
    if (dt1.getUTCHours() <= dt2.getUTCHours()) { return; }
    maxDiff = 23 * 60 * 60 * 1000;
    diff = (dt1.getTime() - dt2.getTime());
    if (diff < maxDiff) {
        this.date2.addDays(1);
    }
};
UI.StartEndDateTimeFn.prototype.SetDurationDiv = function () {
    var dt1, dt2, realStart, realEnd, message;
    //Get reference
    var durationDivObj = $('#DurationDiv');
    durationDivObj.css("margin-top", "5px");
    // Gather dates
    dt1 = this.date1.getDateTime();
    realStart = (this.IsNight) ? dt1.addDays(1) : dt1;
    dt2 = this.date2.getDateTime();
    realEnd = (this.IsAllDay || (this.IsNight && (dt1.getUTCDate() === dt2.getUTCDate()))) ? dt2.addDays(1) : dt2;

    if (this.IsAllDay) {
        // We only want to calculate full days!
        realStart = new Date(realStart.getUTCFullYear(), realStart.getUTCMonth(), realStart.getUTCDate());
        realEnd = new Date(realEnd.getUTCFullYear(), realEnd.getUTCMonth(), realEnd.getUTCDate());

        message = SystemOneLibrary.exports.Utils.timeSpanToWrittenTime(realEnd, realStart);
    } else {
        message = SystemOneLibrary.exports.Utils.timeSpanToWrittenTime(dt2, dt1);
    }

    durationDivObj.html((message) ? '(' + message + ')' : '');
};
UI.StartEndDateTimeFn.prototype.SetTimeOfPerformance = function () {
    if (!this.DisplayTimeOfPerformanceInput) { return; }

    var dt1AsString, dt2AsString, localDate1, localDate2;

    if (this.IsAllDay) {
        this.TimeOfPerformanceInput.disabled = false;
    } else {
        this.TimeOfPerformanceInput.disabled = true;

        //        localDate1 = this.date1.getLocalisedDate();
        //        localDate2 = this.date2.getLocalisedDate();
        localDate1 = this.date1.getDateTime();
        localDate2 = this.date2.getDateTime();
        dt1AsString = this.date1.GetTimeString();
        dt2AsString = this.date2.GetTimeString();

        if (dt1AsString === dt2AsString) {
            this.TimeOfPerformanceInput.value = dt1AsString;
        } else if (localDate2.getTime() < localDate1.getTime()) {
            this.TimeOfPerformanceInput.value = '';
        } else {
            this.TimeOfPerformanceInput.value = dt1AsString + ' - ' + dt2AsString;
        }
    }
};
UI.StartEndDateTimeFn.prototype.SetRealDateDiv = function () {
    var realDateDivObj, dt1, dt2, realStart, realEnd, message, v0, v1;
    //Get reference
    realDateDivObj = $('#RealDateDiv');

    // Gather dates
    //dt1 = this.date1.getLocalisedDate();
    dt1 = this.date1.getDateTime();
    realStart = (this.IsNight) ? dt1.addDays(1) : dt1;

    //dt2 = this.date2.getLocalisedDate();
    dt2 = this.date2.getDateTime();
    realEnd = (this.IsNight && (dt1.getUTCDate() === dt2.getUTCDate())) ? dt2.addDays(1) : dt2;

    realDateDivObj.removeClass('s1_red');
    realDateDivObj.css("margin-top", "5px");

    message = '';
    if (realEnd < realStart) {
        realDateDivObj.addClass('s1_red');
        realDateDivObj.html(RESX.DateTime.TheEndDateIsBeforeTheBeginDate);
        return;
    } else if (this.IsNight) {
        // realDateDivObj.css('color', 'black');

        //Prepare variables
        v0 = serverReference.dayNames[dt1.getUTCDay()] + ', ' + dt1.getUTCDate() + ' ' + serverReference.monthNames[dt1.getUTCMonth()];
        v1 = serverReference.dayNames[realStart.getUTCDay()] + ', ' +
            realStart.getUTCDate() + ' ' +
            serverReference.monthNames[realStart.getUTCMonth()] + ' ' +
            this.date1.GetTimeString();
        if (realStart.getTime() !== realEnd.getTime()) {
            v1 += ' - ';
        }
        if (realStart.getUTCDate() !== realEnd.getUTCDate()) {
            v1 += serverReference.dayNames[realEnd.getUTCDay()] + ', ' + realEnd.getUTCDate() + ' ' + serverReference.monthNames[realEnd.getUTCMonth()] + ' ';
        }

        if (realStart.getTime() !== realEnd.getTime()) {
            v1 += this.date2.GetTimeString();
        }

        message += RESX.DateTime.ThisAppointmentBeginsInTheNight.replace('{0}', v0).replace('{1}', v1);
    } else {
        message += RESX.DateTime.ThisAppointmentTakesPlace;
        message += ' <b>';
        message += serverReference.dayNames[realStart.getUTCDay()];
        message += ', ';
        message += realStart.getUTCDate();
        message += ' ';
        message += serverReference.monthNames[realStart.getUTCMonth()];
        if (this.IsAllDay && (realStart.getUTCDate() !== realEnd.getUTCDate())) {
            message += '</b> ';
            message += RESX.DateTime.UntilAndIncluding;
            message += ' <b>';
            message += serverReference.dayNames[realEnd.getUTCDay()];
            message += ', ';
            message += realEnd.getUTCDate();
            message += ' ';
            message += serverReference.monthNames[realEnd.getUTCMonth()];
        } else if (!this.IsAllDay) {
            message += ' ';
            message += this.date1.GetTimeString();
            if (realStart.getUTCDate() !== realEnd.getUTCDate()) {
                message += ' - ';
                message += serverReference.dayNames[realEnd.getUTCDay()];
                message += ', ';
                message += realEnd.getUTCDate();
                message += ' ';
                message += serverReference.monthNames[realEnd.getUTCMonth()];
                message += ' ';
                message += this.date2.GetTimeString();
            } else if (realStart.getHours() !== realEnd.getHours() || realStart.getMinutes() !== realEnd.getMinutes()) {
                message += ' - ';
                message += this.date2.GetTimeString();
            }
        }
        message += '</b>.';
    }

    realDateDivObj.html(message);
};
UI.StartEndDateTimeFn.prototype.RenderIsNightInput = function () {
    var isNightDiv, div, label;

    isNightDiv = document.getElementById('IsNightDiv');

    //Clear holder
    while (isNightDiv.hasChildNodes()) {
        isNightDiv.removeChild(isNightDiv.lastChild);
    }

    if (!this.DisplayIsNightInput) { return false; }

    div = document.createElement('div');
    div.style.styleFloat = 'left';
    div.style.cssFloat = 'left';
    isNightDiv.appendChild(div);

    this.IsNightInput.checked = this.IsNight;
    this.IsNightInput.defaultChecked = this.IsNight;
    div.appendChild(this.IsNightInput);

    label = createLabelElement(this.IsNightInput.id, RESX.DateTime.InTheNightOf);
    div.appendChild(label);
};
UI.StartEndDateTimeFn.prototype.IsNightInputInit = function () {
    if (!this.UseIsNightInput) { return; }

    var that = this;

    this.IsNightInput = document.createElement('input');
    this.IsNightInput.name = 'IsNight';
    this.IsNightInput.id = 'IsNight';
    this.IsNightInput.type = 'checkbox';
    this.IsNightInput.value = 'true';
    this.IsNightInput.onclick = function () {
        that.IsNightInput_OnClick();
    };
};
UI.StartEndDateTimeFn.prototype.IsNightInput_OnClick = function () {
    this.IsNight = this.IsNightInput.checked;
};
UI.StartEndDateTimeFn.prototype.Validate = function () {
    var dt1, dt2, realStart, realEnd;

    // Gather dates
    //dt1 = this.date1.getLocalisedDate();
    dt1 = this.date1.getDateTime();
    realStart = (this.IsNight) ? dt1.addDays(1) : dt1;
    //dt2 = this.date2.getLocalisedDate();
    dt2 = this.date2.getDateTime();
    realEnd = (this.IsNight && (dt1.getDate() === dt2.getDate())) ? dt2.addDays(1) : dt2;

    if (realEnd < realStart) {
        this.date2.ShowWarning(RESX.DateTime.TheEndDateIsBeforeTheBeginDate);
        formObj.setHasError();
    }

    return true;
};