$.widget("sone.deactivateaccount", {
    options: {

    },
    _create: function () {
        var that = this;

        this.dialog$ = null;

        this.element.click(function () {

            that.dialog$ = $("<div />", {
                title: RESX.SoUser.DeactivateAccount,
                text: RESX.GeneralWarnings.AreYouSure
            })
                .dialog2({
                    autoOpen: true,
                    width: 400,
                    height: 300,
                    closeOutside: true,
                    buttons: [
                        {
                            text: RESX.SoUser.DeactivateAccount,
                            "class": "button-new warning",
                            click: function () {
                                that._doDeactivate();
                            }
                        }]
                });

        });
    },
    _doDeactivate: function () {
        var that = this;

        that.dialog$.dialog2('option', 'buttons', {});

        var userId = that.element.attr("data-userid");

        WebApi.Users.DeactivateAccount(userId)
            .done(
                function () {
                    location.reload();
                }
            );
    }
}); 