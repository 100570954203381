var moment = require("moment");

UI.FormElement.TimeSpanControl = function (options) {

    var internalMoment, that = this;

    // Set the defaults and apply supplied options
    var defaults = {
        isOn: true,
        is24Hr: true,
        displayIsOnInput: true,
        displaySeconds: false,
        required: true
    };

    $.fn.applyOptions(this, defaults, options);
    
    this.ValueMinimum = Date.nowAsUTC();
    this.ValueMaximum = Date.nowAsUTC();

    this.Value = null;

    //Is On checkbox
    this.IsOnInput = null;

    //Time input
    this.TimeInput = null;

    this.OnChangeFunctions = [];

    this.HolderRow1 = null;
    this.HolderRow2 = null;
    this.initHolder();

    this.epochId = this.name + 'Epoch';

    var invalidInternalMoment = function () {
        return internalMoment.toDate() == 'Invalid Date';
    };

    // private functions
    var updateHiddenEpoch = function () {
        $('#' + that.epochId).val(that.getUnixEpoch());
    };

    var parseTime = function (tm) {
        var d = new Date();
        var time = tm.match(/(\d{1,2})[:h.]?(\d\d)?\s*([pPaA]?)/);

        if (!time) {
            return null;
        }
        if (time[3]) {
            if ((time[3].toLowerCase() === 'a' && parseInt(time[1], null) === 12)) {
                d.setHours(parseInt(time[1], null) - 12);
            } else if (time[3].toLowerCase() === 'p') {
                d.setHours(parseInt(time[1], null) + 12);
            } else {
                d.setHours(parseInt(time[1], null));
            }
        } else {
            d.setHours(parseInt(time[1], null));
        }

        d.setMinutes(parseInt(time[2], null) || 0);

        return { hours: d.getHours(), minutes: d.getMinutes() };
    };

    // NOTE! Although setInternalValue is public it is not designed for external usage, take care!
    // rather use setValue
    this.setInternalValue = function (value) {
        internalMoment = moment.utc(value);
        updateHiddenEpoch();
    };

    this.getDateTime = function () {
        var m = internalMoment.clone();
        if (invalidInternalMoment()) {
            return null;
        }

        return m.toDate();
    };

    this.setValue = function (unixEpoch) {
        this.setInternalValue(unixEpoch);
        this.isOn = true;
        this.Render();
    };

    this.hasValue = function () {
        if (internalMoment && !invalidInternalMoment()) {
            return true;
        }
        return false;
    };

    this.setValueFromTime = function (tm) {

        var t = parseTime(tm);

        this.isOn = true;
        internalMoment
            .set('hour', t.hours)
            .set('minute', t.minutes);

        updateHiddenEpoch();
        this.Render();
    };

    this.getUnixEpoch = function () {
        return internalMoment.unix() * 1000;
    };
};
UI.FormElement.TimeSpanControl.prototype = new UI.FormElement();

UI.FormElement.TimeSpanControl.prototype.InitInputs = function () {
    var epochInput = document.createElement('input');
    epochInput.name = this.name + 'Epoch';
    epochInput.id = this.epochId;
    epochInput.type = 'hidden';
    this.holderDiv.appendChild(epochInput);

    this.IsOnInputInit();
    this.setInternalValue(this.Value);
};
UI.FormElement.TimeSpanControl.prototype.IsOnInputInit = function () {
    var that = this;

    this.IsOnInput = document.createElement('input');
    this.IsOnInput.name = this.name + 'IsOn';
    this.IsOnInput.id = this.name + 'IsOn';
    this.IsOnInput.type = 'checkbox';
    this.IsOnInput.value = 'true';
    this.IsOnInput.onclick = function () {
        that.SetIsOn(this.checked);
        that.OnChange();
        if (this.checked) {
            $(that.TimeInput).focus();
        } else {
            $(that.IsOnInput).focus();
        }
    };
};
UI.FormElement.TimeSpanControl.prototype.Render = function () {

    //Clear holder
    var epochDiv, holder;

    epochDiv = $('#' + this.epochId);
    holder = $(this.holderDiv);
    holder.empty();
    holder.append(epochDiv);

    this.HolderRow1 = document.createElement('div');
    this.HolderRow1.style.clear = 'both';
    this.holderDiv.appendChild(this.HolderRow1);

    this.HolderRow2 = document.createElement('div');
    this.HolderRow2.style.clear = 'both';
    this.holderDiv.appendChild(this.HolderRow2);

    this.holderDiv.appendChild(this.warningDiv);

    this.RenderIsOnInput();

    this.RenderTimeInput();

};
UI.FormElement.TimeSpanControl.prototype.RenderIsOnInput = function () {
    if (!this.displayIsOnInput) {
        return false;
    }

    var div = document.createElement('div');
    div.style.styleFloat = 'left';
    div.style.cssFloat = 'left';
    this.HolderRow1.appendChild(div);

    this.IsOnInput.checked = this.isOn;
    this.IsOnInput.defaultChecked = this.isOn;

    div.appendChild(this.IsOnInput);
};
UI.FormElement.TimeSpanControl.prototype.RenderTimeInput = function () {
    if (!this.isOn) {
        return false;
    }

    var div, timeFormat, that = this;
    div = document.createElement('div');
    div.style.styleFloat = 'left';
    div.style.cssFloat = 'left';
    this.HolderRow1.appendChild(div);
    
    this.TimeInput = document.createElement('input');
    this.TimeInput.id = this.name + 'TimeSpan';
    this.TimeInput.name = this.name + 'TimeSpan';
    this.TimeInput.type = 'text';
    this.TimeInput.className = 's1_timeinput';

    if (this.is24Hr) {
        $(this.TimeInput).addClass('time24');
        timeFormat = 'H:i';
    } else {
        timeFormat = 'h:i A';
        $(this.TimeInput).addClass('time');
    }

    div.appendChild(this.TimeInput);
    $(this.TimeInput)
        .timepicker({
            'scrollDefaultNow': true,
            'step': 15,
            'timeFormat': timeFormat
        });

    $(this.TimeInput)
        .timepicker(
            'setTime', this.getDateTime()
        );

    $(this.TimeInput)
        .on('change', function () {
            that.OnChange();
        });
};
UI.FormElement.TimeSpanControl.prototype.GetValue = function () {
    if (!this.isOn) {
        return null;
    }
    return this.getDateTime();
};

UI.FormElement.TimeSpanControl.prototype.SetIsOn = function (i) {
    this.isOn = i;
};

UI.FormElement.TimeSpanControl.prototype.SetOff = function () {
    this.IsOnInput.checked = false;
    this.OnChange();

};
UI.FormElement.TimeSpanControl.prototype.OnChange = function () {

    var dt, i, e, that = this;

    var onChange = function () {
        for (i = 0; i < that.OnChangeFunctions.length; i++) {
            /*jslint evil: true */
            eval(that.OnChangeFunctions[i]);
        }

        that.Validate();

        e = jQuery.Event("change", { value: that.Value });
        $(that).trigger(e);
    };

    dt = this.getDateTime();
    if (this.TimeInput) {
        if (dt == null) {
            dt = new Date(0);
        }
        dt = $(this.TimeInput).timepicker('getTime', dt);
    }

    if (dt == null) {
        this.IsOnInput.checked = false;
        this.setInternalValue(dt);
        onChange();
    } else if (this.getDateTime() != dt.getTime()) {
        this.setInternalValue(dt);

        onChange();
    }

    formObj.setConfirmUnload(true);
    this.Render();

};
UI.FormElement.TimeSpanControl.prototype.SetDisabled = function (i) {
    this.disabled = i;
    this.Render();
};

UI.FormElement.TimeSpanControl.prototype.SelectTimeInput = function () {
    if (this.TimeInput) {
        this.TimeInput.focus();
    }
};

UI.FormElement.TimeSpanControl.prototype.Validate = function () {
    if (!this.required) {
        return true;
    }
    if (this.GetValue()) {
        this.HideWarning();
        return true;
    } else {
        this.ShowWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        return false;
    }
};