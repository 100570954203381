export interface IDocumentIndicationsCounter {
  getStart: () => number
  getEnd: () => number
}

export class DocumentIndicationsCounter implements IDocumentIndicationsCounter {
  shows: any;

  constructor(shows: any) {
    this.shows = shows;
  }

  hasAnyWithContent(documentIndications: any[]): boolean {
    const x = $.grep(documentIndications, (n) => n.count > 0);

    return x.length > 0;
  }

  getStart() {
    // to render apptodocindications we need some additional data
    return Math.min(this.shows.map((show) => {
      for (let i = 0; i < show.documentIndications.length; i++) {
        if (show.documentIndications[i].count !== 0) {
          return i;
        }
      }
      return 100;
    }));
  }

  getEnd() {
    // to render apptodocindications we need some additional data
    return Math.max(this.shows.map((p) => {
      for (let i = p.documentIndications.length - 1; i > 0; i--) {
        if (p.documentIndications[i].count !== 0) {
          return i;
        }
      }
      return 0;
    }));
  }
}
