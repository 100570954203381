import { formatWrittenDayMonthYear } from "utilities";
import { joinWithMaxLength } from "utilities/stringutils";
import m from "moment";
import { AxiosPromise, AxiosResponse } from "axios";

type AutocompletePromise<T> = AxiosPromise<server.dto.PaginationWrapper<T[]>>;
type AutocompleteResponse<T> = AxiosResponse<server.dto.PaginationWrapper<T[]>>;
type InstructionWithExcludeKeys<T extends server.dto.AutocompleteInstruction> = T & { excludeKeys: number[] };
type EntityTypeOfPromise<T> = T extends AutocompletePromise<infer U> ? U : never;

type SpecificProps<T, U> = Omit<T, keyof U>; // Gets properties in T that are not in U

const convertToJqueryAutocomplete = <
    TApiFunc extends (instruction: InstructionWithExcludeKeys<any>) => AutocompletePromise<any>,
    TInstruction = Parameters<TApiFunc>[0] // Infer the type of the instruction from TApiFunc
>(
    apiFunc: TApiFunc,
    mapToKeyValue: (entity: EntityTypeOfPromise<ReturnType<TApiFunc>>) => server.dto.KeyValueSublabel
) => {
    return (query: string, excludeKeys: number[]) => {
        const $promise = $.when(apiFunc({
            keyword: query,
            excludeKeys,
            page: 1,
            perPage: 30
        } as TInstruction));

        return $promise.pipe((res: AutocompleteResponse<EntityTypeOfPromise<ReturnType<TApiFunc>>>) => res.data.data.map(mapToKeyValue));
    };
}

const getProductionSublabel = (production: server.dto.ProductionSmallV2) => {
    if (production.startDate == null) return "";

    const startDate = m.utc(production.startDate);
    const endDate = m.utc(production.endDate);

    const artistNames = joinWithMaxLength(production.artists, ", ", 2);

    let result = `${formatWrittenDayMonthYear(startDate)} - ${formatWrittenDayMonthYear(endDate)}`;
    if (artistNames.length > 0) {
        result += `<br/>${artistNames}`;
    }

    return result;
}

(window as any).productionFilterHack = convertToJqueryAutocomplete(
    window.CoreApi.Production.PaginatedSmall,
    prod => ({
        key: prod.productionId,
        value: prod.name,
        sublabel: getProductionSublabel(prod),
        defaultSelect: false,
    })
);

(window as any).venueFilterHack = convertToJqueryAutocomplete(
    window.CoreApi.Venue.PaginatedSmall,
    venue => ({
        key: venue.venueId,
        value: venue.name,
        sublabel: venue.cityCountry,
        defaultSelect: false,
    })
);

(window as any).companyAdministrativeFilterHack = convertToJqueryAutocomplete(
    (i: server.dto.ListCompanyInstruction) => window.CoreApi.Company.PaginatedSmall(i, "administrative"),
    company => ({
        key: company.companyId,
        value: company.name,
        sublabel: company.cityCountry,
        defaultSelect: false,
    })
);

(window as any).artistRegularFilterHack = convertToJqueryAutocomplete(
    window.CoreApi.Artist.PaginatedSmall,
    artist => ({
        key: artist.artistId,
        value: artist.name,
        sublabel: null,
        defaultSelect: false,
    })
);

(window as any).companyRegularFilterHack = convertToJqueryAutocomplete(
    (i: server.dto.ListCompanyInstruction) => window.CoreApi.Company.PaginatedSmall(i, "regular"),
    company => ({
        key: company.companyId,
        value: company.name,
        sublabel: company.cityCountry,
        defaultSelect: false,
    })
);

(window as any).contactV2FilterHack = convertToJqueryAutocomplete(
    window.CoreApi.Contact.PaginatedSmall,
    contact => ({
        key: contact.contactId,
        value: contact.name.fullName,
        sublabel: contact.position,
        defaultSelect: false,
    })
);

(window as any).ContractDeleteHack = function (contractId) {
    return window.CoreApi.Contract.Delete({ documentId: contractId });
};

(window as any).QuoteDeleteHack = function (quoteId) {
    return window.CoreApi.Quote.Delete({ documentId: quoteId });
};

(window as any).PurchaseAgreementDeleteHack = function (purchaseAgreementId) {
    return window.CoreApi.PurchaseAgreement.Delete({ documentId: purchaseAgreementId });
};

(window as any).InvoiceDeleteHack = function (invoiceId) {
    return window.CoreApi.Invoice.Delete({ invoiceId });
};