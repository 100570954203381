$.widget("sone.pageselection", {
    options: {
        month: 0,
        year: 0,
        firstYear: 2013,
        lastYear: 2025,
        callback: function () {
        }

    },
    _create: function () {

        this.pageGenerator = this.options.pageGenerator;

        this.pageGenerator.init(serverReference.monthNames, this.options.firstYear, this.options.lastYear);

        this.currentPage = this.options.currentPage;

        this.pageDiv = $("<div />", {
            "class": this.options.pageClass
        });

        this.callback = this.options.callback;

        this._updatePages();

        this.element.prepend(this.pageDiv);
    },
    _appendPage: function (pageText, isCurrent, value) {
        var that = this;

        if (isCurrent) {
            this.pageDiv.appendDiv({
                "class": "current",
                "style": "min-width: 53px;",
                "text": pageText
            });
        } else {
            this.pageDiv.appendDiv({
                "class": "hoverable",
                "style": "min-width: 53px;",
                "click": function () {
                    that.callback(value);
                },
                "text": pageText
            });
        }
    },
    updatePagination: function (page) {
        this.currentPage = page;
        this._updatePages();
    },
    _updatePages: function () {
        var that = this;

        this.pageDiv.empty();

        var pages = this.getPages();

        $.each(pages, function (index, page) {
            that._appendPage(page.text, page.isCurrent, page.value);
        });

    },
    getPages: function () {
        return this.pageGenerator.getPages(this.currentPage);
    }

});
