import * as Sentry from "@sentry/browser";

$.widget("sone.ajaxactionconfirmation", {
    options: {
        api: null,
        onSuccessRedirectUrl: "//www.google.com",
        ok: "OK",
        confirmationMessage: null,
        moveToOptions: null,
        title: "header",
        done: function (result) {
            document.location.href = this.onSuccessRedirectUrl.replace("{key}", result);
        }
    },
    _create: function () {
        var that = this;

        this.title = (that.element.text().length > 1) ? that.element.text() : this.options.title;

        this.dialog$ = $("<div />", {
            title: that.title
        })
            .dialog2({
                width: 400,
                height: 300
            });

        this.element.on("click", function () {
            that.dialog$.dialog2("open");
            that._showConfirmation();
        });
    },
    _init: function () {
        var keysJson = this.element.attr("keys");
        if (!keysJson) {
            this.element.css("background-color", "red");
            throw "no keys defined in the HTML button element of confirmation";
        }
        this.keys = JSON.parse(keysJson);
    },
    _showConfirmation: function () {
        //Make sure execution only starts once
        if (this.filledConfirmationContent) {
            return;
        } else {
            this.filledConfirmationContent = true;
        }

        var that = this;

        this.contentDiv$ = this.dialog$.appendDiv();

        this.contentDiv$.appendDiv({
            text: that.options.confirmationMessage,
            style: "margin-bottom:10px;"
        });

        if (this.options.moveToOptions) {
            that.moveToSelect$ = this.contentDiv$.appendSelect({});

            $.each(that.options.moveToOptions, function (index, value) {
                that.moveToSelect$.appendOption({
                    "value": value.key,
                    "text": value.value
                });
            });
        }

        that.dialog$.dialog2("option", "buttons", [
            {
                "id": "confirmationdelete",
                "class": "button-new warning",
                "text": that.title.toUpperCase(),
                "click": function () {
                    that.contentDiv$.fadeOut(function () {
                        that._showWaiting();
                    });
                    $("#confirmationdelete").animate({ opacity: 0 });
                    that._execute();
                }
            }
        ]);

        $("#confirmationdelete").blur();
    },
    _showWaiting: function () {

        var that = this;

        that.contentDiv$.empty();
        that.contentDiv$.css("text-align", "center");
        that.contentDiv$.css("padding-top", "50px");

        that.contentDiv$.appendSpinnerImage({});

        that.contentDiv$.fadeIn();

    },
    _execute: function () {
        //Make sure execution only starts once
        if (this.executeStarted) {
            return;
        } else {
            this.executeStarted = true;
        }

        var that = this;

        var moveToId$ = (that.moveToSelect$) ? parseInt(that.moveToSelect$.val(), 10) : null;
        moveToId$ = (moveToId$ === 0) ? null : moveToId$;

        const promise = this._executeApiFunction(moveToId$);

        // Jquery promise
        if (promise.done) {
            promise
                .done(result => that.options.done(result))
                .fail((xhr, _, err) => {
                    that._showError(xhr.responseText)
                    Sentry.captureException(err);
                });
        }
        // Axios promise
        else if (promise.then) {
            promise
                .then(result => {
                    if (result.data && result.data.errors && result.data.errors.length > 0) {
                        that._showError(result.data.errors[0]);
                    } else {
                        return that.options.done(result.data);
                    }
                })
                .catch(e => {
                    that._showError(RESX.GeneralLabels.SomethingUnexpectedHappened);
                    Sentry.captureException(e);
                });
        }
    },
    _executeApiFunction: function (moveToId$) {
        if (this.keys.length === 0) {
            throw "No keys found to send to the API.";
        } else if (this.keys.length === 1) {
            return soneTools.executeFunctionByName(this.options.api, window, this.keys[0], moveToId$);
        } else if (this.keys.length === 2) {
            return soneTools.executeFunctionByName(this.options.api, window, this.keys[0], this.keys[1], moveToId$);
        } else {
            throw "Too many keys found to send to the API." + this.keys;
        }
    },
    _showError: function (errorHtml) {
        var that = this;
        that.dialog$.empty();
        that.dialog$.html(errorHtml);
        that.dialog$.addClass("s1_red");

        that.dialog$.dialog2("option", "buttons", [
            {
                "class": "button-new primary",
                text: that.options.ok,
                click: function () {
                    $(this).dialog2("close");
                }
            }
        ]);
    },
    close: function () {
        this.dialog$.dialog2("close");
    }
});