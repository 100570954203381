coordinator.iCalendarFeeds = (function () {
 
    var emailOverview = function (userId) {
        CoreApi.ICalendarFeed.EmailOverview(userId).then(
            function (result) {
                showEmailSentDialog(result);
            }
        );
    };

    var showEmailSentDialog = function (result) {
        $("<div />",
        {
            "text": RESX.GeneralWarnings.AnEmailHasBeenSentToInformTheUser
        }).dialog2({
            width: 400,
            height: 300,
            modal: true,
            resizable: false,
            autoOpen: true,
            title: RESX.Message.EmailSent,
            buttons: [
                {
                    text: "OK",
                    "class": "button-new primary",
                    click: function () {
                        $(this).remove();
                    }
                }
            ],
            close: function () {
                $(this).remove();
            }
        });
    };


    return {
        emailOverview: emailOverview
    };
}());