soneTools.InputTextRowLocalizedControl = function (arrayOfControlIds) {
    this.InputArray = [];
    for (var i = 0; i < arrayOfControlIds.length; i++) {
        this.InputArray.push(document.getElementById(arrayOfControlIds[i]));
    }
    this.ValueHasBeenEntered = false;
}

soneTools.InputTextRowLocalizedControl.prototype.OnFocus = function () {
    this.ValueHasBeenEntered = this.HasAnyValue();
};
soneTools.InputTextRowLocalizedControl.prototype.OnKeyUp = function (obj) {
    if (!this.ValueHasBeenEntered) {
        this.SyncValueFromFocussed(obj);
    }
};
// Check if the other fields hold a value, excluding the current focussed
soneTools.InputTextRowLocalizedControl.prototype.HasAnyValue = function () {
    for (var i = 0; i < this.InputArray.length; i++) {
        if (this.HasValue(this.InputArray[i])) {
            return true;
        }
    }
    return false;
};
soneTools.InputTextRowLocalizedControl.prototype.HasValue = function (obj) {
    if (!obj) {
        return false;
    }
    return (obj.value);
};
//Set the value in the other boxes, exclude the current focussed
soneTools.InputTextRowLocalizedControl.prototype.SyncValueFromFocussed = function (obj) {
    for (var i = 0; i < this.InputArray.length; i++) {
        this.SyncValueSourceTarget(obj, this.InputArray[i]);
    }
};
soneTools.InputTextRowLocalizedControl.prototype.SyncValueSourceTarget = function (sourceObj, targetObj) {
    if (targetObj) {
        if (sourceObj != targetObj) {
            targetObj.value = sourceObj.value;
            if (targetObj.value) {
                soneTools.input_ClearErrorMessage(targetObj);
            }
        }
    }
};