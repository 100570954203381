var moment = require("moment");

$.widget("sone.periodcombofilter2", {
    options: {
        formelements: {
            start: "period_start",
            end: "period_end"
        },
        includeBySendOrDue: false,
        includeBlankOption: true,
        label: [],
        periodtypes: [],
        quartervalues: [],
        value: [],
        firstyear: 2000,
        lastyear: 2050,
        elementStrategy: null,
        today: null
    },
    _create: function () {
        this.mainHolderDiv$ = $("<div />");
        this.element.append(this.mainHolderDiv$);

        this.periodDetailDiv$ = $("<div />", {
            id: "periodDetail"
        });

        if (this.options.elementStrategy === null) {
            this.options.elementStrategy = periodElementModule;
            this.startEndHolderDiv$ = $("<div />");
        } else {
            this.startEndHolderDiv$ = $("<div />", {
                "class": "flex"
            });
            this.periodDetailDiv$.css("margin-right", "10px");
            this.periodDetailDiv$.css("padding-top", "10px");
        }
        this.element.append(this.startEndHolderDiv$);

        this._createHiddenInput();
        this._createPeriodElement();
        this._createStartDateElement();
        this._createEndDateElement();
        this._createYearElement(this.periodDetailDiv$);
        this._createQuarterElement(this.periodDetailDiv$);
        this._createMonthElement(this.periodDetailDiv$);
        this._createWeekElement(this.periodDetailDiv$);

        this.mainHolderDiv$.append(this.periodDetailDiv$);

        $("#ui-datepicker-div")
            .click(function (event) {
                event.stopPropagation();
            });
    },
    _init: function () {
        this.syncUI();
    },
    _createPeriodElement: function () {
        var that = this;

        this.periodSelect$ = this.options.elementStrategy.getPeriodElement(this.options.formelements.period);
        this.mainHolderDiv$.append(this.periodSelect$);

        if (this.options.includeBlankOption) {
            this.periodSelect$.append($("<option/>", {
                value: "",
                text: ""
            }));
        }

        $.each(this.options.periodtypes, function (index, value) {
            that.periodSelect$.append($("<option/>", {
                value: value.key,
                text: value.value.toLowerCase()
            }));
        });

        //On change trigger
        this.periodSelect$.on("change", function () {
            var value = $(this).val();
            that.options.value.period = (value === "") ? null : value;
            that.onChange();
        });
    },
    _createHiddenInput: function () {

        this.inputStart$ = $("<input />", {
            "name": this.options.formelements.start,
            "type": "hidden",
            "value": this.options.value.start
        });
        this.element.append(this.inputStart$);

        this.inputEnd$ = $("<input />", {
            "name": this.options.formelements.end,
            "type": "hidden",
            "value": this.options.value.end
        });
        this.element.append(this.inputEnd$);

    },
    _createStartDateElement: function () {

        var that = this;

        this.startDiv$ = this.options.elementStrategy.getStartDiv();
        this.startEndHolderDiv$.append(this.startDiv$);

        this.startDatePicker$ = $("<input />", {
            "type": "text",
            "class": "s1_dateinput"
        });
        this.startDiv$.append(this.startDatePicker$);

        //On change trigger for input field
        this.startDatePicker$.on("change", function () {
            that.options.value.start = that._getEpochFromDatepicker(this);

            that.onChange();
        });

        this.startDatePicker$.datepicker({
            showWeek: true,
            yearRange: that._yearRange(),
            onSelect: function () {
                that.options.value.start = that._getEpochFromDatepicker(this);
                that.onChange();
            }
        });

        this.startDatePicker$.click(function (event) {
            event.stopPropagation();
        });
    },
    _createEndDateElement: function () {

        var that = this;

        this.endDiv$ = this.options.elementStrategy.getEndDiv();
        this.startEndHolderDiv$.append(this.endDiv$);

        this.endDatePicker$ = $("<input />", {
            "type": "text",
            "class": "s1_dateinput"
        });
        this.endDiv$.append(this.endDatePicker$);

        //On change trigger for input field
        this.endDatePicker$.on("change", function () {
            that.options.value.end = that._getEpochFromDatepicker(this);
            that.onChange();
        });

        this.endDatePicker$.datepicker({
            showWeek: true,
            yearRange: that._yearRange(),
            onSelect: function () {
                that.options.value.end = that._getEpochFromDatepicker(this);
                that.onChange();
            }
        });

    },
    _createYearElement: function (holder) {
        var that = this;

        this.yearSelect$ = this.options.elementStrategy.getYearElement(holder);

        for (var year = this.options.firstyear; year <= this.options.lastyear; year++) {
            var utcDate = Date.UTC(year, 0);

            this.yearSelect$.append($("<option/>", {
                value: utcDate,
                text: year
            }));
        }

        //On change trigger
        this.yearSelect$.on("change", function () {
            that.options.value.start = parseInt($(this).val(), 10);

            that.onChange();
        });

    },
    _createQuarterElement: function (holder) {

        var that = this;

        this.quarterSelect$ = this.options.elementStrategy.getQuarterElement(holder);

        for (var year = this.options.firstyear; year <= this.options.lastyear; year++) {

            var optgroup$ = $("<optgroup />", {
                "label": year
            });
            this.quarterSelect$.append(optgroup$);

            $.each(this.options.quartervalues, function (index, value) {

                var month = value.key - 1;
                var utcDate = Date.UTC(year, month);
                var text = value.value + " " + year;

                optgroup$.append($("<option/>", {
                    value: utcDate,
                    text: text
                }));
            });
        }

        //On change trigger
        this.quarterSelect$.on("change", function () {
            that.options.value.start = parseInt($(this).val(), 10);
            that.onChange();
        });

    },
    _createMonthElement: function (holder) {

        var that = this;
        this.monthSelect$ = this.options.elementStrategy.getMonthElement(holder);

        for (var year = this.options.firstyear; year <= this.options.lastyear; year++) {

            var optgroup$ = $("<optgroup />", {
                "label": year
            });
            this.monthSelect$.append(optgroup$);

            $.each(serverReference.monthNames, function (month, value) {

                var utcDate = Date.UTC(year, month);
                var text = value + " " + year;

                optgroup$.append($("<option/>", {
                    value: utcDate,
                    text: text
                }));
            });
        }

        //On change trigger
        this.monthSelect$.on("change", function () {
            that.options.value.start = parseInt($(this).val(), 10);
            that.onChange();
        });

    },
    _createWeekElement: function (holder) {
        var that = this;

        this.weekSelect$ = this.options.elementStrategy.getWeekElement(holder);

        const {
            yearsWithWeeks
        } = site;
        const years = Object.keys(yearsWithWeeks);

        for (let year of years) {
            const optgroup$ = $("<optgroup />", {
                "label": year
            });
            for (let i = 0; i < yearsWithWeeks[year].length; i++) {
                const weekMoment = yearsWithWeeks[year][i];
                optgroup$.append($("<option/>", {
                    value: weekMoment,
                    text: `${that.options.label.week} ${i + 1} ${year}`,
                    selected: new Date(that.currentWeek).valueOf() === weekMoment
                }));
            }
            that.weekSelect$.append(optgroup$);
        }

        //On change trigger
        this.weekSelect$.on("change", function () {
            that.options.value.start = parseInt($(this).val(), 10);

            that.onChange();
        });

    },
    _yearRange: function () {
        return this.options.firstyear.toString() + ":" + this.options.lastyear.toString();
    },
    onChange: function () {
        this.syncUI();
    },
    syncUI: function () {
        var that = this;

        //Show form elements based on the period
        switch (this.options.value.period) {
            case "perYear":
                //PerYear
                this.startDiv$.hide();
                this.endDiv$.hide();
                this.yearSelect$.show();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.show();

                var yearDate = new Date(this.options.value.start);
                var y = yearDate.getUTCFullYear();

                this.options.value.start = Date.UTC(y, 0);
                this.options.value.end = Date.UTC(y + 1, 0);

                this.valueElement = this.yearSelect$;
                this.getDisplayText = function () {
                    return that.yearSelect$.find("option:selected").text();
                };

                break;
            case "perQuarter":
                //PerQuarter
                this.startDiv$.hide();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.show();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.show();

                var quarterDate = new Date(this.options.value.start);
                var endQuarter = quarterDate.getUTCMonth() + 3;
                this.options.value.end = quarterDate.setMonth(endQuarter);

                this.getDisplayText = function () {
                    return that.quarterSelect$.find("option:selected").text();
                };

                break;
            case "perMonth":
                //PerMonth
                this.startDiv$.hide();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.show();
                this.weekSelect$.hide();
                this.periodDetailDiv$.show();

                var monthDate = new Date(this.options.value.start);
                var endMonth = monthDate.getUTCMonth() + 1;
                this.options.value.end = monthDate.setMonth(endMonth);

                this.getDisplayText = function () {
                    return that.monthSelect$.find("option:selected").text();
                };

                break;
            case "perWeek":
                //PerWeek
                this.startDiv$.hide();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.show();
                this.periodDetailDiv$.show();

                var weekDate = new Date(this.options.value.start);

                this.options.value.end = weekDate.setDate(weekDate.getDate() + 7);

                this.getDisplayText = function () {
                    return that.weekSelect$.find("option:selected").text();
                };

                break;
            case "perDay":
                //PerDay
                this.startDiv$.show();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.hide();

                //var dayDate = new Date(this.options.value.start);

                //this.options.value.end = dayDate.setDate(dayDate.getUTCDate() + 1);
                var dt = new Date(this.options.value.start);
                coordinator.newappointment.setDate(dt.toISOString());

                this.getDisplayText = function () {
                    var date = that.startDatePicker$.datepicker("getDate");
                    var str = date.toShortDateStringNonUtc();
                    return str;
                };

                break;
            case "from":
                //From
                this.startDiv$.show();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.hide();

                this.options.value.end = this.options.value.start;

                this.getDisplayText = function () {
                    var date = that.startDatePicker$.datepicker("getDate");

                    return date.toShortDateStringNonUtc();
                };

                break;
            case "fromToday":
                //FromToday
                this.startDiv$.hide();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.hide();

                var now = new Date();
                var yutc = now.getUTCFullYear();
                var mutc = (now.getUTCMonth() + 1);
                var dutc = now.getUTCDate();

                this.options.value.start = Date.UTC(yutc, mutc - 1, dutc);
                this.options.value.end = Date.UTC(yutc, mutc - 1, dutc);

                this.getDisplayText = function () {
                    return "";
                };

                break;
            case "untilAndIncluding":
                this.startDiv$.show();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.hide();

                var datePickerValue = this._getEpochFromDatepicker(this.startDatePicker$[0]);

                if (datePickerValue) {
                    this.options.value.start = datePickerValue;
                }

                this.options.value.end = this.options.value.start;

                this.getDisplayText = function () {
                    var date = that.startDatePicker$.datepicker("getDate");

                    return date.toShortDateStringNonUtc();
                };

                break;
            case "fromUntilAndIncluding":
                //FromUntilAndIncluding
                this.startDiv$.show();
                this.endDiv$.show();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.hide();

                if (this.options.value.start > this.options.value.end) {
                    this.options.value.end = this.options.value.start;
                }

                this.getDisplayText = function () {
                    var startDate = that.startDatePicker$.datepicker("getDate");
                    var endDate = that.endDatePicker$.datepicker("getDate");
                    return startDate.toShortDateStringNonUtc() + " / " + endDate.toShortDateStringNonUtc();
                };

                break;
            case "currentYear":
                //Current year
                this.startDiv$.hide();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.hide();

                this.options.value.start = this.options.today;
                this.options.value.end = this.options.today;

                this.getDisplayText = function () {
                    return "";
                };

                break;
            case "currentWeek":
                //Current month
                this.startDiv$.hide();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.hide();

                this.options.value.start = this.options.today;
                this.options.value.end = this.options.today;

                this.getDisplayText = function () {
                    return "";
                };

                break;
            case "currentMonth":
                //Current month
                this.startDiv$.hide();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.hide();

                this.options.value.start = this.options.today;
                this.options.value.end = this.options.today;

                this.getDisplayText = function () {
                    return "";
                };

                break;
            case "currentDay":
                //Current day
                this.startDiv$.hide();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.hide();

                this.options.value.start = this.options.today;
                this.options.value.end = this.options.today;

                this.getDisplayText = function () {
                    return "";
                };

                break;
            case "currentQuarter":
                //Current quarter
                this.startDiv$.hide();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();
                this.periodDetailDiv$.hide();

                this.options.value.start = this.options.today;
                this.options.value.end = this.options.today;

                this.getDisplayText = function () {
                    return "";
                };

                break;
            default:
                //no filter
                this.startDiv$.hide();
                this.endDiv$.hide();
                this.yearSelect$.hide();
                this.quarterSelect$.hide();
                this.monthSelect$.hide();
                this.weekSelect$.hide();

                break;
        }

        //Sync UI parameters in page
        var startDate = new Date(this.options.value.start);
        var localDateStart = new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate());
        this.startDatePicker$.datepicker("setDate", localDateStart);

        var endDate = new Date(this.options.value.end);
        var localDateEnd = new Date(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate());
        this.endDatePicker$.datepicker("setDate", localDateEnd);

        this.inputStart$.val(this.options.value.start);
        this.inputEnd$.val(this.options.value.end);

        var yearUtc = startDate.getUTCFullYear();
        var monthUtc = (startDate.getUTCMonth() + 1);

        var epochForYear = Date.UTC(yearUtc, 0);
        this.yearSelect$.val(epochForYear);

        var roundedQuarter = parseInt(Math.ceil(monthUtc / 3), 10);
        var monthInQuarter = (roundedQuarter - 1) * 3;
        var epochForQuarter = Date.UTC(yearUtc, monthInQuarter);
        this.quarterSelect$.val(epochForQuarter);

        var epochForMonth = Date.UTC(yearUtc, monthUtc - 1);
        this.monthSelect$.val(epochForMonth);

        var weekMoment = moment.utc(this.options.value.start).startOf("week");

        this.weekSelect$.val(this.options.value.start);
        if (this.weekSelect$.prop("selectedIndex") === -1) {
            var wkYear = weekMoment.weekYear();
            var wkNumber = weekMoment.week();

            const startTime = this.getDateFromYearAndWeek(wkYear, wkNumber);
            this.weekSelect$.val(startTime);
        }

        this.periodSelect$.val(this.options.value.period);
    },
    _getEpochFromDatepicker: function (refObj) {
        var nonUtcDate = $(refObj).datepicker("getDate");
        if (!nonUtcDate) {
            return null;
        }

        var y = nonUtcDate.getFullYear();
        var m = nonUtcDate.getMonth();
        var d = nonUtcDate.getDate();

        var utcDate = Date.UTC(y, m, d);
        return utcDate;
    },
    getValue: function () {

        var period = this.periodSelect$.val();

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        var filter = {
            "filterName": "period_" + period,
            "filterTitle": capitalizeFirstLetter(this.periodSelect$.find("option:selected").text()),
            "period": period,
            "period_start": new Date(this.options.value.start).getTime(),
            "period_end": this.options.value.end,
            "filterDisplayText": this.getDisplayText()
        };

        return filter;
    },
    setWeek: function (year, week) {
        this.options.value.period = "perWeek";

        this.options.value.start = this.getDateFromYearAndWeek(year, week);

        this.syncUI();
    },
    // Sometimes they have an old filter preset with a year we don't support anymore
    // So we will clamp the year between the lowest and highest supported year.
    getDateFromYearAndWeek: function (year, week) {
        if (site.yearsWithWeeks[year] == null) {
            const sortedYears = Object.keys(site.yearsWithWeeks).map(Number).sort();
            const lowestSupportedYear = sortedYears[0];
            const highestSupportedYear = sortedYears[sortedYears.length - 1]

            const clampedYear = Math.min(
                Math.max(year, lowestSupportedYear),
                highestSupportedYear);

            return site.yearsWithWeeks[clampedYear][0];
        } else {
            return site.yearsWithWeeks[year][week - 1];
        }
    },
    setValue: function (value) {
        this.options.value = value;

        this.syncUI();
    }
});

var periodElementModule = (function () {

    // Return an object exposed to the public
    return {
        getPeriodElement: function (name) {
            var periodSelect$ = $("<select />", {
                "name": name
            });
            periodSelect$.css("width", "185px");
            periodSelect$.css("margin-right", "10px");

            return periodSelect$;
        },
        getStartDiv: function () {
            var startDiv$ = $("<div />");
            startDiv$.css("margin-right", "10px");

            return startDiv$;
        },
        getEndDiv: function () {
            var endDiv$ = $("<div />");

            return endDiv$;
        },
        getQuarterElement: function (holder) {
            var quarterSelect$ = $("<select />");
            quarterSelect$.css("width", "150px");

            holder.append(quarterSelect$);
            return quarterSelect$;
        },
        getMonthElement: function (holder) {
            var monthSelect$ = $("<select />");
            monthSelect$.css("width", "150px");

            holder.append(monthSelect$);
            return monthSelect$;
        },
        getYearElement: function (holder) {
            var yearSelect$ = $("<select />");
            yearSelect$.css("width", "150px");

            holder.append(yearSelect$);
            return yearSelect$;

        },
        getWeekElement: function (holder) {
            var weekSelect$ = $("<select />");
            weekSelect$.css("width", "150px");

            holder.append(weekSelect$);
            return weekSelect$;
        }
    };
})();