$.widget("sone.statusfilter", {
    options: {
        whereInOrNotInId: null,
        checkboxListId: null
    },
    _create: function () {

    },
    _init: function () {
        var whereInOrNotIn$ = $("#" + this.options.whereInOrNotInId);
        var checkboxList$ = $("#" + this.options.checkboxListId);

        whereInOrNotIn$.change(
            function () {
                var val = $(this).val();
                checkboxList$.toggle(val.length > 0);
            });

    }
});