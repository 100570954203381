import classNames from "classnames";
import React from "react";
import VatNumberDetailsCard from "./VatNumberDetailsCard";

interface Props {
  companyId: number
  vatNumber: server.dto.VatNumber
  displayDot?: boolean
}

const VatNumberDetails = (props: Props) => {
  const { companyId } = props;

  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [hasFinishedRefreshing, setHasFinishedRefreshing] = React.useState(false);
  const [hasFinishedFadingOut, setHasFinishedFadingOut] = React.useState(false);
  const [vatNumber, setVatNumber] = React.useState(props.vatNumber);

  const canRefresh = (vatNumber.number != null && vatNumber.isEu && vatNumber.validationOutcome !== "formattingError") && !hasFinishedFadingOut;

  const refresh = async () => {
    if (isRefreshing || hasFinishedRefreshing) return;

    setIsRefreshing(true);

    try {
      const vatCheckResult = await window.CoreApi.VatNumber.RecheckCompanyVatNumber(companyId);
      setVatNumber(vatCheckResult.data);
      setHasFinishedRefreshing(true);
    } catch (e) {
      console.error(e);
    } finally {
      setIsRefreshing(false);
    }
  };

  const reloadClasses = classNames("icon-reload small", {
    rotating: isRefreshing,
    "fade-out-rotating": hasFinishedRefreshing
  });

  const onAnimationEnd = (e: React.AnimationEvent<HTMLAnchorElement>) => {
    if (e.animationName === "fadeout") {
      setHasFinishedFadingOut(true);
    }
  };

  return <div className="flex gap">
    <VatNumberDetailsCard vatNumber={vatNumber} displayDot={props.displayDot} />

    {canRefresh && <div>
      <a onClick={refresh} className={reloadClasses} onAnimationEnd={onAnimationEnd} />
    </div>}
  </div>;
};

export default VatNumberDetails;