var weekSelectionModule = (function () {

    var init = function (monthNames, firstYear, lastYear) {
        this.firstYear = firstYear;
        this.lastYear = lastYear;
    }

    var getWeeks = function (page) {

        var week = page.week;
        var year = page.year;

        const timeOfSelectedWeek = site.yearsWithWeeks[year][week - 1];

        const flattenedWeeks = Object.keys(site.yearsWithWeeks)
            .map(year => site.yearsWithWeeks[year].map((time, weekIndex) => ({
                year,
                time,
                weekNr: weekIndex + 1
            })))
            .flatMap(times => times);

        const indexOfSelectedTime = flattenedWeeks.findIndex(times => times.time === timeOfSelectedWeek);
        const numberOfWeeksToShow = 7;

        const weeksBeforeSelected = Math.floor(numberOfWeeksToShow / 2);
        const weeksAfterSelected = Math.ceil(numberOfWeeksToShow / 2);

        const maxPossibleIndex = flattenedWeeks.length;
        const minIndex = indexOfSelectedTime - weeksBeforeSelected;
        const maxIndex = indexOfSelectedTime + weeksAfterSelected;

        // These two indices can under/overflow. We subtract or add the overflow to the other side.
        // For example, if minIndex == -1, maxIndex must get 1 more. 
        // If maxIndex == 1 above the limit, minIndex needs to be subtracted by 1
        // This ensures you always the right number of weeks shown on your screen without causing overflows

        const minIndexOverflow = Math.max(0, -minIndex);
        const maxIndexOverflow = Math.max(0, -(maxPossibleIndex - maxIndex));

        const actualMinIndex = Math.max(0, minIndex) - maxIndexOverflow;
        const actualMaxIndex = Math.min(maxPossibleIndex, maxIndex) + minIndexOverflow;

        const weeks = [];
        for (let i = actualMinIndex; i < actualMaxIndex; i++) {
            const timeInfo = flattenedWeeks[i];
            weeks.push({
                "text": "wk" + timeInfo.weekNr + " '" + timeInfo.year % 100,
                "isCurrent": timeInfo.weekNr === page.week,
                "value": timeInfo.time
            });
        }

        return weeks;
    }

    return {
        getPages: getWeeks,
        init: init
    }
}());

export {
    weekSelectionModule
}

//module.exports = {
//    weekSelectionModule
//}