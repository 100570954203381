declare const $: any;

$.fn.appendTags = function (tags: server.dto.TagSmall[]) {
  const that = this;

  $.each(tags, function (index, tag) {
    const div = $("<div />",
      {
        class: "s1_tag",
        text: tag.code,
        title: tag.title
      });
    div.css("background-color", tag.hexColor);
    that.append(div);
  });

  return this;
};

// Turn it into a module to satisfy `isolatedModules`
export {};