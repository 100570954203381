$.widget("sone.contactchooser", {
    options: {
        authorizedToModify: false,
        companyId: null,
        selectedContactId: null,
        data: [],
        label: [],
        url: []
    },
    fromCompanyChooser: function (event) {
        var companyIds = event.keys;

        if (companyIds.length === 0) {
            this._hide();
        } else {
            this._loadByCompany(companyIds[0]);
        }
    },
    reload: function () {
        this._loadByCompany(this.options.companyId);
    },
    link: function (wr) {

        if (!wr) {
            return;
        }

        //Make a shallow copy of the JSON result object because IE will destroy object after modal window close.
        var obj = jQuery.extend({}, wr.value);

        var that = this;

        window.CoreApi.CompanyToContact.SetLink({
            companyId:this.options.companyId, 
            contactId:obj.contactId,
            isActive: true,
            defaultSelect: true
        })
            .then(
                function () {
                    that._add(obj);
                });
    },
    count: function () {
        return this.data.length;
    },
    add: function (obj) {
        this._add(obj);
    },
    getData: function () {
        return this.data;
    },
    _create: function () {
        this.data = this.options.data.slice(0);
        this.selectedContactId = this.options.selectedContactId;
        this._correctSelectedContactId();

        //add radiolist
        var listDiv = $('<div />', {});
        this.element.append(listDiv);
        this.radioListControl$ = $(listDiv)
            .contactRadioList(
            {
                authorizedToModify: this.options.authorizedToModify,
                label: this.options.label,
                url: this.options.url,
                parent$: this
            });

        var buttonsDiv = $('<div />', {});
        this.element.append(buttonsDiv);
        this.buttonsControl$ = $(buttonsDiv)
            .contactChooseButtons(
            {
                authorizedToModify: this.options.authorizedToModify,
                label: this.options.label,
                url: this.options.url,
                parent$: this
            });

        this.warningDiv$ = $('<div />', { 'class': 's1_warning' });
        this.warningDiv$.hide();
        this.element.append(this.warningDiv$);
    },
    _init: function () {
        if (this.options.companyId === null) {
            this._hide();
        }
    },
    _loadByCompany: function (companyId) {
        var that = this;

        function onDone(data) {
            //set company id
            that.options.companyId = companyId;

            //Assign sorted resultset
            that.data = data.sort(soneTools.sort_by('value', false, function (a) { return a.toUpperCase(); }));
            that._correctSelectedContactId();

            that.radioListControl$.contactRadioList("fillFromData");
            that.buttonsControl$.show();
        }

        window.CoreApi.CompanyToContact.ListContactByCompany({
            companyId: companyId,
            page: 1,
            perPage: 1000,
            isActive: true,
        }).then(function (response) {
            onDone(response.data.data);
        });        
    },
    _hide: function () {
        this.selectedContactId = null;
        this.radioListControl$.contactRadioList("clear");
        this.buttonsControl$.hide();
    },
    _add: function (obj) {
        //Select the contact id
        this.setSelectedContactId(obj.key);

        //check if the item already exists in dataset
        for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].key === obj.key) {
                this.radioListControl$.contactRadioList("fillFromData");
                return;
            }
        }

        //add to dataset
        this.data.push(obj);

        this.radioListControl$.contactRadioList("fillFromData");
    },
    _correctSelectedContactId: function () {
        // In case the provided selectedContactId is no longer in the provided data.
        // Because the contact is no longer active with the company
        var i;
        for (i = 0; i < this.data.length; i++) {
            if (this.data[i].key === this.selectedContactId) {
                return;
            }
        }

        //        for (i = 0; i < this.data.length; i++) {
        //            //Preselect contact on fresh load
        //            if (this.data[i].defaultSelect) {
        //                this.selectedContactId = this.data[i].key;
        //                break;
        //            }
        //        }

        if (this.data.length === 0) {
            this.selectedContactId = null;
        }
        //        else if (this.selectedContactId === null) {
        //            //this.selectedContactId = this.data[0].key;
        //        }
    },
    setSelectedContactId: function (contactId) {
        this.selectedContactId = contactId;
        formObj.setConfirmUnload(true);
    },
    validate: function () {
        if (this.data.length >= this.options.min) {
            return true;
        }
        if (this.options.disabled) {
            return true;
        }

        var errorMssg = (this.options.min == 1)
            ? RESX.GeneralLabels.PleaseMakeAChoice
            : RESX.GeneralLabels.AtLeastXItemsShouldBeSelected.replace('{0}', this.options.min);

        this.showWarning(errorMssg);
        return false;
    },
    showWarning: function (message) {
        this.warningDiv$.empty();
        this.warningDiv$.append(message);
        this.warningDiv$.show();
        this.element.addClass('s1_warningborder');

        //var that = this;
        //clearTimeout(this.warningTimeout);
        //this.warningTimeout = setTimeout(function () {
        //    that.hideWarning();
        //}, 2000);

    },
    hideWarning: function () {
        this.element.removeClass('s1_warningborder');
        this.warningDiv$.empty();
        this.warningDiv$.hide();
    },
    getKey: function () {
        return this.selectedContactId;
    }
});

$.widget("sone.contactRadioList", {
    options: {
        authorizedToModify: false,
        nobodySelected: null,
        url: [],
        parent$: null
    },
    _create: function () {

    },
    _init: function () {
        this.fillFromData();
    },
    _appendRows: function () {
        this.element.empty();

        var data = this.options.parent$.getData();
        var tableDiv = $('<div />',
            {
                "class": "itemchooserTable"
            });
        this.element.append(tableDiv);

        //Sort data
        data.sort(soneTools.sort_by('value', false, function (a) { return a.toUpperCase(); }));

        //Add nobody row to dataSet
        var nobodyRowData = {
            showEdit: false,
            grayname: true,
            contactId: null,
            selected: (this.options.parent$.selectedContactId === null),
            name: this.options.label.nobodySelected,
            position: ""
        };
        this._appendRow(tableDiv, nobodyRowData);

        //Add rest of rows
        for (var i = 0; i < data.length; i++) {

            var rowData = {
                showEdit: this.options.authorizedToModify,
                grayname: false,
                contactId: data[i].key,
                selected: (data[i].key === this.options.parent$.selectedContactId),
                name: data[i].value,
                position: data[i].sublabel
            };

            this._appendRow(tableDiv, rowData);
        }
    },
    _appendRow: function (tableDiv, rowData) {

        // Set radiobutton elelemtn
        var that = this;
        var name = this.options.parent$.element.attr('id');
        var id = name + rowData.contactId;
        var radioButton = $('<input />',
            {
                "type": "radio",
                "name": name,
                "value": rowData.contactId,
                "id": id
            });
        radioButton.click(function () {
            that.options.parent$.setSelectedContactId(rowData.contactId);
        });
        if (rowData.selected) {
            radioButton.prop('checked', true);
        }

        //add row
        var rowDiv = $('<div />',
            {
                "class": "flex"
            });
        tableDiv.append(rowDiv);

        //add cells
        this._appendRadio(rowDiv, radioButton, rowData, id);
        this._appendLabels(rowDiv, radioButton, rowData);
        this._appendEditBtn(rowDiv, rowData);
    },
    _appendRadio: function (rowDiv, radioButton, rowData, id) {
        var that = this;

        var cellDiv = $('<div />',
            {
                "class": "ui radio checkbox",
            });

        cellDiv.click(function () {
            radioButton.prop("checked", true);
            that.options.parent$.setSelectedContactId(rowData.contactId);
        });
        rowDiv.append(cellDiv);

        cellDiv.append(radioButton);
        cellDiv.appendLabel({
            "for": id
        });
    },
    _appendLabels: function (rowDiv, radioButton, rowData) {
        var that = this;

        var cellDiv = $('<div />',
            {
                "class": "grow1"
            });
        cellDiv.click(function () {
            radioButton.prop("checked", true);
            that.options.parent$.setSelectedContactId(rowData.contactId);
        });
        rowDiv.append(cellDiv);

        var valueDiv = $('<div />', { "text": rowData.name });
        if (rowData.grayname) {
            valueDiv.addClass('s1_gray');
        }
        cellDiv.append(valueDiv);

        var sublabelDiv = $('<div />',
        {
            "text": rowData.position,
            "class": "s1_small s1_gray"
        });
        cellDiv.append(sublabelDiv);
    },
    _appendEditBtn: function (rowDiv, rowData) {
        var that = this;

        var cellDiv = rowDiv.appendDiv({
            "style": "text-align:right;",
            "click": function () {
                if (rowData.showEdit) {
                    that._openEditDialog(rowData.contactId);
                }
            }
        });

        if (rowData.showEdit) {
            cellDiv.appendA({
                "class": "icon-pencil-1"
            });
        }
    },
    clear: function () {
        this.element.empty();
    },
    fillFromData: function () {
        this._appendRows();
    },
    _openEditDialog: function (contactId) {
        var id = this.options.parent$.element.attr('id');
        var url = this.options.url.edit.replace("{key}", contactId);
        var resultFunc = id + "contactchooser$.contactchooser('reload')";
        SystemOneLibrary.exports.Utils.openPopupModal(url, 1000, 750, resultFunc);
    }
});

$.widget("sone.contactChooseButtons", {
    options: {
        data: [],
        label: [],
        url: [],
        authorizedToModify: false,
        parent$: null
    },
    _create: function () {
        if (!this.options.authorizedToModify) {
            return;
        }

        this._addLinkExistingButton();
        this._addNewButton();
    },
    _addLinkExistingButton: function () {
        var that = this;

        var btn$ = this.element.appendButton({
            "type": "button",
            "class": "button-new tertiary margin-bottom-mini fluid",
            "click": function () {
                that._openLinkExistingDialog();
            },
            "text": this.options.label.linkExistingContact
        });
    },
    _addNewButton: function () {
        var that = this;

        var btn$ = this.element.appendButton({
            "type": "button",
            "class": "button-new primary margin-bottom-mini fluid",
            "click": function () {
                that._openNewModal();
            },
            "text": this.options.label.newContact
        });

    },
    _openLinkExistingDialog: function () {
        var id = this.options.parent$.element.attr('id');
        var url = this.options.url.linkexisting;
        var resultFunc = id + "contactchooser$.contactchooser('link', result)";
        SystemOneLibrary.exports.Utils.openPopupModal(url, 1000, 750, resultFunc);
    },
    _openNewModal: function () {
        var id = this.options.parent$.element.attr('id');
        var url = this.options.url.newContact;
        var resultFunc = id + "contactchooser$.contactchooser('link', result)";
        SystemOneLibrary.exports.Utils.openPopupModal(url, 1000, 750, resultFunc);
    }
});