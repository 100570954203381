declare const $: any;

$.fn.phaseRadios = function (settings) {
  const config = {
    horizontal: true,
    name: "phases",
    phases: []
  };

  if (settings) {
    $.extend(config, settings);
  }

  const phases$ = [];

  const set = (phase) => {
    $.each(phases$, (index, phase$) => {
      const checked = phase$.data("value").value === phase.value;
      phase$.prop("checked", checked);
    });
  };

  const get = () => {
    let returnValue = null;
    $.each(phases$, (index, phase$) => {
      if (phase$.prop("checked")) {
        returnValue = phase$.data("value");
      }
    });

    return returnValue;
  };

  this.val = (phaseId) => ((phaseId == null) ? get() : set(phaseId));

  return this.each(() => {
    const this$ = $(this);

    $.each(config.phases, (index, phase) => {
      const div$ = this$.appendDiv({});
      const id = config.name + "_phase_" + phase.value;
      const cssClass = phase.icon;

      if (config.horizontal) {
        div$.css("display", "inline-block");
      }

      const input = div$.appendInput({
        id,
        checked: phase.selected,
        name: config.name,
        type: "radio",
        value: phase.value
      });

      if (phase.selected) {
        input.prop("checked", true);
      }

      input.data("value", phase);

      phases$.push(input);

      div$.appendSpan({
        class: cssClass,
        style: "padding-top: 3px;"
      });

      div$.appendLabel({
        for: id,
        style: "margin-left:5px;",
        text: phase.text
      });
    });
  });
};

export {};