function AppTypeFilter(options) {
    this.options = options;
}

AppTypeFilter.prototype = new UI.FormElement();

AppTypeFilter.prototype.SetSelected = function (ids) {
    for (var i = 0; i < this.options.appointmentTypes.length; i++) {
        this.options.appointmentTypes[i].select = ($.inArray(this.options.appointmentTypes[i].value, ids) > -1);
    }
};

AppTypeFilter.prototype.Render = function () {
    while (this.holderDiv.hasChildNodes()) {
        this.holderDiv.removeChild(this.holderDiv.lastChild);
    }

    for (var i = 0; i < this.options.appointmentTypes.length; i++) {
        this.RenderRow(this.holderDiv, this.options.appointmentTypes[i]);
    }

    this.holderDiv.appendChild(this.warningDiv);
};
AppTypeFilter.prototype.RenderRow = function (node, appointmentType) {
    var that = this;

    var div = document.createElement('div');
    div.className = (appointmentType.select)
        ? 'sone-ui-apptypefilterelement'
        : 'sone-ui-apptypefilterelement s1_transparent';

    div.onmousedown = function () {
      //  div.style.backgroundColor = LayoutColors[1];
        div.style.color = 'white';
    };
    div.onmouseup = function () {
     //   div.style.backgroundColor = LayoutColors[3];
    };
    div.onmouseover = function () {
      //  div.style.backgroundColor = LayoutColors[3];
    };
    div.onmouseout = function () {
        div.style.backgroundColor = 'white';
    };
    div.onclick = function () {
        that.OnClick(appointmentType);
    };

    node.appendChild(div);

    // RenderFormElement creates a hidden input that allows the slected type to be posted back
    this.RenderFormElement(div, appointmentType);
    this.RenderLabel(div, appointmentType);
};
AppTypeFilter.prototype.RenderFormElement = function (node, i) {
    if (!i.select) {
        return;
    }

    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'Type';
    input.value = i.value;
    node.appendChild(input);
};
AppTypeFilter.prototype.RenderLabel = function (node, appointmentType) {
    var div = document.createElement('div');
    div.className = 'sone-ui-apptypefilterlabel';
    node.appendChild(div);
    
    var img = document.createElement('i');
    img.className = appointmentType.icon;
    div.appendChild(img);

    div.appendChild(document.createTextNode(" "));

    div.appendChild(document.createTextNode(appointmentType.label));
};
AppTypeFilter.prototype.OnClick = function (appointmentType) {
    appointmentType.select = !appointmentType.select;

    this.Validate();
    this.Render();
};
AppTypeFilter.prototype.keys = function () {
    var ids = [];

    for (var i = 0; i < this.options.appointmentTypes.length; i++) {
        if (this.options.appointmentTypes[i].select) {
            ids.push(this.options.appointmentTypes[i].value);
        }
    }

    return ids;
};
AppTypeFilter.prototype.Validate = function () {
    var isSuccess = (this.keys().length > 0);

    if (isSuccess) {
        this.HideWarning();
    } else {
        this.ShowWarning(RESX.GeneralLabels.PleaseMakeAChoice);
    }

    return isSuccess;
};