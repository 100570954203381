import moment from "moment";
import React from "react";
import { formatShortDate, newLineToBr } from "../../utilities";

const FinancialRiskLevel = (props: { financialRisk: server.dto.FinancialRisk }) => {
  const { financialRisk } = props;

  const sinceTxt = (financialRisk.level.value !== "unknown" && financialRisk.changed != null)
    ? <span>{RESX.DateTime.Since} {formatShortDate(moment.utc(financialRisk.changed))}</span>
    : <></>;

  return <div className="flex">
    <div className="margin-right-small">
      <i className="icon-circle" style={{ color: financialRisk.level.hexColor }} />
    </div>
    <div className="grow1">
      {financialRisk.level.text}
    </div>
    <div className="text-sublabel">
      {sinceTxt}
    </div>
  </div>;
};

const FinancialRiskCard = (props: { financialRisk: server.dto.FinancialRisk }) => {
  const { financialRisk } = props;

  if (financialRisk == null) return <></>;
  return <>
    {financialRisk.level.value !== "unknown" && <FinancialRiskLevel financialRisk={financialRisk} />}
    {(financialRisk.comment != null) && <div className="text-sublabel">{newLineToBr(financialRisk.comment)}</div>}
  </>;
};

export default FinancialRiskCard;