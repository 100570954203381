declare const coordinator: any;

$.widget("sone.newappointmentdialog", $.sone.dialog2, {
  options: {
    labels: [],
  },
  _create: function () {
    this._super();

    this._appendShow();
    this._appendFlight();
    this._appendTrain();
    this._appendGroundTransport();
    this._appendLodging();
    this._appendExternalCalendar();

    this.debugDiv$ = this.element.appendDiv();
    this.debugDiv$.hide();
  },
  _appendShow: function () {
    if (!site.permissions.show.canCreate) {
      return;
    }

    var div$ = this._addMenuRow(
      this.options.labels.newShow ?? site.currentEnvironment.show.newLabel,
      "icon-show"
    );
    div$.click(function () {
      coordinator.newappointment.newShow();
    });
  },
  _appendFlight: function () {
    if (!site.permissions.flight.canCreate) {
      return;
    }

    var div$ = this._addMenuRow(RESX.Flight.NewFlight, "icon-flight");
    div$.click(function () {
      coordinator.newappointment.newFlight();
    });
  },
  _appendGroundTransport: function () {
    if (!site.permissions.groundTransport.canCreate) {
      return;
    }

    var div$ = this._addMenuRow(
      RESX.GroundTransport.NewGroundTransport,
      "icon-groundtransport"
    );
    div$.click(function () {
      coordinator.newappointment.newGroundTransport();
    });
  },
  _appendTrain: function () {
    if (!site.permissions.train.canCreate) {
      return;
    }

    var div$ = this._addMenuRow(RESX.Train.NewTrainJourney, "icon-train");
    div$.click(function () {
      coordinator.newappointment.newTrain();
    });
  },
  _appendLodging: function () {
    if (!site.permissions.lodging.canCreate) {
      return;
    }

    var div$ = this._addMenuRow(
      RESX.Accommodation.NewAccommodation,
      "icon-accommodation"
    );
    div$.click(function () {
      coordinator.newappointment.newAccommodation();
    });
  },
  _appendExternalCalendar: function () {
    if (!site.permissions.externalCalendar.canCreate) {
      return;
    }

    var div$ = this._addMenuRow(
      RESX.ExternalCalendar.NewExternalCalendar,
      "icon-external"
    );
    div$.click(function () {
      coordinator.newappointment.newExternalCalendar();
    });
  },
  _addMenuRow: function (label, icon) {
    var button$ = this.element.appendButton({
      type: "button",
      class: "button-new primary fluid margin-bottom",
      style: "justify-content:flex-start;",
    });

    button$.appendI({
      class: icon,
    });

    button$.appendSpan({
      text: label,
    });

    return button$;
  },
  open: function (info) {
    if (window.site.debug) {
      this.debugDiv$.show();
      this.debugDiv$.empty();

      var dtStr = info.date;
      this.debugDiv$.appendDiv({
        html: "date:<br />" + dtStr,
        style: "padding-top:10px;",
      });

      if (info.artist) {
        this.debugDiv$.appendDiv({
          html:
            "artist id / name:<br />" +
            info.artist.artistId +
            " / " +
            info.artist.name,
          style: "padding-top:10px;",
        });
      } else {
        this.debugDiv$.appendDiv({
          html: "no artist provided",
          style: "padding-top:10px;",
        });
      }

      if (info.fromItem) {
        this.debugDiv$.appendDiv({
          html:
            "fromItem type / id:<br />" +
            info.fromItem.type +
            " / " +
            info.fromItem.id,
          style: "padding-top:10px;",
        });
      } else {
        this.debugDiv$.appendDiv({
          html: "no fromItem provided",
          style: "padding-top:10px;",
        });
      }
    }

    this._super();
  },
});

export { };