declare const $: any;

$.fn.appendErrorDiv = function (settings: any) {
  let div$;

  const config = {
    values: []
  };

  if (settings) {
    $.extend(config, settings);
  }

  this.clear = () => {
    div$.empty();
  };

  this.showError = (message) => {
    div$.empty();
    div$.append(message);
  };

  let this$;
  return this.each(function () {
    this$ = $(this);

    div$ = this$.appendDiv({
      style: "color:red;"
    });
  });
};

export {};