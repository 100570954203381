const MyGoogleTooltip = function(options) {
    // Now initialize all properties.
    this.marker_ = options.marker;
    this.content_ = options.content;
    this.map_ = options.marker.get("map");
    this.cssClass_ = options.cssClass || null;
    this.visible_ = false;
    // We define a property to hold the content's
    // div. We'll actually create this div
    // upon receipt of the add() method so we'll
    // leave it null for now.
    this.div_ = null;
    //Explicitly call setMap on this overlay
    this.setMap(this.map_);
    var that = this;
    
    if (options.trigger === "click") {
        google.maps.event.addListener(that.marker_, "click", function () {
            if (that.visible_) {
                that.hide();
            } else {
                that.show();
            }            
        });
    } else {

        google.maps.event.addListener(that.marker_, "mouseover", function () {
            that.show();
        });
        // Hide tooltip on mouseout event.
        google.maps.event.addListener(that.marker_, "mouseout", function () {
            that.hide();
        });    
    }
    
}

// Now we extend google.maps.OverlayView()
if (typeof google !== 'undefined') {
    MyGoogleTooltip.prototype = new google.maps.OverlayView();
//We must implement three functions: onAdd, draw and onRemove, add the following lines:
// onAdd is one of the functions that we must implement,
// it will be called when the map is ready for the overlay to be attached.
    MyGoogleTooltip.prototype.onAdd = function() {
        // Create the DIV and set some basic attributes.
        var div = document.createElement("DIV");
        div.style.position = "absolute";
        // Hide tooltip
        div.style.visibility = "hidden";
        if (this.cssClass_)
            div.className += " " + this.cssClass_;
        //Attach content to the DIV.
        div.innerHTML = this.content_;
        // Set the overlay's div_ property to this DIV
        this.div_ = div;
        // We add an overlay to a map via one of the map's panes.
        // We'll add this overlay to the floatPane pane.
        var panes = this.getPanes();
        panes.floatPane.appendChild(this.div_);
    };
// We here implement draw
    MyGoogleTooltip.prototype.draw = function() {
        // Position the overlay. We use the position of the marker
        // to peg it to the correct position, just northeast of the marker.
        // We need to retrieve the projection from this overlay to do this.
        var overlayProjection = this.getProjection();
        // Retrieve the coordinates of the marker
        // in latlngs and convert them to pixels coordinates.
        // We'll use these coordinates to place the DIV.
        var ne = overlayProjection.fromLatLngToDivPixel(this.marker_.getPosition());
        // Position the DIV.
        var div = this.div_;
        div.style.left = ne.x + "px";
        div.style.top = ne.y + "px";
    };
// We here implement onRemove
    MyGoogleTooltip.prototype.onRemove = function() {
        this.div_.parentNode.removeChild(this.div_);
    };
// Note that the visibility property must be a string enclosed in quotes
    MyGoogleTooltip.prototype.hide = function() {
        if (this.div_) {
            this.visible_ = false;
            this.div_.style.visibility = "hidden";
        }
    };
    MyGoogleTooltip.prototype.show = function() {
        if (this.div_) {
            this.visible_ = true;
            this.div_.style.visibility = "visible";
        }
    };
}

window.myGoogleTooltip = MyGoogleTooltip;