$.fn.groundpanel_editdetails = function (settings) {

    var config = {
        phases: [],
        labels: {
            artist: null
        }
    }

    if (settings) {
        $.extend(config, settings);
    }

    var element = this;

    var artist$;
    var addTable = function () {
        var table$ = $("<table />", {
            "class": "s1_table bordergrid gray"
        });

        var tbody$ = table$.appendTbody({});

        appendPhaseRow(tbody$);
        appendArtist(tbody$);
        appendTravellers(tbody$);

        appendDriverNameRow(tbody$);
        appendDriverMobileRow(tbody$);
        appendDriverEmailRow(tbody$);
        appendVehicleModelRow(tbody$);

        artist$.on("mychange",
            function (event) {
                if (event.artist) {
                    travellers$.loadSuggestionsByArtist(event.artist.artistId);
                } else {
                    travellers$.hideRow();
                }
            });

        element.appendDiv({
            "class": "grow1"
        }).append(table$);
    }

    var phases$;
    var appendPhaseRow = function (tbody$) {

        var tr$ = tbody$.appendTr({});

        //append label
        tr$.appendTd({
            "class": "label",
            "text": RESX.Status.resxStatus
        });

        var td$ = tr$.appendTd({
            colspan: 2
        });

        var div$ = td$.appendDiv();

        phases$ = div$.phaseRadios({
            name: "ground_phases",
            phases: serverReference.appointmentPhases.map(function (p) {
                if (p.value === "confirmed") {
                    p.selected = true;
                    return p;
                }
                return p;
            })
        });
    }

    var appendArtist = function (tbody$) {
        var tr$ = tbody$.appendTr({});

        //append label
        tr$.appendTd({
            "class": "label",
            "text": site.currentEnvironment.functionGroups.artist.singular
        });

        var td$ = tr$.appendTd({
            colspan: 2
        });

        artist$ = td$.selectArtist({});
    }

    var travellers$;
    var appendTravellers = function (tbody$) {
        travellers$ = tbody$.attendeechooser2({
            labels: {
                rowlabel: RESX.Flight.Travellers
            }
        });
    }

    var vehicleModel$;
    var appendVehicleModelRow = function (tbody$) {
        var tr$ = tbody$.appendTr({});

        //append label
        tr$.appendTd({
            "class": "label",
            "text": RESX.GroundTransport.Vehicle
        });

        var td$ = tr$.appendTd({
            colspan: 2
        });
        vehicleModel$ = td$.appendInput({
            type: "text",
            maxlength: 200
        });
    }

    var driverName$;
    var appendDriverNameRow = function (tbody$) {
        var tr$ = tbody$.appendTr({});

        //append label
        tr$.appendTd({
            "class": "label",
            "text": RESX.GroundTransport.DriverName
        });

        var td$ = tr$.appendTd({
            colspan: 2
        });
        driverName$ = td$.appendInput({
            type: "text",
            maxlength: 100,
            placeholder: RESX.GroundTransport.DriverName
        });
    }

    var driverMobile$;
    var appendDriverMobileRow = function (tbody$) {
        var tr$ = tbody$.appendTr({});

        //append label
        tr$.appendTd({
            "class": "label",
            "text": RESX.GroundTransport.DriverMobile
        });

        var td$ = tr$.appendTd({
            colspan: 2
        });
        driverMobile$ = td$.appendInput({
            type: "text",
            maxlength: 100,
            placeholder: "+00123456789"
        });
    }

    var driverEmail$;
    var appendDriverEmailRow = function (tbody$) {
        var tr$ = tbody$.appendTr({});

        //append label
        tr$.appendTd({
            "class": "label",
            "text": RESX.GroundTransport.DriverEmail
        });

        var td$ = tr$.appendTd({
            colspan: 2
        });
        driverEmail$ = td$.appendInput({
            type: "text",
            maxlength: window.serverReference.constants.mailAddress.maxEmailLength,
            placeholder: "name@email.com"
        }).isEmail({
            required: false
        });
    }

    addTable();

    this.setValue = function (val) {

        phases$.val(val.phase);
        artist$.val(val.artists[0] || null);
        travellers$.val(val.artists[0], val.travellers);

        driverName$.val((val.driver) ? val.driver.name : null);
        driverMobile$.val((val.driver) ? val.driver.mobile : null);
        driverEmail$.val((val.driver) ? val.driver.email : null);

        vehicleModel$.val((val.driver) ? val.driver.vehicleModel : null);
    };

    this.getValue = function () {
        var phase = phases$.val();
        var artist = artist$.val();
        var travellers = travellers$.val();

        var driverName = driverName$.val();
        var driverMobile = driverMobile$.val();
        var driverEmail = driverEmail$.val();

        var vehicleModel = vehicleModel$.val();

        return {
            phase: phase,
            artists: [artist],
            travellers: travellers,
            driver: {
                name: driverName,
                mobile: driverMobile,
                email: driverEmail,
                vehicleModel: vehicleModel,
            }
        };
    }

    this.validate = function () {
        var isValid = true;

        if (!artist$.validate()) {
            isValid = false;
            window.console && console.warn("No artist selected");
        }

        if (!travellers$.validate()) {
            isValid = false;
            window.console && console.warn("No traveller selected");
        }

        if (!driverEmail$.validate()) {
            isValid = false;
            window.console && console.warn("Emailadres invalid");
        }

        return isValid;
    };
    //this.update = function () {
    //    return updateInternalValue();
    //};

    return this;
};