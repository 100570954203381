import unionWith from "lodash/unionWith";
declare let urlTools: any;
declare let modalWindow: any;

// Exposed to old style JS as SystemOneLibrary.exports.Utils

export class Utils {
  static processManualRecipients = (obj$) => {
    obj$.val(Utils.processManualRecipientsString(obj$.val()));
  };

  static stopSubmissionIfInvalidEmails = (event, obj$) => {
    if (event.key === "Enter") {
      const emailOutput = Utils.processManualRecipientsString(obj$.val());
      if (emailOutput === "") {
        obj$.val("");
        event.preventDefault();
      }
    }
  }

  static processManualRecipientsString = (input: string): string => {
    const emailArray = Utils.getValidEmails(input);
    let resultText = "";
    for (let i = 0; i < emailArray.length; i++) {
      resultText = resultText + emailArray[i];
      if (i < (emailArray.length - 1)) {
        resultText = resultText + "; ";
      }
    }
    return resultText;
  };

  static isValidEmailAddress = (input: string): boolean => {
    // 1. There should be only one @
    // 2. There should be a minimum of 1 character before the @
    // 3. There should be a minimum of 1 character after the @ that is not a dot
    // 4. There should be at least one dot after the @
    // 5. There should always be at least one character after every dot

    // Valid characters are:
    // - \p{L} Letters in latin, cyrillic, greek, etc, including accented ones
    // - \p{N} Numbers in latin, arabic, etc
    // - \p{M} Diacritic marks
    // - +, -, _, ' and . are also allowed
    const validCharacterRegex = /^[\p{L}\p{N}\p{M}\+\-\._']+$/u;

    const parts = input.split("@");
    
    // 1. There should be only one @
    if (parts.length !== 2) {
      return false;
    }

    const [localPart, domainPart] = parts;

    // 2. There should be a minimum of 1 character before the @
    // The character(s) should also be "valid" characters
    if (localPart.length < 1 || !validCharacterRegex.test(localPart)) {
      return false;
    }
    
    // 3. There should be a minimum of 1 character after the @ that is not a dot
    if (domainPart.length < 1 || domainPart[0] === ".") {
      return false;
    }

    
    // 4. There should be at least one dot after the @
    if (domainPart.split(".").length < 2) {
      return false;
    }

    const partsByDot = input.split(/@|\./);

    for (let i = 0; i < partsByDot.length; i++) {
      const part = partsByDot[i];
      // 5. There should always be at least one character after the dot
      // The character(s) should also be "valid" characters
      if (part.length < 1 || !validCharacterRegex.test(part)) {
        return false;
      }
    }

    return true;
  };

  static hasOnlyLettersAndNumbers = (input: string): boolean => {
    const regExPattern = /^[0-9a-zA-Z]+$/;
    return input.search(regExPattern) > -1;
  };

  // get distinct array of valid email addresses out of a string of possible email addresses
  static getValidEmails = (emailString: string): string[] => {
    const result = [] as string[];
    const emails = emailString.toLowerCase().split(/[^'_\.\-@a-z0-9\u00C0-\u024F\+]/);
    for (const item of emails) {
      if (Utils.isValidEmailAddress(item) && !result.includes(item)) {
        result.push(item);
      }
    }
    return result;
  };

  static isValidPassword = (pw: string): boolean => {
    const minimumLength = 10;
    const objDigitRegEx = /\d+/;
    const objAlphaRegEx = /[a-zA-Z]+/;

    if (pw.length < minimumLength || !objDigitRegEx.test(pw) || !objAlphaRegEx.test(pw)) {
      return false;
    }
    return true;
  };

  static parseInteger = (value: string, fallback?: number): number => {
    if (!value) {
      return fallback;
    }

    const formValue = parseInt(value, 10);
    if (isNaN(formValue)) {
      return fallback;
    } else {
      return formValue;
    }
  };

  static parseDecimal = (value: string, fallback?: number): number => {
    if (!value) {
      return fallback;
    }

    if (site.culture.numberDecimalSeparator === ",") {
      value = value.replace(/\./g, "");
      value = value.replace(/\,/g, ".");
    } else {
      value = value.replace(/\,/g, "");
    }
    const result = Number(value);
    if (isNaN(result)) {
      return fallback;
    }
    return result;
  };

  static formatDecimal = (num: number, decimals = 2): string => {
    if (num == null) {
      return "";
    }

    if (site.culture.numberDecimalSeparator === ",") {
      const value = num.toFixed(decimals);
      return value.replace(/\./g, ",");
    } else {
      return num.toFixed(decimals);
    }
  };

  static parseUserDecimal = (value: string) => {
    if (site.culture.numberDecimalSeparator === ",") {
      value = value.replace(/\./g, "");
      value = value.replace(/\,/g, ".");
    } else {
      value = value.replace(/\,/g, "");
    }
    const result = Number(value);
    if (isNaN(result)) {
      return 0;
    }
    return result;
  };

  static uniqBy<T>(a: T[], key: (item: T) => any): T[] {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return Object.prototype.hasOwnProperty.call(seen, k) ? false : (seen[k] = true);
    });
  }

  /**
     * This combines two arrays, where duplicate entries are replaced by the newer version (like `upsert`)
     * It does not mutate any parameter
     * It's just a wrapper for unionWith
     *
     * @param existingArray
     * @param newArray
     * @param equalityComparer
     */
  static addAndReplaceExisting<T>(existingArray: T[], newArray: T[], equalityComparer: (a: T, b: T) => boolean) {
    return unionWith(newArray, existingArray, equalityComparer);
  }

  static formatDecimalInput = (obj: any, decimals = 2, maxValue: number) => {
    if (!obj.value) {
      return;
    }
    let result = Utils.parseUserDecimal(obj.value);
    if (result > maxValue || result < -maxValue) {
      result = 0;
    }
    obj.value = Utils.formatDecimal(result, decimals);
  };

  static formatDecimalFormInput = (value: string, decimals: number, maxValue: number): string => {
    if (value == null) {
      return "";
    }
    let result = Utils.parseDecimal(value);
    if (result > maxValue || result < -maxValue) {
      result = 0;
    }
    return Utils.formatDecimal(result, decimals);
  };

  static timeSpanToWrittenTime = (dt1: Date, dt2: Date): string => {
    let diff = dt1.getTime() - dt2.getTime();
    if (diff <= 0) {
      return "";
    }

    const day = 24 * 60 * 60 * 1000;
    const hour = 60 * 60 * 1000;
    const minute = 60 * 1000;

    const diffDays = Math.floor(diff / day);
    diff -= diffDays * day;

    const diffHours = Math.floor(diff / hour);
    diff -= diffHours * hour;

    const diffMinutes = Math.floor(diff / minute);
    diff -= diffMinutes * minute;

    let result = "";
    if (diffDays > 1) {
      result += `${diffDays} ${RESX.DateTime.days}`;
    } else if (diffDays === 1) {
      result += RESX.DateTime.YDay;
    }

    if (diffHours > 1) {
      if (result) {
        result += ", ";
      }
      result += RESX.DateTime.XHours.replace("{0}", diffHours.toString());
    } else if (diffHours === 1) {
      if (result) {
        result += ", ";
      }
      result += RESX.DateTime.YHour;
    }

    if (diffMinutes > 1) {
      if (result) {
        result += ", ";
      }
      result += RESX.DateTime.XMinutes.replace("{0}", diffMinutes.toString());
    } else if (diffMinutes === 1) {
      if (result) {
        result += ", ";
      }
      result += RESX.DateTime.YMinute;
    }

    return result;
  };

  static dialogItem = (url: string) => {
    Utils.openPopupModal(url, 1000, 750, "resultItem(result)");
  };

  static openPopupModal = (url: string, pWidth: number, pHeight: number, dialogId: string): void => {
    const browserLeft = window.screenLeft ? window.screenLeft : window.screenX;
    const browserTop = window.screenTop ? window.screenTop : window.screenY;
    const browserWidth = $(window).width();
    const browserHeight = $(window).height();

    const pLeft = ((browserWidth - pWidth) / 2) + browserLeft;
    const pTop = ((browserHeight - pHeight) / 2) + browserTop;

    // Add the dialogId to the URL
    const params = { dialogId };
    const dialogUrl = urlTools.addParameters(url, params);

    const re = /./g;
    const did = dialogId.replace(re, "");
    if ((!modalWindow) || modalWindow.closed) {
      modalWindow = window.open(dialogUrl,
        did,
        "modal=true,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,resizable=yes,width=" +
        pWidth +
        ",height=" +
        pHeight +
        ",top=" +
        pTop +
        ",left=" +
        pLeft);
    } else {
      modalWindow.focus();
    }
  };

  static clamp = (val: number, min: number, max: number): number => Math.max(min, Math.min(val, max));
}