import * as Sentry from "@sentry/react";
import { getDefaultSentryConfig } from "shared/sentry/initConfig";

Sentry.init({
    integrations: [
        new Sentry.BrowserTracing({
             tracePropagationTargets: []
        }),
        new Sentry.Replay({
            maskAllInputs: false,
            maskAllText: false,
        })],
        ...getDefaultSentryConfig({
            dsn: site.sentryDsn,
            buildVersion: site.build,
            environment: site.sentryEnvironment,
            enabled: process.env.NODE_ENV === "production",
        })

});

if (site?.currentUser != null) {
    Sentry.setUser({
        id: site.currentUser.userId.toString(),
        username: site.currentUser.fullName
    });
}
