const keypress = {
    isNumeric: function (value) {
        // 0-9, period, comma, minus
        return (value >= 48 && value <= 57) || (value >= 44 && value <= 46);
    },
    isAlpha: function (value) {
        // A-Z or a-z
        return (value >= 65 && value <= 90) || (value >= 97 && value <= 122);
    },
    isDigit: function (value) {
        // 0-9
        return value >= 48 && value <= 57;
    },
    isUnderscore: function (value) {
        // _
        return value === 189;
    },

    isTab: function (value) {
        // TAB
        return value === 9;
    }
};

function keypressCheck(obj, t) {
    $(obj).keypress((event) => {
        switch (t) {
            case "lettersdigits":
                if (!(keypress.isDigit(event.which) || keypress.isAlpha(event.which))) {
                    event.preventDefault();
                }
                break;
            case "letters":
                if (!keypress.isAlpha(event.which)) {
                    event.preventDefault();
                }
                break;
            case "digits":
                if (!keypress.isDigit(event.which)) {
                    event.preventDefault();
                }
                break;
            default:
                throw new Error("Keypress type " + t + " is not implemented.");
        }
    });
    return true;
}

const soneTools = {
    getParameterByName: function (name) {
        // Never convert to lower case
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");

        const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
        const results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    formatIntegerInput: function (obj) {
        if (!obj.value) {
            return;
        }
        const formValue = parseInt(obj.value, 10);
        if (isNaN(formValue)) {
            if (isNaN(obj.defaultValue)) {
                obj.value = 0;
            } else {
                obj.value = obj.defaultValue;
            }
        } else {
            obj.value = formValue;
        }
    },

    validateIPAddress: function (ipaddr: string) {
        // Remember, this function will validate only Class C IP.
        // change to other IP Classes as you need
        ipaddr = ipaddr.replace(/\s/g, ""); // remove spaces for checking
        const re = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/; // regex. check for digits and in
        // all 4 quadrants of the IP
        if (re.test(ipaddr)) {
            // split into units with dots "."
            const parts = ipaddr.split(".");
            // if the first unit/quadrant of the IP is zero
            if (parseInt(parseFloat(parts[0]).toString(), 10) === 0) {
                return false;
            }
            // if the fourth unit/quadrant of the IP is zero
            if (parseInt(parseFloat(parts[3]).toString(), 10) === 0) {
                return false;
            }
            // if any part is greater than 255
            for (const part of parts) {
                if (parseInt(parseFloat(part).toString(), 10) > 255) {
                    return false;
                }
            }
            return true;
        } else {
            return false;
        }
    },

    formatIPInput: function (obj) {
        if (!this.validateIPAddress(obj.value)) {
            alert("Not a valid IP address");
            obj.value = "0.0.0.0";
        }
    },

    // Sort by price high to low
    // homes.sort(sort_by("price", true, parseInt));
    // Sort by city, case-insensitive, A-Z
    // homes.sort(sort_by("city", false, function (a) { return a.toUpperCase() }));
    sort_by: function (field, reverse, primer) {
        reverse = reverse ? -1 : 1;
        return function (a, b) {
            a = a[field];
            b = b[field];

            if (typeof primer !== "undefined") {
                a = primer(a);
                b = primer(b);
            }

            if (a < b) {
                return reverse * -1;
            }
            if (a > b) {
                return reverse * 1;
            }
            return 0;
        };
    },

    CrlfToBR: function (str) {
        if (str == null) {
            return null;
        }
        return str.replace(/(\r\n|[\r\n])/g, "<br />");
    },

    // Execute a function from a given string name - used to allow const api calls
    executeFunctionByName: function (functionName, context, ...funcArgs) {
        const namespaces = functionName.split(".");
        const func = namespaces.pop();
        for (const ns of namespaces) {
            context = context[ns];
        }
        return context[func].apply(this, funcArgs);
    },

    // if the last part of a string is a given character, remove it
    stripLastCharacter: function (target, toReplace) {
        if (target.charAt(target.length - 1) === toReplace) {
            return target.substr(0, target.length - 1);
        }
        return target;
    },

    getRadioValue: function (name) {
        const radioObj = document.getElementsByName(name) as any;
        if (!radioObj) {
            return 0;
        }

        const radioLength = radioObj.length;
        if (radioLength === undefined) {
            if (radioObj.checked) {
                return parseInt(radioObj.value, 10);
            }
        }

        for (let i = 0; i < radioLength; i++) {
            if (radioObj[i].checked) {
                return parseInt(radioObj[i].value, 10);
            }
        }
        return 0;
    },

    select_ClearErrorMessage: function (obj) {

        const obj$ = $(obj);

        if (obj.className !== "disabled") {
            obj.className = "";
        }

        obj$.hideWarningMessage();
    },

    cbList_ValidateMinimumLength: function (id, minLength) {
        const groups = document.getElementsByName(id) as any;
        let itemSelectedCount = 0;
        for (const group of groups) {
            if (group.disabled) {
                return true;
            } // Escape function when group is disabled;
            if (group.checked) {
                itemSelectedCount += 1;
            }
        }
        if (itemSelectedCount < minLength) {
            if (minLength === 1) {
                document.getElementById(id + "_err").innerHTML = RESX.GeneralLabels.AtLeast1ItemShouldBeSelected;
            } else {
                document.getElementById(id + "_err").innerHTML = RESX.GeneralLabels.AtLeastXItemsShouldBeSelected.replace("{0}", minLength);
            }
            document.getElementById(id + "HolderDiv").className = "s1_warningborder";
            return false;
        }

        return true;
    },

    checkbox_ValidateEnforceBoolean: function (id, val) {
        const obj = document.getElementById(id) as any;

        if (obj.disabled) {
            return true;
        }

        const divError = document.getElementById(id + "_err");

        if (obj.checked !== val) {
            if (val) {
                divError.innerHTML = RESX.GeneralLabels.MustBeChecked;
            } else {
                divError.innerHTML = RESX.GeneralLabels.MustBeUnchecked;
            }
            obj.className = "s1_warningborder";
            return false;
        }
        return true;
    },

    // Validate Twitter screen name
    custom_ValidateTwitter: function (id) {
        const obj = document.getElementById(id) as any;

        const divError = document.getElementById(id + "_err");
        const formValue = obj.value.trim();
        obj.value = formValue;

        if (!formValue) {
            return true;
        }

        const regExPattern = /^[a-zA-Z0-9\._\-]+$/;
        if (formValue.search(regExPattern) === -1) {
            divError.innerHTML = RESX.Contact.AValidTwitterScreenName;
            obj.className = "s1_warningborder";
            return false;
        }
        return true;
    },

    input_ValidateBookkeepingCodeExists: function (id) {
        const obj = document.getElementById(id) as any;

        if (obj.disabled) {
            return true;
        }

        let code = obj.value.trim();
        code = code.toUpperCase();
        obj.value = code;

        let bookkeepingId = $("#ItemId").val().toString();
        bookkeepingId = (bookkeepingId === "0") ? null : bookkeepingId;


        let result;

        $.ajax({
            async: false, // Important because the function returns a result
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            url: window.CoreApi.Bookkeeping.CodeExists.absoluteUrl(code, +bookkeepingId),
            success: function (codeExists) {
                if (codeExists) {
                    $("#" + id + "_err").html(RESX.Bookkeeping.ABookKeepingWithThisCode);
                    obj.className = "s1_warningborder";
                    result = false;
                } else {
                    result = true;
                }
            }
        });

        return result;
    },

    input_ValidateDisallowInjection: function (id) {
        const obj = document.getElementById(id) as any;
        const obj$ = $(obj);

        if (obj.disabled) {
            return true;
        }

        const formValue = obj.value.trim();

        if (!formValue) {
            return true;
        }

        const regExPattern = /[<>]|(\%3C)|(\%3E)/;
        if (formValue.search(regExPattern) > -1) {
            obj$.displayWarningMessage(RESX.GeneralLabels.InputMayNotContainTheseCharacters);
            obj$.addClass("s1_warningborder");
            return false;
        }
        return true;
    },

    input_ValidateEmail: function (id) {
        const obj = document.getElementById(id) as any;
        const obj$ = $(obj);

        if (obj.disabled) {
            return true;
        }

        const formValue = obj.value.trim();

        if (!formValue) {
            return true;
        }

        if (!SystemOneLibrary.exports.Utils.isValidEmailAddress(formValue)) {
            obj$.displayWarningMessage(RESX.GeneralLabels.EmailIsInvalid);
            obj$.addClass("s1_warningborder");
            return false;
        }
        return true;
    },

    input_ValidateNoDigits: function (id) {
        const obj = document.getElementById(id) as any;
        const obj$ = $(obj);

        if (obj.disabled as any) {
            return true;
        }

        const formValue = obj.value.trim();

        if (hasDigits(formValue)) {
            obj$.displayWarningMessage(RESX.GeneralLabels.NoDigitsAllowed);
            obj$.addClass("s1_warningborder");
            return false;
        }
        return true;
    },

    input_ValidateOnlyLettersAndNumbers: function (id) {
        const obj = document.getElementById(id) as any;
        const obj$ = $(obj);

        if (obj.disabled) {
            return true;
        }

        const formValue = obj.value.trim();

        if (!SystemOneLibrary.exports.Utils.hasOnlyLettersAndNumbers(formValue)) {
            obj$.displayWarningMessage(RESX.GeneralLabels.OnlyLettersAndNumbersAreAllowed);
            obj$.addClass("s1_warningborder");
            return false;
        }
        return true;
    },

    input_ValidateMustBeLargerThan0: function (id) {
        const obj = document.getElementById(id) as any;
        const obj$ = $(obj);

        if (obj.disabled) {
            return true;
        }

        if (!obj.value || parseFloat(obj.value) <= 0) {
            obj$.displayWarningMessage(RESX.GeneralWarnings.PleaseFillInThisField);
            obj$.addClass("s1_warningborder");
            return false;
        }
        return true;
    },

    input_ValidateMinimumLength: function (id, minLength) {
        const obj = document.getElementById(id) as any;
        const obj$ = $(obj);

        if (obj.disabled) {
            return true;
        }

        const formValue = obj.value.trim();

        if (formValue.length < minLength) {
            let message = "";
            if (minLength === 1) {
                message = RESX.GeneralWarnings.PleaseFillInThisField;
            } else {
                message = RESX.GeneralLabels.MinimumLengthOfXCharactersIsRequired.replace("{0}", minLength);
            }
            obj$.displayWarningMessage(message);
            obj$.addClass("s1_warningborder");
            return false;
        }
        return true;
    },

    input_ValidateUrl: function (id) {
        const obj = document.getElementById(id) as any;
        const obj$ = $(obj);

        if (obj.disabled) {
            return true;
        }

        const formValue = obj.value.trim();

        if (!formValue) {
            return true;
        }

        if (!isValidUrl(formValue)) {
            obj$.displayWarningMessage(RESX.GeneralLabels.WebAddressIsInvalid);
            obj$.addClass("s1_warningborder");
            return false;
        }
        return true;
    },

    select_ValidateMustSelect: function (id) {
        const obj = document.getElementById(id) as any;
        const obj$ = $(obj);

        if (obj.disabled) {
            return true;
        }

        if (obj.selectedIndex === 0) {
            obj$.displayWarningMessage(RESX.GeneralLabels.PleaseMakeAChoice);
            obj$.addClass("s1_warningborder");
            return false;
        }
        return true;
    },

    rbList_EnforceChoise: function (id) {
        const radioButtons = document.getElementsByName(id) as any;

        const holder$ = $("#" + id + "HolderDiv");

        for (const btn of radioButtons) {
            if (btn.disabled) {
                holder$.hideWarningMessage();
                return true;
            } // Escape function when checkbox is disabled;
            if (btn.checked) {
                holder$.hideWarningMessage();
                return true;
            }
        }

        holder$.displayWarningMessage(RESX.GeneralLabels.PleaseMakeAChoice);
        holder$.addClass("s1_warningborder");
        return false;
    },

    // ****** Clearerror
    cbList_ClearErrorMessage: function (id) {
        const holderDiv = document.getElementById(id + "HolderDiv");
        if (holderDiv.className !== "disabled") {
            holderDiv.className = "";
        }
        document.getElementById(id + "_err").innerHTML = "";
    },

    input_ClearErrorMessage: function (obj) {
        const obj$ = $(obj);

        const isDisabled = obj$.prop("disabled");
        if (!isDisabled) {
            obj$.removeClass("s1_warningborder");
            obj$.hideWarningMessage();
        }

        const errorElement = document.getElementById(obj.id + "_err");
        if (errorElement) {
            errorElement.innerHTML = "";
        }
    },

    scrollTo: function (jQueryObj) {
        $("html, body").animate({
            scrollTop: $(jQueryObj).offset().top - 20
        }, "slow");
    },

    // Return a helper with preserved width of cells for JQUERY Sortable
    fixSortableWidthHelper: function (e, ui) {
        ui.children()
            .each(function () {
                $(this).width($(this).width());
            });
        return ui;
    },

    isEquivalent: function (a, b, nullAsEmpty) {

        if (nullAsEmpty) {
            if (!a) {
                a = {};
            }
            if (!b) {
                b = {};
            }
        }

        // Create arrays of property names
        const aProps = Object.getOwnPropertyNames(a);
        const bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false;
        }

        for (const propName of aProps) {
            if (isArray(a[propName]) && isArray(b[propName])) {
                if (a[propName].length !== b[propName].length) {
                    return false;
                }

                if (!allCompareEqual(a[propName], b[propName])) {
                    return false;
                }
            }

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    },

    isMobile: function () {
        if (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)
        ) {
            return true;
        } else {
            return false;
        }
    }
};

$(window).bind("beforeunload", () => {
    if (window.modalWindow) {
        window.modalWindow.close();
    }
});

function hasDigits(s) {
    const regExPattern = /[0-9]/;
    return s.search(regExPattern) > -1;
}

function isValidUrl(value) {
    let isValid = true;

    const apiUrl = "/api/v1/isvalidurl";
    const params = { "url": value };

    $.ajax({
        type: "GET",
        async: false,
        url: window.urlTools.addParameters(apiUrl, params)
    }).done((data) => {
        isValid = data; // TODO: ???? looks buggy
    });

    return isValid;
}

// TODO:
function isArray(x) { return Array.isArray(x); }

function allCompareEqual(...array) {
    // e.g.  allCompareEqual([2,2,2,2]) --> true
    // does not work with nested arrays or objects
    return array.every((x) => x === array[0]);
}

// Restrict input into a form element, by providing options.
// allowDigits : false,
// allowLetters : false
// allowUnderscore: false
$.fn.restrictInput = function (options) {

    function charAllowed(char) {
        if (options.allowDigits && (keypress.isDigit(char))) {
            return true;
        }

        if (options.allowLetters && (keypress.isAlpha(char))) {
            return true;
        }

        if (options.allowUnderscore && (keypress.isUnderscore(char))) {
            return true;
        }

        // // Always enable tab, to not break standard logic in page
        // if (keypress.isTab(char)) {
        //    return true;
        // }

        return false;
    }

    function sanitize(value) {
        let str = "";

        for (let i = 0, len = value.length; i < len; i++) {
            if (charAllowed(value[i].charCodeAt())) {
                str += value[i];
            }
        }

        return str;
    }

    const element$ = $(this);

    element$.keypress((event) => {

        if (charAllowed(event.which)) {
            return true;
        }

        event.preventDefault();
        return false;
    })
        .on("paste", () => {
            setTimeout(() => {
                const sanitizedValue = sanitize(element$.val());
                // Set it back into the input
                element$.val(sanitizedValue);
            }, 1);
        });
};

// Check if an item is in localStorage - if it is missing, call a deferred function to generate it, then store it
const cache = (function (localStorage) {
    function init(ls) {
        localStorage = ls;
    }

    function localStorageGetAndStore(key, generator) {
        const d = $.Deferred();

        let item;

        if (localStorage) {
            const todaysDate = new Date();

            item = JSON.parse(localStorage.getItem(key));

            if (!item || !DateTools.sameDay(new Date(item.date), todaysDate)) {
                if (item && !DateTools.sameDay(new Date(item.date), todaysDate)) {
                    localStorage.clear();
                }

                item = {
                    date: new Date(),
                    item: {}
                };

                localStorage.removeItem(key);
                generator().done((v) => {
                    item.item = v;

                    try {
                        localStorage.setItem(key, JSON.stringify(item));
                    } catch (ex) {

                        console.warn("failure setting to localstorage", ex);
                        localStorage.clear();
                    } finally {
                        d.resolve(item);
                    }

                });
            } else {
                d.resolve(item);
            }

        } else {
            item = {
                date: new Date(),
                item: {}
            };

            generator().done((v) => {
                item.item = v;
                d.resolve(item);
            });
        }

        return d;
    }

    return {
        localStorage: localStorageGetAndStore,
        init: init
    };
}(localStorage));

$(document).ready(() => {

    if (window.location.hash && window.location.hash === "#_=_") {
        if (window.history && history.pushState) {
            window.history.pushState("", document.title, window.location.pathname);
        } else {
            // Prevent scrolling by storing the page"s current scroll offset
            const scroll = {
                top: document.body.scrollTop,
                left: document.body.scrollLeft
            };
            window.location.hash = "";
            // Restore the scroll offset, should be flicker free
            document.body.scrollTop = scroll.top;
            document.body.scrollLeft = scroll.left;
        }
    }
});

export { cache, keypress, soneTools };

(window as any).soneTools = soneTools;
(window as any).keypress = keypress;

// module.exports = {
//    keypress
// }
