$.fn.appendVisibleToExternalIcon = function (value: boolean) {
  function getTitle() {
    return value
      ? RESX.GeneralLabels.VisibleToExternalUsers
      : RESX.Planning.NotVisibleToExternalUsers;
  }

  const holder$ = this.appendDiv({
    class: "s1_compositedicon tooltiptarget",
    title: getTitle()
  });

  holder$.appendSpan({
    class: "icon-eye"
  });

  if (!value) {
    holder$.addClass("s1_inactive");

    holder$.appendSpan({
      class: "icon-forbidden layered"
    });
  }

  return this;
};

// Turn it into a module to satisfy `isolatedModules`
export {};