$.fn.attendeechooser2 = function (settings) {
    var tr$, internalValue;
    var attendeeItemChooser$;
    var this$;
    var suggestion$;

    var config = {
        labels: {
            rowlabel: "set label"
        },
        values: []
    };

    if (settings) {
        $.extend(config, settings);
    }

    var mapOldstyleToAttendee = function (values) {

        if (values === null) {
            return null;
        }

        var mapped = values.map(function (obj) {
            return {
                contactId: obj.key,
                name: {fullName: obj.value},
                position: obj.sublabel
            };
        });

        return mapped;
    }

    var itemchooserChanged = function (items) {
        internalValue = mapOldstyleToAttendee(items);
    };

    function mapAttendeesToOldstyle(values) {
        if (values === null) {
            return null;
        }

        return values.map(function (obj) {
            return {
                key: obj.contactId,
                value: obj.name.fullName,
                sublabel: obj.position
            };
        });
    }


    var setItemchooserValues = function () {
        var items = mapAttendeesToOldstyle(internalValue);
        attendeeItemChooser$.itemchooser("setItems", items);
    }

    var set = function (attendees) {
        internalValue = attendees;
        setItemchooserValues();
    }

    var loadSuggestionsByArtistInitial = function (artist) {
        if (!artist || !artist.artistId) {
            return;
        }

        window.CoreApi.CompanyToContact.ListContactByCompany({
            companyId: artist.artistId,
            page: 1,
            perPage: 1000,
            isActive: true,
        }).then(function (result) {
                var contactsWithWorkRelation = result.data.data;

                var selectedContactIds = internalValue.map(item => item.contactId);

                var contactNotSelect = contactsWithWorkRelation.map(item => item.contact).filter(item => selectedContactIds.indexOf(item.contactId) === -1);
                var notSelectedOldStyle = mapAttendeesToOldstyle(contactNotSelect);

                suggestion$.attendeesuggestion2("setItems", notSelectedOldStyle);
            });
    };

    var get = function () {
        return internalValue;
    }

    this.val = function (artist, attendees) {
           if ((typeof artist === 'undefined') || (typeof attendees === 'undefined')) {
            return get();
        }

        if (artist) {
            showRow();
        }

        var setReturn = set(attendees);
        loadSuggestionsByArtistInitial(artist);
        return setReturn;
    }

    this.attendeeItemChooser$ = function () {
        return attendeeItemChooser$;
    }

    this.hideRow = function () {
        set([]);
        tr$.hide();
    };

    var showRow = function () {
        tr$.show();
    };

    this.validate = function () {
        return attendeeItemChooser$.itemchooser("validate");
    }

    this.loadSuggestionsByArtist = function (artistId) {
        window.CoreApi.CompanyToContact.ListContactByCompany({
            companyId: artistId,
            page: 1,
            perPage: 1000,
            isActive: true,
        }).then(function (result) {
            var contactsWithWorkRelation = result.data.data;

            var defaultSelected = contactsWithWorkRelation.filter(item => item.defaultSelect).map(item => item.contact);
            set(defaultSelected);  

            var contactNotSelect = contactsWithWorkRelation.filter(item => !item.defaultSelect).map(item => item.contact);
            var notSelectedOldStyle = mapAttendeesToOldstyle(contactNotSelect);
           // console.log("loadSuggestionsByArtistInitial", contactNotSelect, notSelectedOldStyle, defaultSelected);
            suggestion$.attendeesuggestion2("setItems", notSelectedOldStyle);

            showRow();
        });
    };

    return this.each(function () {

        //Should be attached to tbody$
        this$ = $(this);

        tr$ = this$.appendTr({
            "style": "display:none;"
        });

        //add label
        tr$.appendTd({
            "class": "label",
            "text": config.labels.rowlabel
        });

        //add itemchooser cell
        var chooserTd$ = tr$.appendTd({
            "style": "min-width:350px;",
            "class": "disable_select"
        });
        attendeeItemChooser$ = chooserTd$.itemchooser({
            "inputClass": "s1_magnifyingicon",
            max: 25,
            min: 1,
            onNavigationConfirmChanges: true,
            url: {},
            autocompleteApi: "window.contactV2FilterHack"
        });

        //add suggestion cell
        var suggestionTd$ = tr$.appendTd({

        });

        //Initialize suggestions
        suggestion$ = suggestionTd$.attendeesuggestion2({
            attendeeItemChooser$: attendeeItemChooser$
        });

        attendeeItemChooser$.bind("myremove", function (event) {
            suggestion$.attendeesuggestion2("addItem", event.obj);
        });

        attendeeItemChooser$.bind("mychange", function (data) {
            itemchooserChanged(data.items);
            suggestion$.attendeesuggestion2("fillFromData");
        });

    });
};

$.widget("sone.attendeesuggestion2", {
    options: {
        attendeeItemChooser$: null
    },
    _create: function () {
        this.data = [];
        this.fillFromData();
    },
    _init: function () {

    },
    addItem: function (obj) {
        //Check if AddItem is actually an update
        for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].key == obj.key) {
                this.data[i] = obj; //Override with new data

                this.fillFromData();
                return;
            }
        }

        this.data.push(obj);
        this.fillFromData();
    },
    setItems: function (data) {
        this.data = data;
        this.fillFromData();
    },
    fillFromData: function () {
        this.element.empty();

        var filteredData = this._getNonSelectedSuggestions();
        if (filteredData.length === 0) {
            //            if (this.element.width() <= 8) {
            //                this.element.hide();
            //            }

            return;
        }
        this.element.show();

        //Add label
        var b$ = $("<b />", { "text": RESX.Planning.ConsiderIncluding + ": " });
        this.element.append(b$);

        //Render names
        for (var i = 0; i < filteredData.length; i++) {
            this._appendName(filteredData[i]);
            if ((i + 1) < filteredData.length) {
                this.element.append(", ");
            }
        }
    },
    _appendName: function (obj) {
        var that = this;

        var a$ = $("<a />", { "text": obj.value });
        a$.click(function () {
            //Add name to chosen list of attendees
            that.options.attendeeItemChooser$.itemchooser("addItem", obj);
        });

        this.element.append(a$);
    },
    _getNonSelectedSuggestions: function () {

        //Get data from at
        var attendeeChooserData = this.options.attendeeItemChooser$.itemchooser("getItems");

        var result = [];
        for (var s = 0; s < this.data.length; s++) {
            var found = false;

            for (var a = 0; a < attendeeChooserData.length; a++) {
                if (this.data[s].key == attendeeChooserData[a].key) {
                    found = true;
                }
            }
            if (!found) {
                result.push(this.data[s]);
            }
        }

        result.sort(soneTools.sort_by("value", false, function (a) { return a.toUpperCase(); }));

        return result;
    }
});