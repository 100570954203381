import React from "react";
import { createRoot } from "react-dom/client";
import GenericModal from "./genericmodal/genericmodal";
import DeleteConfirmationModal from "./genericmodal/deleteconfirmationmodal";
import FinancialRiskModal from "./components/FinancialRiskModal";
import TimeoutModal from "./TimeoutModal/App";
import DefaultProviders from "./common/DefaultProviders";

jQuery(() => {
  const modalEl = document.getElementById("generic-react-modal");
  if (modalEl) {
    const root = createRoot(modalEl);
    root.render(
      <DefaultProviders>
        <GenericModal />
        <DeleteConfirmationModal />
        <FinancialRiskModal />
        <TimeoutModal />
      </DefaultProviders>
    );
  }
});
