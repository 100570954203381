import m from "moment";

declare const $: any

$.fn.selectArtist = function (settings) {
    var div$, select$, chosenDiv$, internalValue;
    var this$;

    var config = {

    };

    if (settings) {
        $.extend(config, settings);
    }

    var showSelect = function (id) {
        if (select$) {
            select$.val(id);
            if (chosenDiv$) chosenDiv$.hide();
            select$.show();
        } else {
            window.Api.Artists.GetArtistPickerData({
                filter: "limitByUserAccess"
            })
                .then(function (result) {
                    select$ = div$.appendSelect({
                        "change": function () {
                            var selectedOption = this.options[this.selectedIndex];
                            internalValue = $(selectedOption).data("artist");

                            //    console.log(internalValue);

                            var event = $.Event("mychange");
                            event.artist = internalValue;
                            this$.trigger(event);

                            validate();
                        }
                    });

                    var emptyOption = select$.appendOption({});
                    emptyOption.data("artist", null);

                    result.data.artists.forEach((artist, idx) => {
                        var option = select$.appendOption({
                            "value": artist.artistId,
                            "text": artist.name
                        });

                        option.data("artist", artist);

                        if (id === artist.artistId) {
                            option.prop("selected", "true");
                        }
                    });

                    if (chosenDiv$) chosenDiv$.hide();
                });
        }
    }

    var renderChosen = function (artist: server.dto.ArtistSmall) {

        if (!artist) {
            showSelect(null);
            return;
        }

        if (select$) select$.hide();

        if (chosenDiv$) {
            chosenDiv$.data("artist", artist);
            chosenDiv$.show();
        } else {
            chosenDiv$ = div$.appendDiv({
                "class": "flex s1_text",
                "click": function () {
                    showSelect(chosenDiv$.data("artist").id);
                }
            });

            chosenDiv$.data("artist", artist);

            chosenDiv$.appendA({
                "class": "icon-x small",
                "style": "padding-right:5px;padding-top:3px;font-size:10px;"
            });

            var span = chosenDiv$.appendSpan({
                "text": artist.name
            });

            chosenDiv$.renderName = function () {
                span.text(chosenDiv$.data("artist").name);
            };
        }

        chosenDiv$.renderName();
    }

    var set = function (artist) {
        internalValue = artist;
        renderChosen(artist);
    }

    var get = function () {
        return internalValue;
    }

    this.val = function (artist) {
        return (typeof artist === 'undefined') ? get() : set(artist);
    }

    var validate = function () {
        if (!internalValue) {
            div$.addClass("s1_warningborder");
            return false;
        } else {
            div$.removeClass("s1_warningborder");
            return true;
        }
    }

    this.validate = function () {
        return validate();
    }

    return this.each(function () {
        this$ = $(this);

        div$ = this$.appendDiv({});
    });
};