$.widget("sone.panelwidgetbase", {
    options: {
        labels: [],
        collection: null,
        pagination: null,
        tableClass: "s1_table list",
        displayNoDataMessage: true,
        displayTotal: true
    },
    _create: function () {
        var that = this;
        this.id = this.element.attr("id");

        this.initPromise = null;

        this.content$ = this.element.find(".content").first();

        this.headerLabel$ = this.element.find("H1").empty();

        this.addButton$ = $("#" + this.id + "_addbtn");
        this.addButton$.click(function () {
            that._onAddButtonClick();
        });
    },
    _init: function () {
        if ($("#" + this.id + "_datachanged").val() === "true" || this.options.collection == null) {
            this.requeryServer();
        } else {
            this._updateData(this.options.collection, this.options.pagination);
        }
    },
    _tryInitPagination: function (pagination) {
        var that = this;

        if (pagination && !this.paginationWidget) {
            this.page = pagination.page;

            var div = $("<div />", {
                "class": "margin-right"
            });
            this.element.find("H1").parent().after(div);

            this.paginationWidget = div.pagination({
                pagination: pagination,
                pageClass: "pagination_small",
                callback: function (pageNumber) {
                    that.page = pageNumber;
                    that.requeryServer(pageNumber);
                }
            });
        }
    },
    getData: function () {
        var that = this;

        return {
            label: that.options.headerLabel,
            totalItems: that.totalItems,
            collection: that.collection,
            addButton: that.addButton$,
            showAddDialog: function () {
                that._onAddButtonClick();
            }
        };
    },
    setData: function (collection, pagination) {
        //Call the setData method when the data in page is changed;
        var dataChanged = (this.collection);

        this._updateData(collection, pagination);

        if (dataChanged) {
            this._dataInPageChanged();
        }

    },
    _setData: function (collection, pagination) {
        //Call the setData method when the data in page is changed;

        this._updateData(collection, pagination);
        this._dataInPageChanged();
    },
    _updateData: function (collection, pagination) {

        this.collection = collection;
        this.totalItems = (pagination) ? pagination.total : collection.length;

        if (pagination) {
            this._tryInitPagination(pagination);
            this.paginationWidget.pagination("updatePagination", pagination);
        }

        if (pagination && pagination.pageCount > 1 && this.options.displayTotal) {
            this._setHeaderLabel(this.options.headerLabel + ": " + this.totalItems);
        } else {
            this._setHeaderLabel(this.options.headerLabel);
        }

        this._drawPanel(collection);
    },
    _dataInPageChanged: function () {
        $("#" + this.id + "_datachanged").val("true");
        var event = $.Event("dataChanged");
        this.element.trigger(event);
    },
    _drawPanel: function (collection) {

        this.content$.empty();

        if (collection.length > 0) {
            this._drawTable(collection);
        } else {
            this._drawNoData();
        }
    },
    _drawTable: function (collection) {
        var table$ = $("<table />", {
            "class": this.options.tableClass
        });

        this._appendTableHeader(table$);
        this._appendTableBody(table$, collection);
        this._appendTableFooter(table$, collection);

        this.content$.append(table$);
    },
    _drawNoData: function () {
        if (!this.options.displayNoDataMessage) {
            return;
        }

        this.noDataDiv$ = this.element.append(`<div class=\"no-data-available yellow flex gap-large align-center\"><span><i class=\"icon-no-data-available icon-list-unordered\" /></span><span>${RESX.GeneralLabels.NoResultsFound}</span></div>`);
    },
    _appendTableHeader: function () {
        //Can be implemented
    },
    _appendTableBody: function (table$, collection) {
        var that = this;

        this.tbody$ = table$.appendTbody();

        $.each(collection, function () {
            that._appendBodyRow(that.tbody$, this);
        });
    },
    _appendTableFooter: function () {
        //Can be implemented
    },
    _appendBodyRow: function () {
        alert("_appendBodyRow not implemented");
    },
    _setHeaderLabel: function (label) {
        this.headerLabel$.text(label);
    },
    validate: function () {

    },
    _displaySpinner: function () {
        var previousHeight = this.content$.height();
        this.content$.empty();
        this.content$.appendSpinnerImage(previousHeight);
    },
    requeryServer: function () {
        alert("requeryServer not implemented");
    },
    _onAddButtonClick: function () {
        alert("_onAddButtonClick not implemented");
    }
});