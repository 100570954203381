/*global UI */

$.widget("sone.chooseartistdialog", $.sone.dialog2, {
    options: {
        height: 700,
        width: 1100,
        closeOutside: true,
        title: RESX.Buttons.btnMakeSelection
    },
    _create: function () {
        this._super();

        var that = this;
        this.keyword = "";

        this.paginationWidget = $("<div />", {
            "style": "text-align:right;",
        }).pagination({
            pagination: {
                page: 1
            },
            pageClass: "pagination_small",
            callback: function (pageNumber) {
                that.page = pageNumber;
                that.requeryServer(pageNumber, null);
            }
        });

        this._appendSearchBar();

        this.element.append(this.paginationWidget);
        this._addArtistTable();
        this.errorDiv$ = this.element.appendErrorDiv();
        this._drawNoData();
    },
    _appendSearchBar: function () {
        var that = this;

        var div = $("<div />", {

        });
        div.appendInput({
            "type": "text",
            "placeholder": RESX.GeneralLabels.Search,
            "class": "s1_magnifyingicon",
            "keyup": function () {
                that._searchByKeyword(this.value);
            }
        });
        this.element.append(div);
    },
    _searchByKeyword: function (keyword) {
        var that = this;

        that.keyword = keyword;
        if (that.allArtists != null) {
            that.artistsToDisplay = that.allArtists.filter(a => a.name.toLowerCase().includes(keyword.toLowerCase()));
            that._addRows(that.artistsToDisplay);
            that.noDataDiv$.toggle(that.artistsToDisplay.length === 0);
        }
    },
    _resizeElements: function () { },
    _drawNoData: function () {
        this.noDataDiv$ = this.element.appendDiv({
            text: RESX.GeneralLabels.NoResultsFound,
            class: "ui message",
            "style": "display:hidden;"
        });
    },
    _addArtistTable: function () {
        var table$ = $("<table />", {
            "class": "s1_table list"
        });

        this.tbody$ = table$.appendTbody({});

        this.element.append(table$);
    },
    open: function (excludeKeys) {
        var that = this;
        this.excludeKeys = excludeKeys;
        this.requeryServer(1);

        this._super();

        this.deferred$ = $.Deferred();
        return this.deferred$;
    },
    requeryServer: function (page) {
        var that = this;

        window.Api.Artists.GetArtistPickerData({ filter: "allForClient" })
            .then(res => {
                const artists = res.data
                    .artists
                    .filter(artist => !that.excludeKeys.includes(artist.artistId));

                that.allArtists = artists;
                that.artistsToDisplay = that.allArtists.filter(a => a.name.toLowerCase().includes(that.keyword.toLowerCase()));

                that._addRows(that.artistsToDisplay);

                that.noDataDiv$.toggle(that.artistsToDisplay.length === 0);
            });
    },
    _updateData: function (collection, pagination) {
        this.collection = collection;

        if (pagination) {
            this.paginationWidget.pagination("updatePagination", pagination);
        }

        this._drawPanel(collection);
    },
    _addRows: function (data) {
        var that = this;

        this.tbody$.empty();

        $.each(data, function (index, artist) {
            that._addRow(that.tbody$, artist);
        });

    },
    _addRow: function (tbody$, artist) {
        var that = this;

        var tr$ = tbody$.appendTr({
            "class": "hoverable",
            "click": function () {
                that.close();
                that.deferred$.resolve(artist);
            }
        });

        tr$.appendTd({
            text: artist.name
        });
    }
});