require("./base.panel");

$.widget("sone.webforms", $.sone.panelwidgetbase, {
    options: {
        displayTotal: false,
        perPage: 10
    },
    _create: function () {
        this._super();
    },
    _appendBodyRow: function (tbody$, item) {

        var tr$ = $("<tr />", {});

        this._appendTitle(tr$, item);
        this._uniqueLink(tr$, item);

        tbody$.append(tr$);
    },
    _appendTitle: function (tr$, item) {
        var cell$ = tr$.appendTd({

        });

        cell$.appendA({
            "href": `/webform/${item.webformId}/edit`,
            "text": item.title
        });

        var fromNow = RESX.GeneralLabels.LastModified + ": " + moment(item.lastModifiedUtc).fromNow();

        cell$.appendDiv({
            "class": "s1_gray",
            "text": fromNow
        });
    },
    _uniqueLink: function (tr$, item) {
        var cell$ = tr$.appendTd({

        });

        cell$.appendA({
            "target": "_blank",
            "href": item.uniqueUrl,
            "text": RESX.Webform.UniqueLink
        });

    },
    requeryServer: function (pageNumber) {
        var that = this;

        var keyword = $("#keyword").val();

        this._displaySpinner();

        if (pageNumber === undefined) {
            pageNumber = 1;
        }

        return WebApi.Webforms.List(keyword, pageNumber, this.options.perPage)
            .done(
                function (data) {
                    that._setData(data.data, data.pagination);
                });
    }
});