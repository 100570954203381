import "./sone-ui-form";

$.widget("sone.addressinput", {
    options: {
        disabled: false,
        inputVisibleRequired: [],
        countryHasBlankOption: false,
        inMergeMode: false,
        label: [],
        source: null,
        isHidden: false,
        target: [],
        responsive: false,
    },
    _create: function () {

        this.data = $.extend({}, this.options);
        this.isFirst = true;

        //Fill state objects if ID has been provided.
        if (this.data.source !== null) {
            this.data.source.state = Address.FindStateById(this.data.source.stateId);
        }

        //Add attribute to element for later hiding
        this.element.attr("section", this.getId());

        this.currentElement = this.element;

        this._createNameRow();
        this._createAddressLine1Row();
        this._createAddressLine2Row();
        this._createPostalCodeRow();
        this._createCityRow();
        this._createCountryRow();
        this._createStateRow();
        if (this.data.target.latLng) {
            this._createHiddenLatLng(this.data.target.latLng);
        }

        formObj.setConfirmForInputs(this._getRows());

        if (this.options.isHidden) {
            this.hide();
        }
    },
    _init: function () {
        var event = $.Event("ready");

        this.element.trigger(event);

        this.setValue(this.data.target);
    },
    _appendRow: function (row) {

        if (this.options.responsive && this.isFirst) {
            // The first row is added to the beginning of the div
            // The next rows are added after the previous row
            this.currentElement.prepend(row);
            this.currentElement = row;

            this.isFirst = false;
        } else {
            this.currentElement.after(row);
            this.currentElement = row;
        }
    },
    _getParamRowOrResponsiveRow: function (label, targetControl, inMergeMode, isRequired) {
        if (this.options.responsive) {
            return $("<div>").divrow(label, targetControl, isRequired);
        } else {
            return $("<tr />").paramrow(label, targetControl, inMergeMode, isRequired);
        }
    },
    _createNameRow: function () {

        var formName = this.getId() + "name";
        var that = this;

        this.nameInput$ = $("<input />", {
            "type": "text",
            "maxLength": "100",
            "name": formName,
            "id": formName,
            "keydown": function (e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    return false;
                }
            }
        });
        this.nameInput$.attr("autocomplete", "off");
        this.nameInput$.css("width", "100%");

        //Add row
        var isRequired = this.options.inputVisibleRequired.name === "visibleMandatory";
        this.nameRow$ = this._getParamRowOrResponsiveRow(this.data.label.name, this.nameInput$, this.data.inMergeMode, isRequired);
        this.nameRow$.attr("section", this.getId());

        this.nameInput$.on("keyup change", function () {
            that.validateName();

            var event = $.Event("namekeyup");
            event.inputObj = this;
            that.element.trigger(event);
        });

        if (this.options.inputVisibleRequired.name === "disabled") {
            this.nameInput$.prop("disabled", true);
        }
        if (this.options.inputVisibleRequired.name === "notVisible") {
            this.nameRow$.hide();
        }

        this._appendRow(this.nameRow$);
    },
    _createAddressLine1Row: function () {
        var formName = this.getId() + "AddressLine1";
        var that = this;

        this.addressLine1Input$ = $("<input />", {
            "type": "text",
            "maxLength": serverReference.constants.address.maxAddressLineLength,
            "name": formName,
            "id": formName
        });
        this.addressLine1Input$.css("width", "100%");

        //Add row
        var isRequired = this.options.inputVisibleRequired.addressLine1 === "visibleMandatory";
        this.addressLine1Row$ = this._getParamRowOrResponsiveRow(this.data.label.addressLine1, this.addressLine1Input$, this.data.inMergeMode, isRequired);
        this.addressLine1Row$.attr("section", this.getId());

        this.addressLine1Input$.blur(function () {
            that.validateAddressLine1();
        });

        if (this.options.inputVisibleRequired.addressLine1 === "notVisible") {
            this.addressLine1Row$.hide();
        }

        this._appendRow(this.addressLine1Row$);
    },
    _createAddressLine2Row: function () {
        var formName = this.getId() + "AddressLine2";
        var that = this;

        this.addressLine2Input$ = $("<input />", {
            "type": "text",
            "maxLength": serverReference.constants.address.maxAddressLineLength,
            "name": formName,
            "id": formName

        });
        this.addressLine2Input$.css("width", "100%");

        //Add row
        var isRequired = this.options.inputVisibleRequired.addressLine2 === "visibleMandatory";
        this.addressLine2Row$ = this._getParamRowOrResponsiveRow(this.data.label.addressLine2, this.addressLine2Input$, this.data.inMergeMode, isRequired);
        this.addressLine2Row$.attr("section", this.getId());

        this.addressLine2Input$.blur(function () {
            that.validateAddressLine2();
        });

        if (this.options.inputVisibleRequired.addressLine2 === "notVisible") {
            this.addressLine2Row$.hide();
        }

        this._appendRow(this.addressLine2Row$);
    },
    _createPostalCodeRow: function () {
        var formName = this.getId() + "PostalCode";
        var that = this;

        this.postalCodeInput$ = $("<input />", {
            "type": "text",
            "maxLength": serverReference.constants.address.maxPostalCodeLength,
            "name": formName,
            "id": formName

        });
        this.postalCodeInput$.css("width", "100%");

        //Add row
        var isRequired = this.options.inputVisibleRequired.postalCode === "visibleMandatory";
        this.postalCodeRow$ = this._getParamRowOrResponsiveRow(this.data.label.postalCode, this.postalCodeInput$, this.data.inMergeMode, isRequired);
        this.postalCodeRow$.attr("section", this.getId());

        this.postalCodeInput$.blur(function () {
            that.validatePostalCode();
        });

        if (this.options.inputVisibleRequired.postalCode === "notVisible") {
            this.postalCodeRow$.hide();
        }

        this._appendRow(this.postalCodeRow$);
    },
    _createCityRow: function () {
        var formName = this.getId() + "City";
        var that = this;

        this.cityInput$ = $("<input />", {
            'type': "text",
            'maxLength': serverReference.constants.address.maxCityLength,
            'name': formName,
            'id': formName
        });
        this.cityInput$.css("width", "100%");

        //Add row
        var isRequired = this.options.inputVisibleRequired.city === "visibleMandatory";
        this.cityRow$ = this._getParamRowOrResponsiveRow(this.data.label.city, this.cityInput$, this.data.inMergeMode, isRequired);
        this.cityRow$.attr("section", this.getId());

        this.cityInput$.blur(function () {
            that.validateCity();
        });

        this._appendRow(this.cityRow$);
    },
    _createCountryRow: function () {
        var formName = this.getId() + "CountryId";
        var that = this;

        var countrySelect$ = $("<select />", {
            'name': formName,
            'id': formName
        });
        countrySelect$.css("width", "100%");

        //change state list
        countrySelect$.change(function () {
            var countryId = this.value;
            var states = Address.GetStateOptionsByCountryId(countryId);

            var stateId = (states.length === 0) ? null : states[0].key;
            that._setStateOptions(stateId, states);
            that.validateCountry();
        });

        //Add no filter option
        if (this.data.countryHasBlankOption) {
            countrySelect$
                .append($("<option></option>", { "value": "" })
                    .text("--- " + RESX.GeneralLabels.MakeSelection + " ---"));
        }

        //add other options
        $.each(window.serverReference.countries, function (key, obj) {
            countrySelect$
                .append($("<option />")
                    .attr("value", obj.key)
                    .text(obj.name));
        });

        this.countrySelect$ = countrySelect$;

        //Add row
        var isRequired = this.options.inputVisibleRequired.country === "visibleMandatory";
        this.countryRow$ = this._getParamRowOrResponsiveRow(this.data.label.country, this.countrySelect$, this.data.inMergeMode, isRequired);
        this.countryRow$.attr("section", this.getId());

        this._appendRow(this.countryRow$);
    },
    _createStateRow: function () {
        var formName = this.getId() + "StateId";

        this.stateSelect$ = $("<select />", {
            'name': formName,
            'id': formName
        });
        this.stateSelect$.css("width", "100%");

        //Add row
        var isRequired = this.options.inputVisibleRequired.state === "visibleMandatory";
        this.stateRow$ = this._getParamRowOrResponsiveRow(this.data.label.state, this.stateSelect$, this.data.inMergeMode, isRequired);
        this.stateRow$.attr("section", this.getId());

        if (this.data.countryHasBlankOption) {
            this.stateRow$.hide();
        }

        this._appendRow(this.stateRow$);
    },
    _createHiddenLatLng: function (latLng) {
        var formNameLat = this.getId() + "Lat";

        this.hiddenLat$ = $("<input />", {
            'name': formNameLat,
            'id': formNameLat,
            'type': "hidden",
            "value": latLng[0]
        });

        this.hiddenLat$.appendTo("form");

        var formNameLng = this.getId() + "Lng";

        this.hiddenLng$ = $("<input />", {
            'name': formNameLng,
            'id': formNameLng,
            'type': "hidden",
            "value": latLng[1]
        });

        this.hiddenLng$.appendTo("form");
    },

    _setStateOptions: function (stateId, states) {
        //refer to select box
        var stateSelect$ = this.stateSelect$;

        if (!stateSelect$) {
            return;
        }

        //clear all options
        stateSelect$.find("option").remove();

        if (states.length === 0) {
            if (this.data.inMergeMode) {
                stateSelect$.hide();
            } else {
                this.stateRow$.hide(400);
            }

        } else {
            if (this.countryRow$.is(":visible")) {
                this.stateRow$.show(400);
                stateSelect$.show();
            }

            $.each(states, function (key, obj) {
                stateSelect$
                    .append($("<option />")
                        .attr("value", obj.key)
                        .text(obj.value));
            });
            stateSelect$.val(stateId);
        }
    },
    _getRows: function () {
        return $("tr[section='" + this.getId() + "']");
    },
    getId: function () {
        return this.element.attr("id");
    },
    hide: function () {
        const rows = this._getRows();
        rows.hide();

        rows.closest("div").hide();
    },
    show: function () {
        const rows = this._getRows();
        rows.show();

        rows.closest("div").show();
    },
    getValue: function () {

        // Retrieve values from Form
        var value = {
            'name': this.nameInput$.val(),
            'addressLine1': this.addressLine1Input$.val(),
            'addressLine2': this.addressLine2Input$.val(),
            'postalCode': this.postalCodeInput$.val(),
            'city': this.cityInput$.val(),
            'stateId': this.stateSelect$.val(),
            'state': Address.FindStateById(this.stateSelect$.val())
        };

        //Set internal value
        this.data.target = value;

        // Copy reference
        return value;
    },
    setValue: function (value) {
        var that = this;

        var createMergeElement = function (input, type, strategy, data, onclick) {
            var mse = new UI.MergeSourceElement(input.attr("id"), type, strategy);
            mse.DataSet = data;
            mse.OnClickFunctions = onclick;
            mse.Init();
            return mse;
        };

        //Try find state by stateId
        value.state = Address.FindStateById(value.stateId);

        //Set internal value
        this.data.target = value;

        //Set form variables set variable
        this.nameInput$.val(value.name);
        this.addressLine1Input$.val(value.addressLine1);
        this.addressLine2Input$.val(value.addressLine2);
        this.postalCodeInput$.val(value.postalCode);
        this.cityInput$.val(value.city);
        if (value.state) {
            var states = Address.GetStateOptionsByCountry(value.state.Country);
            this._setStateOptions(value.state.key, states);
            this.countrySelect$.val(value.state.Country.key);
        } else {
            this._setStateOptions(null, []);
            this.countrySelect$.val();
        }

        if (this.data.inMergeMode) {
            createMergeElement(this.nameInput$, "Textbox", new UI.MergeSourceStrategy.TextBox(),
                [{ Key: this.data.source.name, Value: this.data.source.name }]);
            createMergeElement(this.addressLine1Input$, "Textbox", new UI.MergeSourceStrategy.TextBox(),
                [{ Key: this.data.source.addressLine1, Value: this.data.source.addressLine1 }]);
            createMergeElement(this.addressLine2Input$, "Textbox", new UI.MergeSourceStrategy.TextBox(),
                [{ Key: this.data.source.addressLine2, Value: this.data.source.addressLine2 }]);
            createMergeElement(this.postalCodeInput$, "Textbox", new UI.MergeSourceStrategy.TextBox(),
                [{ Key: this.data.source.postalCode, Value: this.data.source.postalCode }]);
            createMergeElement(this.cityInput$, "Textbox", new UI.MergeSourceStrategy.TextBox(),
                [{ Key: this.data.source.city, Value: this.data.source.city }]);
            if (this.data.source.state) {
                var updateStates = function () {
                    var s = Address.GetStateOptionsByCountry(that.data.source.state.Country);
                    that._setStateOptions(that.data.source.state.key, s);
                    stateStrategy.preClick = null;
                    that.stateRow$.sourceCell$.click();
                    stateStrategy.preClick = statePreClick;
                };
                var statePreClick = function () {
                    that.countryRow$.sourceCell$.click();
                    return { cancel: true };
                };
                createMergeElement(this.countrySelect$, "Dropdown",
                    new UI.MergeSourceStrategy.DropDown(),
                    [{ Key: this.data.source.state.Country.key, Value: this.data.source.state.Country.name }],
                    [updateStates]);
                var stateStrategy = new UI.MergeSourceStrategy.DropDown();
                stateStrategy.preClick = statePreClick;
                createMergeElement(this.stateSelect$, "Dropdown", stateStrategy,
                    [{ Key: that.data.source.state.key, Value: that.data.source.state.name }]);
            } else {
                var countryClick = function () {
                    stateStrategy1.preClick = null;
                    that.stateRow$.sourceCell$.click();
                    stateStrategy1.preClick = statePreClick;
                };
                var stateStrategy1 = new UI.MergeSourceStrategy.DropDown();

                createMergeElement(this.countrySelect$, "Dropdown",
                    new UI.MergeSourceStrategy.DropDown(),
                    [{ Key: null, Value: "" }], [countryClick]);
                createMergeElement(this.stateSelect$, "Dropdown",
                    stateStrategy1,
                    [{ Key: null, Value: "" }]);
            }
        }
    },
    validate: function () {

        if (this.element.is(":hidden")) {
            return true;
        }

        var valid = true;

        if (!this.validateName()) {
            valid = false;
        }

        if (!this.validateAddressLine1()) {
            valid = false;
        }

        if (!this.validateAddressLine2()) {
            valid = false;
        }

        if (!this.validatePostalCode()) {
            valid = false;
        }

        if (!this.validateCity()) {
            valid = false;
        }

        if (!this.validateCountry()) {
            valid = false;
        }

        return valid;
    },
    validateName: function () {
        if (this.options.inputVisibleRequired.name != "visibleMandatory") {
            return true;
        }

        var valid = (this.nameInput$.val().trim().length);

        if (valid) {
            this.nameRow$.showWarning(null);
        } else {
            this.nameRow$.showWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        }
        return valid;
    },
    validateAddressLine1: function () {
        if (this.options.inputVisibleRequired.addressLine1 != "visibleMandatory") {
            return true;
        }

        var valid = (this.addressLine1Input$.val().trim().length);
        if (valid) {
            this.addressLine1Row$.showWarning(null);
        } else {
            this.addressLine1Row$.showWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        }
        return valid;
    },
    validateAddressLine2: function () {
        if (this.options.inputVisibleRequired.addressLine2 != "visibleMandatory") {
            return true;
        }

        var valid = (this.addressLine2Input$.val().trim().length);
        if (valid) {
            this.addressLine2Row$.showWarning(null);
        } else {
            this.addressLine2Row$.showWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        }
        return valid;
    },
    validatePostalCode: function () {
        if (this.options.inputVisibleRequired.postalCode != "visibleMandatory") {
            return true;
        }

        var valid = (this.postalCodeInput$.val().trim().length);
        if (valid) {
            this.postalCodeRow$.showWarning(null);
        } else {
            this.postalCodeRow$.showWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        }
        return valid;
    },
    validateCity: function () {
        if (this.options.inputVisibleRequired.city !== "visibleMandatory") {
            return true;
        }

        var valid = (this.cityInput$.val().trim().length);
        if (valid) {
            this.cityRow$.showWarning(null);
        } else {
            this.cityRow$.showWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        }
        return valid;
    },
    validateCountry: function () {
        if (this.options.inputVisibleRequired.country !== "visibleMandatory") {
            return true;
        }

        if (!this.countrySelect$) {
            return true;
        }

        var valid = (this.countrySelect$.val() !== "");

        if (valid) {
            this.countryRow$.showWarning(null);
        } else {
            this.countryRow$.showWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        }
        return valid;
    },
    setNameRowWarning: function (value) {
        this.nameRow$.showWarning(value);
    }
});

$.fn.paramrow = function (label, targetControl, inMergeMode, isRequired) {

    this.labelCell$ = this.appendTd({
        "text": label,
        "class": "label"
    });

    if (isRequired) {
        this.labelCell$.append($("<span />", {
            text: "*",
            class: "required"
        }));
    }

    if (inMergeMode) {
        this.sourceCell$ = this.appendTd();
    }

    const thirdColumnProps = inMergeMode ? { "class": "third-column" } : {};

    //Add valueCell
    var valueCell$ = this.appendTd(thirdColumnProps);
    if (!inMergeMode) {
        valueCell$.attr("colSpan", 2);
    }

    //Add holder div to value cell
    this.valueDiv$ = valueCell$.appendDiv();
    this.valueDiv$.append(targetControl);

    this.showWarning = function (message) {
        if (message) {
            targetControl.displayWarningMessage(message);
            targetControl.addClass("s1_warningborder");
        } else {
            targetControl.hideWarningMessage()
            targetControl.removeClass("s1_warningborder");
        }
    };

    return this;
};

$.fn.divrow = function (label, targetControl, isRequired) {
    this.addClass('responsive-row');

    var $labelDiv = $('<div>', { class: 'label' }).text(label);

    if (isRequired) {
        $labelDiv.append('<span class="required-asterisk">*</span>');
    }

    var $targetControlDiv = $('<div>').append(targetControl);

    this.append($labelDiv).append($targetControlDiv);

    this.showWarning = function (message) {
        if (message) {
            targetControl.displayWarningMessage(message);
            targetControl.addClass("s1_warningborder");
        } else {
            targetControl.hideWarningMessage()
            targetControl.removeClass("s1_warningborder");
        }
    };

    return this;
};


//Helper classes for Address

//Retrieve state from serverReference.countries preloaded JSON collection
Address.FindStateById = function (state) {
    if (!state) {
        return null;
    }

    var stateId = parseInt(state, 10);

    for (var i = 0; i < window.serverReference.countries.length; i++) {
        for (var j = 0; j < window.serverReference.countries[i].states.length; j++) {
            if (window.serverReference.countries[i].states[j].key === stateId) {
                return window.serverReference.countries[i].states[j];
            }
        }
    }

    return null;
};

//Retrieve country from serverReference.countries preloaded JSON collection
Address.FindCountryById = function (country) {

    if (!country) {
        return null;
    }
    var countryId = country;

    for (var i = 0; i < window.serverReference.countries.length; i++) {
        if (window.serverReference.countries[i].key === countryId) {
            return window.serverReference.countries[i];
        }
    }

    return null;
};

Address.GetCountryIdByStateId = function (stateId) {

    if (!stateId) {
        return null;
    }

    var state = Address.FindStateById(stateId);
    return state.Country.key;
};

//Get collection for form element
Address.GetStateOptionsByCountry = function (country) {

    var a = [];
    if (!country) {
        return a;
    }

    for (var i = 0; i < country.states.length; i++) {
        var obj = {};
        obj.key = country.states[i].key;
        obj.value = (country.states[i].isCountryDefault) ? "" : country.states[i].name;
        a.push(obj);
    }

    return a;
};

Address.GetStateOptionsByCountryId = function (countryId) {

    var a = [];
    if (!countryId) {
        return a;
    }

    var country = Address.FindCountryById(countryId);
    return Address.GetStateOptionsByCountry(country);
};