declare const $: any;

$.fn.isEmail = function (settings) {
  const config = {
    required: false
  };

  if (settings) {
    $.extend(config, settings);
  }

  const this$ = this;

  this.on("keyup",
    function () {
      const value = this.value.trim();

      if (isValid(value)) {
        this$.removeClass("s1_warningborder");
      }
    });

  function isValid(value: string) {
    if (!config.required && value.length === 0) {
      return true;
    }

    const regExPattern = /^[a-zA-Z0-9_\.\-]+\@([a-zA-Z0-9\-]+\.)+[a-zA-Z0-9]{2,12}$/;
    return regExPattern.test(value);
  }

  this.validate = () => {
    const value = this$.val().trim();
    const valid = isValid(value);

    this$.toggleClass("s1_warningborder", !valid);
    return valid;
  };

  return this;
};

export {};