import moment from "moment";
import { routes } from "utilities/routing/generatedRoutes";
declare const coordinator: any;

/* eslint-disable */
coordinator.newappointment = (function () {

    var dialog$;

    var config = {
        date: null,
        artist: undefined,
        fromItem: null,
    };

    var isActive = true;

    var canOnlyAddShow = function () {
        return site.permissions.show.canCreate &&
            !site.permissions.flight.canCreate &&
            !site.permissions.train.canCreate &&
            !site.permissions.groundTransport.canCreate &&
            !site.permissions.externalCalendar.canCreate;
    }

    var openChooseTypeDialog = function () {
        if (!isActive) return;

        if (!dialog$) {
            dialog$ = $("<div />");

            dialog$.newappointmentdialog({
                width: 300,
                showTitle: false,
                closeOutside: true,
                autoHeight: true,
            });
        }

        dialog$.newappointmentdialog("open", config);
    }

    var newShow = function () {

        var companyId = null;

        if (config.artist) {
            companyId = config.artist.artistId;
        }

        var defaultDate = (config.date) ? moment(config.date).toISOString() : null;

        var params = {
            "date": defaultDate,
            "artistId": companyId,
            "returnPage": "detail"
        };

        const newShowRoute = routes["show_New"]().absolute;

        document.location.href = window.urlTools.addParameters(newShowRoute, params);
    }

    var newFlight = function () {
        var date = (config.date) ? new Date(config.date) : null;

        SystemOneLibrary.exports.newFlight(date, config.artist, config.fromItem);

        dialog$.newappointmentdialog("close", config);
    }

    var newTrain = function () {
        var date = (config.date) ? new Date(config.date) : null;
        SystemOneLibrary.exports.openModalAndCreateTrainJourney(date, config.artist, config.fromItem);
        dialog$.newappointmentdialog("close", config);
    }

    var newGroundTransport = function () {
        var date = (config.date) ? new Date(config.date) : null;
        coordinator.ground.init({ fromItem: config?.fromItem });
        coordinator.ground.newGroundTransport(date, config.artist, config.fromItem);
    }

    var newAccommodation = function () {
        SystemOneLibrary.exports.openAccommodationModal();
        dialog$.newappointmentdialog("close", config);
    }

    var newExternalCalendar = function () {
        document.location.href = routes.externalCalendar_New().absolute;
    }

    var startChoose = function (options) {

        $.extend(config, options);

        if (canOnlyAddShow()) {
            newShow(); //Users that can only add shows shouldn't be bothered with the optionMenu
        } else {
            openChooseTypeDialog();
        }
    }

    var init = function (options) {

        config = $.extend(config, options);

        isActive = site.permissions.show.canCreate ||
            site.permissions.flight.canCreate ||
            site.permissions.groundTransport.canCreate ||
            site.permissions.externalCalendar.canCreate ||
            site.permissions.lodging.canCreate;

        if (isActive) {
            $("#newappointmentbutton")
                .on("click", function () {
                    startChoose({});
                });
        } else {
            $("#newappointmentbutton").hide();
        }
    };

    var setDate = function (isoDateStr) {
        config.date = isoDateStr;
    };

    var setFromItem = function (item) {
        config.fromItem = item;
    };

    var setArtist = function (artist) {
        config.artist = artist; //should be artist according to definition ArtistSmallDto
    }

    return {
        init: init,
        startChoose: startChoose,
        newShow: newShow,
        newFlight: newFlight,
        newTrain: newTrain,
        newGroundTransport: newGroundTransport,
        newExternalCalendar: newExternalCalendar,
        newAccommodation: newAccommodation,
        setArtist: setArtist,
        setDate: setDate,
        setFromItem: setFromItem,
    };
}());