import React from "react";

export const newLineToBrSpans = (obj: string): JSX.Element[] => {
  if (obj === null) {
    return [];
  }

  return obj.split("\n").map((str, key) => <span key={key} >
    {str}
    <br />
  </span>);
};

export const newLineToBr = (input: string) => {
  let i = -1;

  if (input === null) {
    return [<></>];
  }
  return input.split("\n")
    .map<React.ReactNode>((line, idx) => <React.Fragment key={idx + "#_[]_#" + line}>{line}</React.Fragment>)
    .reduce((prev, curr) => [prev, <br key={i--} />, curr]);
};
