$.fn.appendPublicationToWebIcon = function (value) {

    function getTitle() {
        return value
            ? RESX.Planning.PublicationToWeb
            : RESX.Planning.NoPublicationToWeb;
    }

    var holder$ = this.appendDiv({
        "class": "s1_compositedicon tooltiptarget",
        "title": getTitle()
    });

    holder$.appendSpan({
        "class": "icon-globe"
    });

    if (!value) {
        holder$.addClass("s1_inactive");

        holder$.appendSpan({
            "class": "icon-forbidden layered"
        });
    }

    return this;
};