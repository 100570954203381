const urlTools = {
    addParameters: function (url, params) {
        function stripNull(obj) {
            for (const i in obj) {
                if (obj[i] === null) {
                    delete obj[i];
                }
            }
            return obj;
        }

        params = stripNull(params);

        if (jQuery.isEmptyObject(params)) {
            return url;
        }

        if (url.indexOf("?") === -1) {
            url = url + "?";
        }

        // Check if the provided URL has already assigned parameters
        if (url.indexOf("=") > -1) {
            url = url + "&";
        }

        return url + $.param(params);
    },

    addParametersShallow: function (url, params) {
        function stripNull(obj) {
            for (const i in obj) {
                if (obj[i] === null) {
                    delete obj[i];
                }
            }
            return obj;
        }

        params = stripNull(params);

        if (jQuery.isEmptyObject(params)) {
            return url;
        }

        if (url.indexOf("?") === -1) {
            url = url + "?";
        }

        // Check if the provided URL has already assigned parameters
        if (url.indexOf("=") > -1) {
            url = url + "&";
        }

        return url + $.param(params, true);
    },

    replaceQueryString: function (url, params) {
        if (url.indexOf("?") !== -1) {
            url = url.substring(0, url.indexOf("?"));
        }

        let newUrl = this.addParametersShallow(url, params);
        newUrl = window.soneTools.stripLastCharacter(newUrl, "?");

        return newUrl;
    },

    replaceRouteKey: function (url, value) {
        if (!value) {
            value = "";
        }

        // replace everything after the last backslash
        url = url.replace(/[^\/]+$/, value);
        return url;
    },

    removeRouteKey: function (url) {
        const lastSlashIndex = url.lastIndexOf("/");
        if (lastSlashIndex > url.indexOf("/") + 1) { // if not in http://
            if ($.isNumeric(url.substr(lastSlashIndex + 1))) {
                return url.substr(0, lastSlashIndex); // cut it off
            }
            return url;
        } else {
            return url;
        }
    }
};

export { urlTools };

(window as any).urlTools = urlTools;
