import classNames from "classnames";
import React from "react";
import CardRowSegmentHeader from "./CardRowSegmentHeader";

interface Props {
  className?: string
  type?: "boxed" | "lined"
  lineColor?: "darkgray-bg" | "gray-bg" | "green-bg" | "red-bg" | string
}

type CardRowSegmentType = React.FC<React.PropsWithChildren<Props>>;

interface CardRowSegmentComponent extends CardRowSegmentType {
  Header: typeof CardRowSegmentHeader
}

const CardRowSegment: CardRowSegmentComponent = (props: React.PropsWithChildren<Props>) => {
  const { lineColor } = props;

  const isHex = lineColor != null && lineColor.startsWith("#");

  const lineClasses = classNames("padding-mini margin-right-small",
    {
      "gray-bg": lineColor === "gray-bg" || lineColor == null,
      "darkgray-bg": lineColor === "darkgray-bg",
      "green-bg": lineColor === "green-bg",
      "red-bg": lineColor === "red-bg"
    }
  );

  const lineStyle = (isHex) ? { backgroundColor: lineColor } : null;

  if (props.type === "boxed") {
    return <div className={props.className}>
      <div className="card-floating-bg  padding-large">
        {props.children}
      </div>
    </div>;
  }

  return <div className={props.className}>
    <div className="flex">
      <div className={lineClasses} style={lineStyle}></div>
      <div className="padding grow1">
        {props.children}
      </div>
    </div>
  </div >;
};

CardRowSegment.Header = CardRowSegmentHeader;

export default CardRowSegment;