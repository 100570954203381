declare const $: any;

$.fn.requireValue = function () {
  const this$ = this;

  this.on("keyup",
    () => {
      const value = this$.val().trim();
      this$.toggleClass("s1_warningborder", value.length === 0);
    });

  return this;
};

export {};