$.fn.attendees = function (settings) {
    var div$;

    var config = {
        values: []
    };

    if (settings) {
        $.extend(config, settings);
    }

    var this$;
    return this.each(function () {
        this$ = $(this);

        div$ = this$.appendDiv({});

     //   console.log("attendees.js", config.values);

        var values = config.values.sort(function(a, b) {
            var textA = a.name.fullName.toUpperCase();
            var textB = b.name.fullName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        $.each(values,
            function (index, value) {

                if (index > 0) {
                    div$.append(", ");
                }

                div$.appendSpan({
                    "text": value.name.fullName
                });
            });
    });
};