coordinator.ground = (function () {

    var config = {
        phases: [],
        labels: [],
        fromItem: null,
    };

    //Set data in the groundTransportpanel in the detail page
    var setData = function (value) {
        var detailPageSettings = $.extend({}, config);

        var container = $("#groundTransportpanel").find(".content");
        container.empty();

        var details$ = $("<div />").groundpanel_detail(detailPageSettings, value);
        container.append(details$);
    }

    var reload = function () {
        location.reload();
    };

    var modifyDialog$ = null;
    var initDialog = function (groundTransport, activeTab) {
        if (modifyDialog$) {
            openDialog(groundTransport, activeTab);
        } else {
            modifyDialog$ = $("<div />").grounddialog(config);
            openDialog(groundTransport, activeTab);
        }
    }

    var openDialog = function (groundTransport, activeTab) {
        modifyDialog$.grounddialog("open", groundTransport, activeTab).done(
            function (result) {
                reload();
            });
    }

    // TODO: This is currently used in the preview panel and ground transport detail page
    var editDialog = function (groundTransportId) {
        window.CoreApi.GroundTransport.GetGroundTransport(groundTransportId).then(function (response) {
            initDialog(response.data, 1);
        });
    };

    var init = function (options) {
        $.extend(config, options);
    };

    var newGroundTransport = function (defaultDate, artist) {

        var dateNow = (defaultDate) ? defaultDate : new Date();

        //initialize groundTransport
        var groundTransport = {
            routeHexColor: "#FF0A81",
            departArriveSelect: 0,
            duration: null,
            durationEstimate: null,
            distance: null,
            phase: {
                value: "confirmed"
            },
            artists: [artist],
            travellers: [],
            start: {
                dateTimeLocal: dateNow,
                locationType: {
                    value: "unknown"
                },
                placeAddress: null,
                placeGeo: null,
                placeId: null,
                placeName: null
            },
            end: {
                dateTimeLocal: dateNow,
                locationType: {
                    value: "unknown"
                },
                placeAddress: null,
                placeGeo: null,
                placeId: null,
                placeName: null
            },
            notes: null
        };

        initDialog(groundTransport, 0);
    }

    return {
        init: init,
        reload: reload,
        newGroundTransport: newGroundTransport,
        editDialog: editDialog,
        setData: setData
    };
}());