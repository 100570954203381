$.widget("sone.vatcheckrefresh", {
    options: {
        companyId: 0,
        displayButton: true,
        initialThis: null
    },
    _init: function () {
        this.options.initialThis = this;
        this.isClicked = false;

        this.$label = this.element.find("SPAN");
        if (!this.$label.length) {
            throw "could not find label";
        }

        this.$dot = this.element.find(".icon-circle");
        if (!this.$dot.length) {
            throw "could not find dot";
        }

        this.$sublabel = this.element.find(".s1_small");
        if (!this.$sublabel.length) {
            throw "could not find sublabel";
        }

        if (this.options.displayButton) {
            this._addButton();
        }
    },
    _addButton: function () {
        var that = this;

        this.buttonDiv$ = $("<div />", {
            "click": function () {
                if (!that.isClicked) {
                    that._check();
                }
                that.isClicked = true;
            }
        });

        var button$ = $("<img />", {
            "class": "mysprite3 button_refresh",
            "src": "/images/empty.png"
        });
        this.buttonDiv$.append(button$);

        this.element.after(this.buttonDiv$);
    },
    _check: function () {
        var that = this;

        this.buttonDiv$.find("img").animateRotate(20000, 70000);

        window.CoreApi.VatNumber.RecheckCompanyVatNumber(this.options.companyId)
            .then(function (response) {

                var data = response.data;

                that.element.fadeOut({
                    complete: function () {
                        that.$label.text(data.number + " (" + data.validationOutcomeText + ")");
                        that.$dot.css("color", data.validationHexColor);
                        that.$sublabel.text(data.lastCheckFormatted);
                    }
                })
                    .fadeIn();
                that.buttonDiv$.fadeOut();
            })
            .catch((err) => {
                console.error(err);
                this.buttonDiv$.remove();
                this.isClicked = false;
                this._addButton.call(this.options.initialThis);
            });
    }
});