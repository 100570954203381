$.fn.googlePlaceAutocompleteList = function (settings) {

    var config = {
        poweredBy: "Powered by Google Places",
        noMatchFound: "No match found on Google Places",
        label: "Google Places",
        beaconArticle: "5900a0552c7d3a057f8894b0",
        choose: function (obj) {
            Api.GooglePlaces.GetPlace(obj.placeId, "unknown").then(res => res.data)
        },
        search: function (name) {
            return Api.GooglePlaces.TextSearch(name, "Unknown", 0).then(res => res.data);
        },
        displayPoweredBy: false,
        format: function (item) {
            var div = $("<div />", {
                "text": item.name
            });

            div.appendDiv({
                "text": item.address,
                "class": "s1_small s1_gray"
            });

            return div;
        }
    }

    if (settings) {
        $.extend(config, settings);
    }

    //Add holder for results
    var resultsDiv$ = $("<div />", {
        "class": "flex"
    });
    this.append(resultsDiv$);

    function emptyResults() {
        resultsDiv$.empty();
    }

    var textSearchAjax;

    function stopTextSearch() {
        clearTimeout(searchTimeout);
    }

    function callTextSearchCompany(name) {

        textSearchAjax = config.search(name).then(
            function (response) {
                renderOptions(response, 5);
            });
    }

    function renderOptions(items, top) {
        emptyResults();

        var list$ = resultsDiv$.appendDiv({
            "class": "grow1"
        });

        $.each(items,
            function (index, item) {
                if (index >= top) {
                    return;
                }

                var item$ = list$.appendDiv({
                    "class": "panel-bg-color padding-large hoverable",
                    "style": "margin-top:3px",
                    "click": function () {
                        stopTextSearch();
                        emptyResults();
                        config.choose(item);
                    }
                });

                item$.append(config.format(item));
            });

        if (items.length === 0) {
            list$.appendDiv({
                "class": "blue-bg white-text s1_small",
                "style": "padding:4px 8px;margin-top:3px;text-align:center;"
            }).append("<a data-beacon-article-sidebar=\"" +
                config.beaconArticle +
                "\">" +
                config.noMatchFound +
                "</a>");
        } else if (items.length > top) {
            list$.appendDiv({
                "class": "blue-bg white-text s1_small hoverable",
                "style": "padding:4px 8px;margin-top:3px;text-align:center;",
                "click": function () {
                    renderOptions(items, 999);
                }
            }).append("<i class=\"icon-double-down\"></i>");
        } else if (config.displayPoweredBy) {
            list$.appendDiv({
                "class": "blue-bg white-text s1_small",
                "style": "padding:4px 8px;margin-top:3px;text-align:center;"
            }).append("<a data-beacon-article=\"" +
                config.beaconArticle +
                "\">" +
                config.poweredBy +
                "</a>");
        }

        //Add close button
        resultsDiv$.appendDiv({
            "class": "padding-large"
        }).appendA({
            "class": "icon-x red",
            "click": function () {
                emptyResults();
                stopTextSearch();
            }
        });
    }

    var searchTimeout;

    this.empty = function () {
        stopTextSearch();
        resultsDiv$.empty();
    }

    this.options = function (settings) {
        stopTextSearch();
        resultsDiv$.empty();

        if (settings) {
            $.extend(config, settings);
        }
    }

    this.search = function (value) {
        var name = value.trim();

        stopTextSearch();

        if (name.length < 2) {
            emptyResults();
            return;
        }

        searchTimeout = setTimeout(function () {
            callTextSearchCompany(name);
        },
            500);
    }

    return this;
};