$.widget("sone.addressfilter", {
    options: {
        disabled: false
    },
    _create: function () {
        this.filterType = this.options.type;

        //Find value cell in row
        var valueCell$ = this.element.children().eq(1);

        //Add select box
        this._appendFilterSelectType(valueCell$);

        this.warningHolderDiv$ = $('<div />');
        valueCell$.append(this.warningHolderDiv$);

        //Add region filter
        this.regionDiv$ = $('<div />');
        this.warningHolderDiv$.append(this.regionDiv$);
        this._appendFilterRegion(this.regionDiv$);

        //Add country filter
        this.countryDiv$ = $('<div />', { "id": "country" });
        this.warningHolderDiv$.append(this.countryDiv$);
        this._appendFilterCountry(this.countryDiv$);

        //Add state filter
        this.stateDiv$ = $('<div />');
        this.warningHolderDiv$.append(this.stateDiv$);
        this._appendFilterState(this.stateDiv$);

        //Add city filter
        this.cityDiv$ = $('<div />');
        this.warningHolderDiv$.append(this.cityDiv$);
        this._appendFilterCity(this.cityDiv$);

        //Add postal code filter
        this.postalCodeDiv$ = $('<div />');
        this.warningHolderDiv$.append(this.postalCodeDiv$);
        this._appendFilterPostalCode(this.postalCodeDiv$);

        //Warning
        this.warningDiv$ = $('<div />', { "class": "s1_warning" });
        valueCell$.append(this.warningDiv$);
        this.warningDiv$.hide();
    },
    _init: function () {
        this.filterType = this.options.type;
        this.setVisiblity(this.filterType);

        if (this.filterType !== "state") {
            this.overrideStateIdsByCountry(window.site.userPreference.regional.country);
        }

        if (this.options.countryIds.length > 0) {
            //Render all the checkboxes for the state
            this.fillStateCheckboxHolder(this.options.countryIds[0]);
        }
    },
    setVisiblity: function (value) {

        this.filterType = value;

        if (this.filterType === "region") {
            this.regionDiv$.show();
            this.regionDiv$.find("input[type=checkbox]").eq(0).focus();
        } else {
            this.regionDiv$.hide();
        }

        if (this.filterType === "country") {
            this.countryDiv$.show();
            this.countryDiv$.find(".ac_input").eq(0).focus();
        } else {
            this.countryDiv$.hide();
        }

        if (this.filterType === "state") {
            this.stateDiv$.show();
            this.stateDiv$.find("select").eq(0).focus();
        } else {
            this.stateDiv$.hide();
        }

        if (this.filterType === "city") {
            this.cityDiv$.show();
            this.cityDiv$.find("input[type=text]").eq(0).focus();
        } else {
            this.cityDiv$.hide();
        }

        if (this.filterType === "postalCode") {
            this.postalCodeDiv$.show();
            this.postalCodeDiv$.find("input[type=text]").eq(0).focus();
        } else {
            this.postalCodeDiv$.hide();
        }

    },
    _appendFilterSelectType: function (valueCell$) {

        var that = this;

        var select$ = $('<select />', {
            name: 'filterType',
        });
        select$.change(function () { that.setVisiblity(this.value); });
        valueCell$.append(select$);

        select$.css('width', '100%');

        //Add no filter option
        select$.append($("<option></option>"));

        //add other options
        $.each(window.serverReference.addressFilterTypes, function (key, obj) {
            select$
                .append($("<option />")
                    .attr("value", obj.key)
                    .text(obj.value));
        });

        //select options
        if (this.filterType !== null) {
            select$.val(this.filterType);
        }
    },
    _appendFilterRegion: function (div$) {

        var that = this;

        div$.css("margin", "3px 2px");

        //add other options
        $.each(window.serverReference.regions, function (key, obj) {

            var id = 'region_' + obj.value;
            var checked = that._regionIsChecked(obj.value);

            var checkboxDiv$ = $('<div />', {});
            div$.append(checkboxDiv$);

            checkboxDiv$
                .append($("<input />")
                    .attr("type", "checkbox")
                    .attr("id", id)
                    .attr("name", "region")
                    .attr("value", obj.value)
                    .prop('checked', checked)
                );

            checkboxDiv$
                .append($("<label />")
                    .attr("for", id)
                    .text(obj.text));
        });
    },
    _appendFilterCountry: function (div$) {
        div$.css("width", "300px");
        //div$.css("margin", "3px 2px");

        var countryIds = this.options.countryIds;

        var selectedCountries = window.serverReference.countries.filter(country => countryIds.indexOf(country.key) > -1);

        window.countryAutocompleteApi = (keyword, excludeKeys) => window.CoreApi.Country.Autocomplete({
            keyword,
            excludeKeys,
            page: 1,
            perPage: 20
        })
            .then(res => res.data.data);

        div$.itemchooser({
            authorizedToModify: false,
            data: selectedCountries,
            max: 25,
            min: 1,
            url: {},
            autocompleteApi: 'window.countryAutocompleteApi',
            autocompleteMap: function (country) {
                return {
                    key: country.key,
                    value: country.name,
                    sublabel: country.region
                }
            }
        });
    },
    _appendFilterState: function (div$) {
        this._appendCountrySelect(div$);

        //Add holderdiv for checkboxes
        var holderDiv$ = $('<div />', {});
        holderDiv$.css("margin", "3px 2px");
        holderDiv$.css("display", "table");
        div$.append(holderDiv$);
    },
    _appendCountrySelect: function (div$) {
        var that = this;

        var holderDiv$ = $('<div />', {});
        //holderDiv$.css("margin", "3px 4px");
        div$.append(holderDiv$);

        var select$ = $('<select />', {});
        select$.change(function () {
            that.overrideStateIdsByCountry(this.value);
            that.fillStateCheckboxHolder(this.value);
            that.stateDiv$.find("input[type=checkbox]")[0].focus();
        });
        holderDiv$.append(select$);

        select$.css('width', '100%');

        //add options
        $.each(window.serverReference.countries, function (key, obj) {
            select$
                .append($("<option />")
                    .attr("value", obj.key)
                    .text(obj.name));
        });

        //Set value
        select$.val(this.options.countryIds[0]);
    },
    fillStateCheckboxHolder: function (countryId) {

        var that = this;

        //Get second div
        var div$ = this.stateDiv$.children().eq(1);

        //Clear any previously selected checkboxes
        div$.empty();

        var states = this._getStatesByCountry(countryId);
        var itemsPerColumn = Math.ceil(states.length / 3);
        var columns$ = [$('<div />'), $('<div />'), $('<div />')];

        $.each(columns$, function (key, obj) {
            div$.append(obj);
            obj.css("display", "table-cell");
            obj.css("width", "250px");
        });

        //add checkboxes
        $.each(states, function (key, obj) {

            var id = 'state_' + obj.key;
            var index = Math.floor(key / itemsPerColumn);
            var checked = that._stateIsChecked(obj.key);

            var checkboxDiv$ = $('<div />', {});
            columns$[index].append(checkboxDiv$);

            checkboxDiv$
                .append($("<input />")
                    .attr("type", "checkbox")
                    .attr("id", id)
                    .attr("name", "state")
                    .attr("value", obj.key)
                    .prop('checked', checked)
                );

            if (!obj.name || obj.name.length === 0) {
                checkboxDiv$
                    .append($("<label />")
                        .attr("for", id)
                        .text(RESX.GeneralLabels.NotSpecified)
                    );

                checkboxDiv$.css("font-style", "italic");
            } else {
                checkboxDiv$
                    .append($("<label />")
                        .attr("for", id)
                        .text(obj.name));
            }
        });
    },
    _getStatesByCountry: function (countryId) {
        for (var i = 0; i < window.serverReference.countries.length; i++) {
            if (serverReference.countries[i].key === countryId) {
                return window.serverReference.countries[i].states.slice(0);
            }
        }

        return null;
    },
    _appendFilterCity: function (div$) {
        var input$ = $('<input />', {
            "type": "text",
            "name": "city",
            "placeholder": RESX.Address.City
        });
        input$.css("width", "100%");
        div$.append(input$);

        input$.val(this.options.city);
    },
    _appendFilterPostalCode: function (div$) {
        var input$ = $('<input />', {
            "type": "text",
            "name": "postalCode",
            "placeholder": RESX.Address.PostalCode
        });
        input$.css("width", "100%");
        div$.append(input$);

        input$.val(this.options.postalCode);
    },
    _stateIsChecked: function (stateId) {
        for (var i = 0; i < this.options.stateIds.length; i++) {
            if (this.options.stateIds[i] === stateId) {
                return true;
            }
        }
        return false;
    },
    _regionIsChecked: function (regionId) {
        for (var i = 0; i < this.options.regionIds.length; i++) {
            if (this.options.regionIds[i] === regionId) {
                return true;
            }
        }
        return false;
    },
    overrideStateIdsByCountry: function (countryId) {
        var states = this._getStatesByCountry(countryId);

        this.options.stateIds.splice(0);

        //Highlight only unspecified country;
        for (var i = 0; i < states.length; i++) {
            if (!states[i].isCountryDefault) {
                continue;
            }
            this.options.stateIds.push(states[i].key);
        }
    },
    validate: function () {
        switch (this.filterType) {
            case "region":
                return this.validateRegion();
            case "country":
                return this.validateCountry();
            case "state":
                return this.validateState();
            case "city":
                return this.validateCity();
            case "postalCode":
                return this.validatePostalcode();
            default:
                return true;
        }
    },
    validateRegion: function () {
        if ($("input[type=checkbox][name='region']").is(":checked")) {
            return true;
        }
        this.showWarning(RESX.GeneralLabels.AtLeast1ItemShouldBeSelected);
        return false;
    },
    validateCountry: function () {
        if ($('#country').itemchooser('getKeys').length > 0) {
            return true;
        }
        this.showWarning(RESX.GeneralLabels.AtLeast1ItemShouldBeSelected);
        return false;
    },
    validateState: function () {
        if ($("input[type=checkbox][name='state']").is(":checked")) {
            return true;
        }
        this.showWarning(RESX.GeneralLabels.AtLeast1ItemShouldBeSelected);
        return false;
    },
    validateCity: function () {
        if ($("input[name='city']").val().length > 0) {
            return true;
        }
        this.showWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        return false;
    },
    validatePostalcode: function () {
        if ($("input[name='postalCode']").val().length > 0) {
            return true;
        }
        this.showWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        return false;
    },
    showWarning: function (message) {
        this.warningDiv$.empty();
        this.warningDiv$.append(message);
        this.warningDiv$.show();
        this.warningHolderDiv$.addClass('s1_warningborder');

        var that = this;
        clearTimeout(this.warningTimeout);
        this.warningTimeout = setTimeout(function () {
            that.hideWarning();
        }, 2000);

    },
    hideWarning: function () {
        this.warningHolderDiv$.removeClass('s1_warningborder');
        this.warningDiv$.empty();
        this.warningDiv$.hide();
    }
});

