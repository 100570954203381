import React from "react";
import { Modal } from "../components";

const HOURS_24 = 24 * 60 * 60 * 1000;
const TimeoutModal = () => {
  const pageOpenedTime = React.useRef(new Date());

  const [hasTimedOut] = React.useState(false);

  React.useEffect(() => {
    const onVisibilityChanged = () => {
      // console.log(e)
      if (document.visibilityState === "visible" && !hasTimedOut) {
        const now = new Date();
        const elapsed = now.getTime() - pageOpenedTime.current.getTime();
        // if 24 hours have passed, set the timeout
        if (elapsed > HOURS_24) {
          // setHasTimedOut(true);
          // console.log("RELOAD")
        }
      }
    };
    // const visibilityChange = document.addEventListener("visibilitychange", onVisibilityChanged);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChanged);
    };
  }, []);

  return <Modal
    title="Session Timeout"
    isOpen={hasTimedOut}
    handleClose={() => { window.location.reload(); }}
  >
    This page has timed out. Reload the page by closing this window.
  </Modal>;
};

export default TimeoutModal;