var moment = require("moment");
require("./jquery.timepicker");

UI.FormElement.DateTimeControl = function(options) {
    var settings, internalMoment, that = this;

    // Set the defaults and apply supplied options
    var defaults = {
        Name: "dateTimeControl",
        Is24Hr: true,
        IsOn: true,
        SwapDateTime: false,
        DisplayDateInput: true,
        DisplayTimeInput: true,
        DisplayIsOnInput: true,
        Value: null,
        setConfirmUnload: true        
    };
    
    if (typeof options === "string" || options instanceof String) {
        defaults.Name = options;
    }

    settings = $.extend({}, defaults);
    
    if (options) {
        settings = $.extend(settings, options);
    }    

    $.extend(this, settings);

    this.ValueMinimum = new Date(2001, 1, 1);
    this.ValueMaximum = new Date(2030, 1, 1);

    //Is On checkbox
    this.IsOnInput = null;

    //Date input
    this.DateInput = null;

    //Time input
    this.TimeInput = null;

    this.OnChangeFunctions = [];
    this.onChangeCallback = settings.onChangeCallback;

    this.HolderRow1 = null;
    this.HolderRow2 = null;
    this.initHolder();
    this.datePickerControlsDiv = null;

    this.epochId = this.Name + "Epoch";

    // private functions
    var updateHiddenEpoch = function() {
        $("#" + that.epochId).val(that.getUnixEpoch());
    };

    var updateTimePicker = function(hour, minute) {
        var d = new Date();
        d.setUTCHours(hour);
        d.setUTCMinutes(minute);
        $(that.TimeInput).timepicker("setTime", d);
    };

    var getDateForDatePicker = function() {
        var tempDt = that.getDateTime();
        var dtYear = tempDt.getUTCFullYear();
        var dtMonth = tempDt.getUTCMonth();
        var dtDay = tempDt.getUTCDate();
        return new Date(dtYear, dtMonth, dtDay);
    };

    // public functions

    // NOTE! Although setInternalValue is public it is not designed for external usage, take care!
    // rather use setValue
    this.setInternalValue = function(value) {
        internalMoment = moment.utc(value);
        updateHiddenEpoch();
    };

    this.setValue = function(unixEpoch) {
        this.setInternalValue(unixEpoch);
        that.updateDatePicker();
        updateTimePicker(internalMoment.hours(), internalMoment.minutes());
    };

    this.getDateTime = function() {
        var m = internalMoment.clone();
        return m.toDate();
    };

    this.setInternalValueFromLocal = function(date) {
        // The date here is a local date - we need to offset it
        var m = moment.utc(date);
        internalMoment = m.subtract("minutes", date.getTimezoneOffset());
        updateHiddenEpoch();
    };

    this.getUnixEpoch = function() {
        return internalMoment.unix() * 1000;
    };

    this.getISOString = function() {
        return internalMoment.format();
    };
    this.addMinutes = function(i) {
        internalMoment.add(i, "minutes");
        updateHiddenEpoch();
        that.updateDatePicker();
        updateTimePicker(internalMoment.get("hours"), internalMoment.get("minutes"));
    };

    this.addHours = function(i) {
        internalMoment.add(i, "hours");
        updateHiddenEpoch();
        that.updateDatePicker();
        updateTimePicker(internalMoment.get("hours"), internalMoment.get("minutes"));
    };

    this.addDays = function(i) {
        internalMoment.add(i, "days");
        updateHiddenEpoch();
        that.updateDatePicker();
    };

    this.addMonths = function(i) {
        internalMoment.add(i, "months");
        updateHiddenEpoch();
        that.updateDatePicker();
    };

    this.addYears = function(i) {
        internalMoment.add(i, "years");
        updateHiddenEpoch();
        that.updateDatePicker();
    };

    this.getMonth = function() {
        return internalMoment.month();
    };

    this.setMonth = function(month) {
        internalMoment.month(month);
        that.updateDatePicker();
        updateHiddenEpoch();
    };

    this.getDate = function() {
        return internalMoment.date();
    };

    this.setDate = function(date) {
        internalMoment.date(date);
        that.updateDatePicker();
        updateHiddenEpoch();
    };

    this.getWeek = function() {
        //return internalMoment.week();
        return internalMoment.isoWeek();
    };

    this.setWeek = function(week, year) {
        if (year) {
            internalMoment.isoWeek(week).isoWeekYear(year).day("Monday");
        }
        internalMoment.isoWeek(week).day("Monday");

        that.updateDatePicker();
        updateHiddenEpoch();
    };

    this.getYear = function() {
        return internalMoment.year();
    };

    this.setYear = function(year) {
        internalMoment.year(year);
        that.updateDatePicker();
        updateHiddenEpoch();
    };

    this.setTimeOfDay = function(hour, minute) {
        internalMoment.set("hour", hour);
        internalMoment.set("minute", minute);
        updateTimePicker(hour, minute);
        updateHiddenEpoch();
    };

    this.showTimeInput = function(show) {
        if (show) {
            $(this.TimeInput).show();
        } else {
            $(this.TimeInput).hide();
            // If we are hiding the time input we set the moment to just the date
            internalMoment.startOf("day");
            updateHiddenEpoch();
        }
        this.DisplayTimeInput = show;
    };

    this.hasValue = function() {
        if (internalMoment && internalMoment.isValid()) {
            return true;
        }
        return false;
    };

    this.updateDatePicker = function() {
        $(that.DateInput).datepicker("setDate", getDateForDatePicker());
    };

};

UI.FormElement.DateTimeControl.prototype = new UI.FormElement();

UI.FormElement.DateTimeControl.prototype.InitInputs = function() {
    var epochInput = document.createElement("input");
    epochInput.name = this.Name + "Epoch";
    epochInput.id = this.epochId;
    epochInput.type = "hidden";
    this.holderDiv.appendChild(epochInput);

    this.IsOnInputInit();
    this.setInternalValue(this.Value);
};

UI.FormElement.DateTimeControl.prototype.IsOnInputInit = function() {
    var that = this;

    this.IsOnInput = document.createElement("input");
    this.IsOnInput.name = this.Name + "IsOn";
    this.IsOnInput.id = this.Name + "IsOn";
    this.IsOnInput.type = "checkbox";
    this.IsOnInput.value = "true";
    this.IsOnInput.onclick = function() {
        that.SetIsOn(this.checked);
        that.OnChange();
        if (!this.checked && soneTools.isMobile()) {
            $(that.IsOnInput).focus();
        }
    };
};
UI.FormElement.DateTimeControl.prototype.Render = function() {
    //Clear holder
    var epochDiv, holder;

    epochDiv = $("#" + this.epochId);
    holder = $(this.holderDiv);
    holder.empty();
    holder.append(epochDiv);

    this.HolderRow1 = document.createElement("div");
    this.HolderRow1.style.clear = "both";
    this.holderDiv.appendChild(this.HolderRow1);

    this.HolderRow2 = document.createElement("div");
    this.HolderRow2.style.clear = "both";
    this.holderDiv.appendChild(this.HolderRow2);

    this.holderDiv.appendChild(this.warningDiv);

    this.RenderIsOnInput();

    if (this.SwapDateTime) {
        this.RenderTimeInput();
        this.RenderDateInput();
    } else {
        this.RenderDateInput();
        this.RenderTimeInput();
    }
};

UI.FormElement.DateTimeControl.prototype.RenderIsOnInput = function() {
    if (!this.DisplayIsOnInput) {
        return false;
    }

    var div = document.createElement("div");
    div.style.styleFloat = "left";
    div.style.cssFloat = "left";
    this.HolderRow1.appendChild(div);

    this.IsOnInput.checked = this.IsOn;
    this.IsOnInput.defaultChecked = this.IsOn;
    div.appendChild(this.IsOnInput);
    return true;
};

UI.FormElement.DateTimeControl.prototype.RenderDateInput = function() {
    if (!this.DisplayDateInput) {
        return false;
    }

    var div = document.createElement("div"), that = this;    
    div.style.styleFloat = "left";
    div.style.cssFloat = "left";
    div.style.marginRight = "4px";
    div.style.verticalAlign = "top";
    this.HolderRow1.appendChild(div);

    this.DateInput = document.createElement("input");
    this.DateInput.name = this.Name + "Date";
    this.DateInput.id = this.Name + "Date";
    this.DateInput.type = "text";
    this.DateInput.className = "s1_dateinput";
    this.DateInput.onchange = function () {
        that.OnChange();
    };

    this.datePickerControlsDiv = div;    

    div.appendChild(this.DateInput);    

    $(this.DateInput)
        .datepicker({
            yearRange: this.YearRange(),
            onSelect: function () {
                that.OnChange();
            }          
        });

    this.updateDatePicker();

    if (!this.IsOn) {
        $(this.datePickerControlsDiv).hide();
    }

    return true;
};

UI.FormElement.DateTimeControl.prototype.RenderTimeInput = function() {
    if (!this.IsOn) {
        return false;
    }

    var div, timeFormat, that = this;
    div = document.createElement("div");

    div.style.styleFloat = "left";
    div.style.cssFloat = "left";
    this.HolderRow1.appendChild(div);

    this.TimeInput = document.createElement("input");
    this.TimeInput.id = this.Name + "Time";
    this.TimeInput.name = this.Name + "Time";
    this.TimeInput.className = "s1_timeinput";
    this.TimeInput.type = "text";

    if (this.Is24Hr) {
        $(this.TimeInput).addClass("time24");
        timeFormat = "H:i";
    } else {
        timeFormat = "h:i A";
        $(this.TimeInput).addClass("time");
    }

    div.appendChild(this.TimeInput);

    if (!this.DisplayTimeInput) {
        $(this.TimeInput).hide();
    }

    $(this.TimeInput)
        .timepicker({
            'scrollDefaultNow': true,
            'step': 15,
            'timeFormat': timeFormat
        });

    $(this.TimeInput)
        .timepicker(
            "setTime",
            this.getDateTime()
        );

    $(this.TimeInput)
        .on("change",
            function() {
                that.OnChange();
            });

    return true;
};

UI.FormElement.DateTimeControl.prototype.YearRange = function() {
    return this.ValueMinimum.getUTCFullYear().toString() + ":" + this.ValueMaximum.getUTCFullYear().toString();
};

UI.FormElement.DateTimeControl.prototype.GetValue = function() {
    if (!this.IsOn) {
        return null;
    }

    if (this.DisplayDateInput && this.DisplayTimeInput) {
        return this.Value;
    } else if (this.DisplayDateInput && !this.DisplayTimeInput) {
        return this.Value;
        //return this.Value.utcDatePart();
    } else {
        throw "Not implemented";
    }
};

UI.FormElement.DateTimeControl.prototype.SetIsOn = function(isOn) {
    this.IsOn = isOn;
    this.Render();

    if (isOn) {
        $(this.datePickerControlsDiv).show();
        this.SelectDateInput();
    } else {
        $(this.datePickerControlsDiv).hide();
    }
};

UI.FormElement.DateTimeControl.prototype.getIsOn = function() {
    return this.IsOn;
};

UI.FormElement.DateTimeControl.prototype.OnChange = function() {
    var dt, i, e, tempDt;
    dt = this.getDateTime();

    // On a change (either to the datepicker or the timepicker) we update the epoch
    if (this.DisplayDateInput && this.DateInput) {
        tempDt = $(this.DateInput).datepicker("getDate");
        var dtYear = tempDt.getFullYear();
        var dtMonth = tempDt.getMonth();
        var dtDay = tempDt.getDate();
        dt = new Date(Date.UTC(dtYear, dtMonth, dtDay));
    }

    if (this.DisplayTimeInput && this.TimeInput) {
        dt = $(this.TimeInput).timepicker("getTime", dt);
    }

    // We want to update out internal moment
    this.setInternalValue(dt);

    for (i = 0; i < this.OnChangeFunctions.length; i++) {
        /*jslint evil: true */
        eval(this.OnChangeFunctions[i]);
    }
    
    e = jQuery.Event("change", { unixEpoch: this.getUnixEpoch(), source: this });
    $(this).trigger(e);

    if (this.setConfirmUnload) {
        formObj.setConfirmUnload(true);
    }

    return;
};

UI.FormElement.DateTimeControl.prototype.GetTimeString = function() {
    if (this.TimeInput) {
        return $(this.TimeInput).val();
    } else {
        return null;
    }
};

UI.FormElement.DateTimeControl.prototype.SelectDateInput = function() {
    if (soneTools.isMobile()) {
        this.DateInput.focus();
    }
};

UI.FormElement.DateTimeControl.prototype.SelectTimeInput = function() {
    if (soneTools.isMobile()) {
        this.TimeInput.focus();
    }
};