$.fn.setRadioValue = function (value) {
    $.each(this, function (index, obj) {
        var isChecked = (value.toString() === obj.value);
        if (isChecked) {
            $(obj).prop("checked", true);
        } else {
            $(obj).removeAttr('checked');
        }
    });
};

$.fn.appendSpinnerImage = function (height) {

    var obj$ = $("<div />");

    if (height) {
        obj$.css("height", height + "px");
    }

    obj$.append("<div class=\"ui active centered inline loader\"></div>");

    $(this).append(obj$);
    return obj$;
};

const warningsMap = new WeakMap();

$.fn.displayWarningMessage = function (message) {
    // Update error div if it already exists
    if (warningsMap.has($(this)[0])) {
        warningsMap.get($(this)[0]).text(message);
        return;
    }
    
    // Create error div if it doesn't exist
    const warningDiv = $("<div></div>");
    warningDiv.text(message);
    $(this).parent().append(warningDiv);

    warningsMap.set($(this)[0], warningDiv);
}

$.fn.hideWarningMessage = function () {
    if (warningsMap.has($(this)[0])) {
        warningsMap.get($(this)[0]).remove();
        warningsMap.delete($(this)[0]);
    }
}