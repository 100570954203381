$.widget("sone.dialog2",
    $.ui.dialog,
    {
        options: {
            autoOpen: false,
            resizable: false,
            draggable: false,
            closeOutside: false,
            showTitle: true,
            autoHeight: false,
            contentPadding: 55,
            modal: true,
            labels: [],
            marginTop: 15,
            marginBottom: 100,
            position: { my: 'center', at: 'center center-100' },
            beforeClose: function (event) {
                if (event.currentTarget) {
                    event.currentTarget.focus();
                }

                return true;
            }
        },
        _create: function () {
            var that = this;

            this.maxWidth = this.options.width;
            this.maxHeight = this.options.height;

            this._super();

            this.uiDialog.addClass("s1_panel");

            if (this.options.showTitle) {
                var titlebar$ = this.uiDialog.find(".ui-dialog-titlebar");
                titlebar$.addClass("margin-left-big margin-top-big margin-right-big flex align-center");
                titlebar$.removeClass("ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix");
                titlebar$.empty();

                var leftContainer = titlebar$.appendDiv(
                    {
                        "class": "grow1"
                    });

                this.headerLeft$ = $("<h1 />",
                {
                    "text": this.options.title
                });
                leftContainer.append(this.headerLeft$);

                this.headerRight$ = $("<div />",
                {

                });
                titlebar$.append(this.headerRight$);

                this.headerRight$.appendA({
                    "class": "icon-x",
                    "click": function () {
                        that.close();
                    }
                });
            } else {
                var titleBar = this.uiDialog.find(".ui-dialog-titlebar");
                titleBar.remove();
                this.element.css("margin", "20px");
                this.element.css("height", "");
            }

            this.element.removeClass("ui-dialog-content ui-widget-content");

            this.element.addClass("margin-big overflow-y");

            var originalOpenHandler = this.option("open");

            this.option({
                open: function () {
                    if (typeof originalOpenHandler !== "undefined" && $.isFunction(originalOpenHandler)) {
                        originalOpenHandler();
                    }
                    that._resizeModal();
                    if (that.options.closeOutside) {
                        $(".ui-widget-overlay")
                            .on("click", function () {
                                that.close();
                            });
                    }
                }
            });

            this._resizeElementsOnWindowResize();
        },
        _init: function () {
            var footer$ = this.uiDialog.find(".ui-dialog-buttonpane");
            footer$.removeClass("ui-widget-content");

            this._super();
        },
        // This overrides the jquery ui focus behavior for mobile devices
        _focusTabbable: function () {
            if (!soneTools.isMobile()) {
                this._super();
            }
        },
        _allowInteraction: function (event) {
            if (this._super(event)) {
                return true;
            }

            // address interaction issues with general iframes with the dialog
            if (event.target.ownerDocument != this.document[0]) {
                return true;
            }

            // address interaction issues with dialog window
            if ($(event.target).closest(".cke_dialog").length) {
                return true;
            }

            // address interaction issues with iframe based drop downs in IE
            if ($(event.target).closest(".cke").length) {
                return true;
            }
        },
        setTitle: function (label) {
            if (!this.options.showTitle) {
                return;
            }

            this.headerLeft$.empty();
            this.headerLeft$.append(label);
        },
        getContent: function () {
            return this.element;
        },
        _getWidth: function () {
            var windowWidth = $(window).width();
            var windowWithMargin = windowWidth - 40;
            return (windowWithMargin > this.maxWidth) ? this.maxWidth : windowWithMargin;
        },
        _getHeight: function () {
            var windowHeight = $(window).height();
            var windowWithMargin = windowHeight - this.options.marginTop - this.options.marginBottom;
            return (windowWithMargin > this.maxHeight) ? this.maxHeight : windowWithMargin;
        },
        _resizeElementsOnWindowResize: function () {
            var that = this;

            $(window)
                .resize(function () {
                    if (that.isOpen()) {
                        that._resizeModal();
                    }
                });
        },
        _resizeModal: function () {
            var that = this;

            var width = that._getWidth();
            var height = that._getHeight();

            if (this.options.autoHeight) {
                this.option({
                    width: width,
                });

                this.element.css("min-height", "");
            } else {
                this.option({
                    width: width,
                    height: height,
                });
            }

            var content$ = this.element;
            var dialogWidth = content$.width();
            var dialogHeight = content$.height();
            this._resizeElements(dialogWidth, dialogHeight);
            this.element.css("display", "");
        },
        _resizeElements: function () {
            //Can override
        },
        _triggerSaveCompleted: function () {
            var event = jQuery.Event("saveCompleted");
            this.element.trigger(event);
        },
        _triggerRefreshData: function (data) {
            var event = jQuery.Event("refreshData", data);
            this.element.trigger(event);
        }
    });