import React from "react";
import { Message, type SemanticCOLORS } from "semantic-ui-react";
import SystemOneModal, { type ModalWidth } from "../components/modal";

/**
 * This component is used as a non-React way of displaying modal
 * This should only be used for modals where you just want to show some text
 * but requires zero user interaction besides closing the modal
 *
 * If you need more customization or user input handling, please do not use this component
 */

const defaultSize: ModalWidth = "small";

const GenericModal = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const [size, setSize] = React.useState<ModalWidth>(defaultSize);
  const [isHtml, setIsHtml] = React.useState<boolean>(false);
  const [color, setColor] = React.useState<SemanticCOLORS>("blue");

  window.displayGenericModal = (newTitle: string, newMessage: string, newSize: ModalWidth, color: SemanticCOLORS = "blue") => {
    setIsOpen(true);
    setMessage(newMessage);
    setTitle(newTitle);
    setSize(newSize);
    setColor(color);
  };

  window.displayGenericModalWithHtml = (newTitle: string, html: string, newSize: ModalWidth, color: SemanticCOLORS = "blue") => {
    window.displayGenericModal(newTitle, html, newSize, color);
    setIsHtml(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setSize(defaultSize);
    setIsHtml(false);
  };

  return <SystemOneModal
    isOpen={isOpen}
    title={title}
    width={size}
    handleClose={onClose}
  >
    <div className="grow1">
      <Message color={color}>
        {!isHtml && message}
        {isHtml && <div dangerouslySetInnerHTML={{ __html: message }}></div>}
      </Message>
    </div>
    <div className="flex margin-top-big justify-end">
      <div>
        <button
          type="button"
          className="button-new primary"
          onClick={onClose}>
          {RESX.Buttons.BtnOk}
        </button>
      </div>
    </div>
  </SystemOneModal>;
};

export default GenericModal;
