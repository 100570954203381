import { getArticleUrl } from "../typescript/react/supportbeacon/model";

// IIFE to hide helper vars from global scope
(function () {
    var supportBeaconId = "support-beacon-sidebar";

    window.OpenSupportSidebar = function (id) {
        var sidebarEl = document.getElementById(supportBeaconId);
        if (sidebarEl) {
            sidebarEl.style.transform = "translateX(0%)";
            window.showSupportArticle(id);
        } 
        // Some pages like modals don't have a sidebar
        else {
            const articleUrl = getArticleUrl(id, "");
            window.open(articleUrl, "_blank");
        }
    };

    window.CloseSupportSidebar = function () {
        var sidebarEl = document.getElementById(supportBeaconId);
        sidebarEl.style.transform = "translateX(100%)";
    }
})();

$(document).ready(function () {
    $("body").on("click", "a[data-beacon-article-sidebar]", function () {
        window.OpenSupportSidebar(this.dataset.beaconArticleSidebar);
    })
});
