function AppPhaseFilter() {
    this.DisplayConfirmed = false;
    this.DisplayConfirmedInput = document.createElement('input');
    this.DisplayConfirmedInput.name = 'DisplayConfirmed';

    this.DisplayOptions = false;
    this.DisplayOptionsInput = document.createElement('input');
    this.DisplayOptionsInput.name = 'DisplayOptions';

    this.DisplayCancelled = false;
    this.DisplayCancelledInput = document.createElement('input');
    this.DisplayCancelledInput.name = 'DisplayCancelled';
}

AppPhaseFilter.prototype = new UI.FormElement();

AppPhaseFilter.prototype.Render = function () {
    while (this.holderDiv.hasChildNodes()) {
        this.holderDiv.removeChild(this.holderDiv.lastChild);
    }

    this.RenderRow(this.holderDiv, this.DisplayConfirmedInput, this.DisplayConfirmed, this.DisplayConfirmedLabel);
    this.RenderRow(this.holderDiv, this.DisplayOptionsInput, this.DisplayOptions, this.DisplayOptionsLabel);
    this.RenderRow(this.holderDiv, this.DisplayCancelledInput, this.DisplayCancelled, this.DisplayCancelledLabel);

    this.holderDiv.appendChild(warningDiv);
};
AppPhaseFilter.prototype.RenderRow = function (node, obj, value, label) {
    var div = document.createElement('div');
    div.style.clear = 'both';
    node.appendChild(div);

    this.RenderInput(div, obj, value);
    this.RenderLabel(div, obj, label);
};
AppPhaseFilter.prototype.RenderInput = function (node, obj, value) {
    var that = this;
    var div = document.createElement('div');
    div.style.styleFloat = 'left';
    div.style.cssFloat = 'left';
    div.style.width = '21px';
    node.appendChild(div);

    obj.type = 'checkbox';
    obj.checked = value;
    obj.defaultChecked = value;
    obj.value = 'true';
    obj.onclick = function () { that.OnClick(); };
    div.appendChild(obj);
};
AppPhaseFilter.prototype.RenderLabel = function (node, obj, lbl) {

    var div = document.createElement('div');
    div.style.styleFloat = 'left';
    div.style.cssFloat = 'left';
    div.style.cursor = 'pointer';
    div.onclick = function () { obj.click(); };
    node.appendChild(div);

    div.appendChild(document.createTextNode(lbl));

};
AppPhaseFilter.prototype.OnClick = function () {
    this.DisplayConfirmed = this.DisplayConfirmedInput.checked;
    this.DisplayOptions = this.DisplayOptionsInput.checked;
    this.DisplayCancelled = this.DisplayCancelledInput.checked;

    return this.Validate();
};
AppPhaseFilter.prototype.Validate = function () {
    var isSuccess = (this.DisplayConfirmed || this.DisplayOptions || this.DisplayCancelled);

    if (isSuccess) {
        this.HideWarning();
    } else {
        this.ShowWarning(RESX.GeneralLabels.PleaseMakeAChoice);
    }

    return isSuccess;
};
