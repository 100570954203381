import React from "react";
import { Message } from "semantic-ui-react";
import Article from "./article";
import { PlaceholderContent, PlaceholderTitle } from "./articleplaceholders";
import { getArticleUrl, type RelevantArticle } from "./model";

declare const site: server.dto.SiteData;

interface State {
  title: string
  content: string
  articleId: number
  slug: string
  relevantArticles: RelevantArticle[]
  hasError: boolean
}

export default class SideBarContent extends React.Component<any, State> {
  state: State = {
    title: "",
    content: null,
    slug: "",
    articleId: undefined,
    relevantArticles: [],
    hasError: false
  };

  constructor(props) {
    super(props);
    (window as any).showSupportArticle = this.showSupportArticle.bind(this);
  }

  // This function is called from outside of React
  showSupportArticle = (id: number) => {
    if (this.state.articleId === id && !this.state.hasError) {
      return;
    }

    this.setState({ articleId: id, content: null, title: "", slug: "", relevantArticles: [], hasError: false },
      this.tryFetchSupportArticle);
  };

  tryFetchSupportArticle = async () => {
    try {
      await this.fetchSupportArticle();
    } catch {
      this.setState({ hasError: true });
    }
  };

  fetchSupportArticle = async () => {
    const result = await window.CoreApi.Article.GetArticle(this.state.articleId);

    const translations = result.data.translations;

    const uiLanguage = serverReference.languages.find(lang => lang.twoLetterTag === site.culture.uiLanguage)?.value ?? "english";

    const translation = translations.find(trans => trans.language === uiLanguage) ||
          translations.find(trans => trans.language === "english");

    const relevantArticles: RelevantArticle[] = result.data.relatedArticles.map<RelevantArticle>(rel => {
      const relevantArticleTranslation = rel.translations.find(t => t.language === uiLanguage) ?? rel.translations.find(t => t.language === "english");
      return {
        title: relevantArticleTranslation.title,
        slug: relevantArticleTranslation.slug,
        articleId: rel.articleId
      }
    });

    this.setState({ relevantArticles, content: translation.content, title: translation.title, slug: translation.slug });
  };

  close = () => {
    (window as any).CloseSupportSidebar();
  };

  render() {
    const { articleId, slug, title, content, relevantArticles, hasError } = this.state;

    if (hasError) {
      return <>
        <div className="flex gray-250 padding-large">
          <div className="grow1 padding-large margin-right huge_text">{RESX.GeneralWarnings.Warning}</div>
          <div><a className="icon-x" onClick={this.close}></a></div>
        </div>

        <div className="margin-big">
          <Message color="red">{RESX.GeneralLabels.SomethingUnexpectedHappened}</Message>
        </div>
      </>;
    }

    if (content == null) {
      return <>
        <div className="flex gray-250 padding-large">
          <div className="grow1 padding-large margin-right huge_text"><PlaceholderTitle /></div>
          <div><a className="icon-x" onClick={this.close}></a></div>
        </div>

        <PlaceholderContent />
      </>;
    }

    const contentEl = () => <div dangerouslySetInnerHTML={{ __html: content }}></div>;
    const articleUrl = getArticleUrl(articleId, slug);

    return <>
      <div className="flex gray-250 padding-large">
        <div className="grow1 padding-large margin-right huge_text">{title}</div>
        {slug && <div className="margin-right"><a href={articleUrl} target="_blank" className="icon-external-link small" rel="noreferrer"></a></div>}
        <div><a className="icon-x" onClick={this.close}></a></div>
      </div>

      <Article
        renderContent={contentEl}
        relevantArticles={relevantArticles}
      />
    </>;
  }
}
