import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "../css/jquery-ui/jquery-ui.scss"; // Import instead of include

import "../css/systemone/_all.scss";

import "../css/sprite.scss";

import "../css/sone.advancing.scss";

// @import "sone.popover.scss";
import "../css/jquery-ui-override.scss"; // Import instead of include

import "../sone-semantic-theme/semantic.less"; // ES6

import "../css/fontastic/fontastic.scss";

import * as moment from "moment";

interface IMyWindow extends Window {
  moment: any
}

declare let window: IMyWindow;
window.moment = moment;
