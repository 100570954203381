declare const $: any;

$.fn.appendTypeToggleIcons = function (config) {
  const settings = {
    name: "types",
    options: []
  };

  if (config) {
    $.extend(settings, config);
  }

  const icons$ = [];

  const set = (values: string[]) => {
    $.each(icons$, (index, icon$) => {
      const val = icon$.val();

      const matches = $.grep(values, (n) => (n.toString() === val.value.toString()));

      icon$.set(matches.length > 0);
    });
  };

  const get = () => {
    const values = [];
    $.each(icons$, (index, image$) => {
      const val = image$.val();
      if (val.selected) {
        values.push(val);
      }
    });
    return values;
  };

  this.val = (values?: string[]) => ((typeof values === "undefined") ? get() : set(values));

  const div$ = $(this).appendDiv({
    class: "s1_phasesfilter"
  });

  const myChangeEvent = () => {
    const event = jQuery.Event("mychange");
    const types = get();
    this.trigger(event, [types]);
  };

  $.each(config.options, (index, option) => {
    const value = option.value;
    const cssClass = option.icon;
    const title = option.formatted;

    const imgDiv$ = div$.appendDiv({
      style: "margin-left:5px;display:inline-block;"
    });

    const icon$ = imgDiv$.appendToggleIcon(cssClass, value, title);
    icons$.push(icon$);

    icon$.click(() => {
      myChangeEvent();
    });
  });

  this.get = get;
  this.set = set;
  return this;
};

// Turn it into a module to satisfy `isolatedModules`
export {};