$.widget("sone.itemchooser", {
    options: {
        authorizedToModify: false,
        autocompleteExcludeKeys: null,
        inputClass: "",
        placeholder: "",
        data: [],
        disabled: false,
        label: [],
        max: 99,
        min: 0,
        onNavigationConfirmChanges: false,
        url: [],
        globalVarname: null,
        id: null,
        autocompleteMap: function (value) {
            return value;
        },
        autocompleteApi: null,
        types: []
    },
    _create: function () {
        var that = this;

        UI.ItemchoosersDict[this.options.globalVarname] = this;

        this.types = this.options.types.slice();

        this.created = new Date().toISOString();

        //Provide data in cool new format in options.data. autocompleteMap() will map it to the old KeyValue structure.
        this.data = this.options.data.slice(0).map(function (i) {
            return that.options.autocompleteMap(i);
        });
        this.disabled = this.options.disabled;

        //add list
        var listDiv = $("<div />");
        this.element.append(listDiv);
        this.listControl$ = $(listDiv)
            .itemchooserList({
                authorizedToModify: this.options.authorizedToModify,
                url: this.options.url,
                parent$: this
            });

        //add buttons and input box
        this.inputControl$ = $("<div />")
            .itemchooserInput({
                inputClass: this.options.inputClass,
                authorizedToModify: this.options.authorizedToModify,
                placeholder: this.options.placeholder,
                label: this.options.label,
                url: this.options.url,
                parent$: this,
                autocompleteMap: this.options.autocompleteMap,
                autocompleteApi: this.options.autocompleteApi,
                types: this.types
            });

        this.element.append(this.inputControl$);
    },
    _init: function () {

    },
    addItem: function (input, event) {
        this.hideWarning();

        //input should be keyValue

        var obj = input;

        // check if AddItem is actually an update
        for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].key === obj.key) {

                this.data[i] = obj; // override with new data
                this._onChange(event);
                return;
            }
        }

        if (this.data.length > (this.options.max - 1)) {
            this.data.splice(this.options.max - 1, 1);
        }

        this.data.push(obj);
        this._onChange(event);
    },
    removeByKey: function (key) {
        this.hideWarning();

        var removedObj = null;

        for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].key != key) {
                continue;
            }

            removedObj = this.data[i];

            this.data.splice(i, 1);
        }

        if (removedObj) {
            var event = jQuery.Event("myremove");
            event.obj = removedObj;
            this.element.trigger(event);
        }

        this.validate();

        this._onChange(removedObj);

        this.focus();
    },
    clearAll: function () {
        this.hideWarning();
        this.data = [];
        this.validate();

        this._onChange();
    },
    focus: function () {
        this.inputControl$.find("input").focus();
    },
    clearInput: function () {
        this.inputControl$.find("input").val("");
    },
    dialogResult: function (wr) {
        if (!wr) {
            return;
        }

        // make a shallow copy of the JSON result object because IE will destroy object after modal window close.
        var obj = jQuery.extend({}, wr);

        var keyValue = this.options.autocompleteMap(obj.value);

        this.addItem(keyValue);
    },
    getKeys: function () {
        var result = [];

        for (var i = 0; i < this.data.length; i++) {
            result.push(this.data[i].key);
        }

        return result;
    },
    getItems: function () {
        var result = [];
        for (var i = 0; i < this.data.length; i++) {
            result.push(this.data[i]);
        }
        return result;
    },
    setItems: function (data) {
        this.hideWarning();

        //Provide data in old KeyValueSublabel format

        if (!data) {
            this.data = [];
        } else {
            this.data = data.slice(0);
            this._onChange();
        }
    },
    _onChange: function (originalEvent) {
        this.listControl$.itemchooserList("fillFromData");
        this.inputControl$.itemchooserInput("fillFromData");

        if (this.options.onNavigationConfirmChanges) {
            formObj.setConfirmUnload(true);
        }

        var event = jQuery.Event("mychange");
        event.originalEvent = originalEvent;
        event.keys = this.getKeys();
        event.items = this.getItems();
        this.element.trigger(event);
    },
    validate: function () {
        if (this.data.length >= this.options.min) {
            return true;
        }
        if (this.disabled) {
            return true;
        }

        this.showWarning();
        return false;
    },
    disable: function (value) {
        this.disabled = value;
        if (value) {
            this.clearAll();
        } else {
            this._onChange();
        }
    },
    showWarning: function (message) {
        this.inputControl$.find("input:not([disabled])").addClass("s1_warningborder");
    },
    hideWarning: function () {
        this.inputControl$.find("input:not([disabled])").removeClass("s1_warningborder");
    },
    getResultFunc: function () {
        return "UI.itemchooserExecuteDialogResult(\"" + this.options.globalVarname + "\", result)";
    }
});

$.widget("sone.itemchooserList", {
    options: {
        authorizedToModify: false,
        url: [],
        label: [],
        parent$: null
    },
    _create: function () {

    },
    _init: function () {
        this.fillFromData();
    },
    fillFromData: function () {
        this.element.empty();
        if (this.options.parent$.disabled) {
            return;
        }
        this._appendItems();
    },
    _appendItems: function () {
        var items = this.options.parent$.getItems();
        if (items.length === 0) {
            this._appendHidden(this.element, "");
            return;
        }

        items.sort(soneTools.sort_by("value", false, function (a) {
            return a.toUpperCase();
        }));

        var tableDiv = $("<div />", {
            "class": "itemchooserTable"
        });
        this.element.append(tableDiv);

        for (var i = 0; i < items.length; i++) {
            this._appendItemRow(tableDiv, items[i]);
        }
    },
    _appendItemRow: function (tableDiv, rowData) {

        var rowDiv = $("<div />", {
            "class": "flex"
        });
        tableDiv.append(rowDiv);

        this._appendClearBtnCell(rowDiv, rowData);

        this._appendLabelsCell(rowDiv, rowData);
        if (this.options.authorizedToModify) {
            this._appendEditBtnCell(rowDiv, rowData);
        }

        this._appendHidden(rowDiv, rowData.key);
    },
    _appendHidden: function (rowDiv, value) {
        var name = this.options.parent$.element.attr("id");
        var input = $("<input />", {
            "type": "hidden",
            "name": name,
            "value": value
        });
        rowDiv.append(input);
    },
    _appendClearBtnCell: function (rowDiv, rowData) {
        var that = this;

        var cellDiv = rowDiv.appendDiv({
            "style": "padding-right:5px;padding-top:3px;",
            "click": function (e) {
                e.stopImmediatePropagation();
                that.options.parent$.removeByKey(rowData.key);
            }
        });

        cellDiv.appendA({
            "class": "icon-x small"
        });
    },
    _appendLabelsCell: function (rowDiv, rowData) {

        var cellDiv = $("<div />", {
            "class": "grow1",
            "text": rowData.value
        });
        rowDiv.append(cellDiv);

        if (rowData.sublabel) {
            var sublabelDiv = $("<div />", {
                "text": rowData.sublabel,
                "class": "s1_small s1_gray"
            });

            sublabelDiv.html(sublabelDiv.html().replaceAll("&lt;br/&gt;", "<br/>"));
            cellDiv.append(sublabelDiv);
        }

    },
    _appendEditBtnCell: function (rowDiv, rowData) {
        var that = this;

        var cellDiv = rowDiv.appendDiv({
            "click": function () {
                that._openEditDialog(rowData.key);
            }
        });

        cellDiv.appendA({
            "class": "icon-pencil-1"
        });
    },
    _openEditDialog: function (key) {
        var resultFunc = this.options.parent$.getResultFunc();
        var url = this.options.parent$.options.url.edit.replace("{key}", key);
        SystemOneLibrary.exports.Utils.openPopupModal(url, 1000, 750, resultFunc);
    }
});

$.widget("sone.itemchooserInput", {
    options: {
        authorizedToModify: false,
        inputClass: "",
        url: [],
        placeholder: "",
        parent$: null,
        minChars: 1,
        autocompleteMap: function (value) {
            return value;
        },
        autocompleteApi: null,
        types: []
    },
    _create: function () {
        this._appendDisabledRow(this.element);
        this._appendSearchRow(this.element);
        this._appendNewRow(this.element);
    },
    _appendAutoComplete: function (target, appendTo) {
        var that = this;

        target.soneautocomplete({
            appendTo: appendTo,
            source: function (request, callback) {
                var excludeKeys = that._getAutocompleteExcludeKeys();
                soneTools.executeFunctionByName(that.options.autocompleteApi, window, request.term, excludeKeys, that.options.types)
                    .then(function (result) {
                        var mappedData = result.map(function (a) {
                            return that.options.autocompleteMap(a);
                        });

                        callback(mappedData);
                    });
            },
            open: function () {
                if (soneTools.isMobile()) {
                    $(".ui-autocomplete").off("menufocus hover mouseover mouseenter");
                }
            },
            select: function (event, data) {
                if (data) {
                    that.options.parent$.addItem(data.item, event);
                }
                that.autocompleteControl$.val("");
                event.stopImmediatePropagation();
                event.stopPropagation();
                return false;
            },
            autoFocus: !soneTools.isMobile()
        });

        if (this.options.inputClass) {
            target.removeClass("ui-autocomplete-input");
            target.addClass(this.options.inputClass);
        }
    },
    _init: function () {
        this.fillFromData();
    },
    fillFromData: function () {
        if (this._showDisabledSearchRow()) {
            this.disabledSearchRow$.show();
            this.disabledSearchRow$.css("display", "");
        } else {
            this.disabledSearchRow$.hide();
        }

        if (this._showEnabledSearchRow()) {
            this.enabledSearchRow$.show();
            this.enabledSearchRow$.css("display", "");
        } else {
            this.enabledSearchRow$.hide();
        }

        if (this._showNewRow()) {
            this.newRow$.show();
            this.newRow$.css("display", "");
        } else {
            this.newRow$.hide();
        }
    },
    _showDisabledSearchRow: function () {
        var items = this.options.parent$.getItems();
        if (items.length >= this.options.parent$.options.max) {
            return false;
        }
        return (this.options.parent$.disabled);
    },
    _showEnabledSearchRow: function () {
        var items = this.options.parent$.getItems();
        if (items.length >= this.options.parent$.options.max) {
            return false;
        }
        if (this.options.parent$.disabled) {
            return false;
        }
        return true;
    },
    _showNewRow: function () {
        var items = this.options.parent$.getItems();
        if (items.length >= this.options.parent$.options.max) {
            return false;
        }
        if (this.options.parent$.disabled) {
            return false;
        }
        if (this.options.url.newItem === null || this.options.url.newItem === undefined) {
            return false;
        }
        return this.options.authorizedToModify;
    },
    _appendDisabledRow: function (node$) {
        this.disabledSearchRow$ = node$.appendDiv();

        var cellDiv = this.disabledSearchRow$.appendDiv({
            "class": "s1_transparent"
        });

        cellDiv.appendA({
            "class": "icon-addressbook"
        });

        var inputCellDiv = this.disabledSearchRow$.appendDiv({});
        inputCellDiv.appendInput({
            "type": "text",
            "disabled": "true"
        });
    },
    _appendSearchRow: function (node$) {

        this.enabledSearchRow$ = $("<div />", {
            "class": "input-group ui fluid icon input",
        });

        this._appendSelectButtonCell(this.enabledSearchRow$);
        this._appendSearchInputCell(this.enabledSearchRow$);

        node$.append(this.enabledSearchRow$);

    },
    _appendSelectButtonCell: function (rowDiv) {
        if (!this.options.parent$.options.url.select) {
            return;
        }

        var that = this;

        var cellDiv = rowDiv.appendButton({
            "type": "button",
            "class": "blue",
            "click": function () {
                that._openSelectDialog();
            },
            "id": this.options.parent$.options.id + "search"
        });

        cellDiv.appendI({
            "class": "icon-magnifier mini"
        });
    },
    _appendSearchInputCell: function (node$) {
        var that = this;

        this.autocompleteControl$ = $("<input />", {
            "type": "text",
            "focusout": function () {
                $(this).val("");
                $(this).soneautocomplete("destroy");
                that._appendAutoComplete($(this), that.element);
            },
            placeholder: this.options.placeholder
        });

        this._appendAutoComplete(this.autocompleteControl$, node$);

        node$.append(this.autocompleteControl$);

        node$.appendI({
            "class": "search icon",
            "aria-hidden": true
        });
    },
    _appendNewRow: function (node$) {
        var that = this;

        this.newRow$ = node$.appendButton({
            "type": "button",
            "class": "button-new primary margin-top-small fluid",
            "click": function () {
                that._openNewDialog();
            },
            "text": this.options.label.newItem
        });
    },
    focus: function () {
        this.autocompleteControl$.focus();
    },
    _getAutocompleteExcludeKeys: function () {
        var keys = this.options.parent$.getKeys();
        if (this.options.parent$.options.autocompleteExcludeKeys !== null) {
            keys.push(this.options.parent$.options.autocompleteExcludeKeys);
        }
        return keys;
    },
    _openNewDialog: function () {
        var resultFunc = this.options.parent$.getResultFunc();
        var url = this.options.url.newItem;
        SystemOneLibrary.exports.Utils.openPopupModal(url, 1000, 750, resultFunc);
    },
    _openSelectDialog: function () {
        var resultFunc = this.options.parent$.getResultFunc();
        var url = this.options.parent$.options.url.select;
        var params = {
            "excludeKeys": this.AutocompleteExcludeKeys
        };
        url = urlTools.addParameters(url, params);
        SystemOneLibrary.exports.Utils.openPopupModal(url, 1000, 750, resultFunc);
    }
});