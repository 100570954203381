Array.prototype.Contains = function (val) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] === val) {
            return true;
        }
    }
    return false;
};

Array.prototype.unique = function () {
    var r = [];
    o:
        for (var i = 0, n = this.length; i < n; i++) {
            for (var x = 0, y = r.length; x < y; x++) {
                if (r[x] === this[i]) {
                    continue o;
                }
            }
            r[r.length] = this[i];
        }
    return r;
};

String.prototype.nounToLower = function () {
    return this.toLowerCase();
};

if (typeof String.prototype.endsWith !== 'function') {
    String.prototype.endsWith = function (suffix) {
        return this.indexOf(suffix, this.length - suffix.length) !== -1;
    };
}