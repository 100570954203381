function ArtistAttendeeFilter() {

}

ArtistAttendeeFilter.prototype.ModeChange = function (value) {
    if (value.toString() == '1') {
        $('#ContactId').itemchooser('disable', true);
        $('#CompanyId').prop('disabled', false);
        $('#CompanyId').focus();
    } else {
        $('#CompanyId').prop('disabled', true);
        $('#ContactId').itemchooser('disable', false);
    }
};

