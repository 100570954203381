/*global UI */
UI.Form = function () {
    this.submitCounter = 0;
    this.SubmitTimeoutId = null;
    this.ResetDoubleSubmitTimeOutId = null;
    this.IsPostback = null;

    this.hasErrors = false;
    this.isConfirmationPage = false;
};
UI.Form.prototype.SetActionValue = function (action) {
    $('#action').val(action);
    console.log("SetActionValue called", action);
};
UI.Form.prototype.setHasError = function () {
    this.hasErrors = true;
};
UI.Form.prototype.DelaySubmit = function (action) {
    var that = this;
    this.ClearSubmitTimeout();
    this.SubmitTimeoutId = setTimeout(function () {
        that.SubmitWithValidation(action);
    },
        1000);
};
UI.Form.prototype.SubmitWithoutValidation = function (action) {
    this.Submit(action);
};
UI.Form.prototype.SubmitWithValidation = function (action) {
    if (!this.Validate()) {
        this.scrollToFirstError();
        return false;
    }
    this.Submit(action);
};
UI.Form.prototype.OnSubmit = function () {
    if (!this.Validate()) {
        this.scrollToFirstError();
        return false;
    }

    if (!this.ProhibitDoubleSubmit()) {
        return false;
    }

    this.setConfirmUnload(false);
    return true;
};
UI.Form.prototype.ProhibitDoubleSubmit = function () {
    this.SetResetDoubleSubmitTimeOut();

    this.submitCounter += 1;

    if (this.submitCounter > 3) {
        document.getElementById('dialogError').innerHTML =
            RESX.GeneralLabels.PleaseBePatientTheServerIsProcessingYourRequest;
        $('#dialogError').dialog2('open');
    }

    if (this.submitCounter > 1) {
        return false;
    }

    return true;
};
UI.Form.prototype.SetResetDoubleSubmitTimeOut = function () {
    var that = this;

    //Check if double submit timeout is set, to ensure uniqueness of timer
    if (this.ResetDoubleSubmitTimeOutId !== null) {
        return;
    }

    //Reset the submitcounter after 5 seconds
    this.ResetDoubleSubmitTimeOutId = setTimeout(function () {
        that.submitCounter = 0;
        that.ResetDoubleSubmitTimeOutId = null;
    },
        5000);
};
UI.Form.prototype.ClearSubmitTimeout = function () {
    clearTimeout(this.SubmitTimeoutId);
    this.SubmitTimeoutId = null;
};
UI.Form.prototype.Submit = function (action) {
    this.ClearSubmitTimeout();

    if (!this.ProhibitDoubleSubmit()) {
        return false;
    }

    $("#P").val(1);
    $("#action").val(action);

    this.setConfirmUnload(false);

    document.getElementById("form1").submit();
};
UI.Form.prototype.formUnloadMessage = function () {
    return 'You have pending unsaved changes.';
};

UI.Form.prototype.scrollToFirstError = function () {
    const el = $(":input.s1_warningborder, .s1_warningborder :input, .ui.input.error");
    if (el.length) {
        soneTools.scrollTo(el);
    }
};

UI.Form.prototype.setConfirmUnload = function (on) {

    var that = this;
    if (!UI.Form.isConfirmationPage) {
        return;
    }

    //if (on && UI.Form.debugConfirmations) {
    //    $('#debuginformation').css("visibility", "visible");
    //    $('#debuginformation').text('unsaved changes');
    //    $('#debuginformation').off('click').on('click', function () {
    //        that.setConfirmUnload(false);
    //    });
    //} else if (UI.Form.debugConfirmations) {
    //    $('#debuginformation').css("visibility", "hidden");
    //}

    if (on) {
        $(window).bind("beforeunload",
            function () {
                return that.formUnloadMessage();
            });
    } else {
        $(window).unbind("beforeunload");
    }
};

// Check for unsaved changes before leaving
UI.Form.prototype.setConfirmForInputs = function (context) {
    var that = this;

    if (UI.Form.isConfirmationPage) {
        $('input:text, textarea', context).not('.nocommitcheck').keyup(function () {
            that.setConfirmUnload(true);
        });

        $('input, select', context).not('input:text, .nocommitcheck').change(function () {
            that.setConfirmUnload(true);
        });
    }
}