export interface RelevantArticle {
  articleId: number
  slug: string
  title: string
}

export const getArticleUrlWithoutSlug = (articleId: number) => {
  const articleUrlBase = `${site.promoSiteUrl}/${site.culture.uiLanguage}/support/article`;
  return `${articleUrlBase}/${articleId}`;
};

export const getArticleUrl = (articleId: number, slug: string) => {
  const articleUrlBase = `${site.promoSiteUrl}/${site.culture.uiLanguage}/support/article`;
  return `${articleUrlBase}/${articleId}-${slug}`;
};

export const getRelevantArticleUrl = (article: RelevantArticle) => getArticleUrl(article.articleId, article.slug);