import * as Cookies from "js-cookie";
import "./sone-ui-form";
declare const UI: any;
declare let formObj: any;

UI.onStartUp = function (settings) {
    $.ajaxSetup({
        dataType: "json",
        async: true,
        type: "POST",
        cache: false,
        contentType: "application/json; charset=utf-8"
    });

    const minYear = 2001;
    const maxYear = new Date().getFullYear() + 15;

    $.datepicker.setDefaults({
        showOn: "button",
        buttonText: "<a class='icon-calendar' />",
        changeMonth: true,
        changeYear: true,
        shortYearCutoff: 50,
        yearRange: minYear + ":" + maxYear,
        firstDay: site.culture.firstDayOfWeek,
        monthNamesShort: serverReference.monthNames,
        dayNamesMin: serverReference.abbreviatedDayNames,
        dateFormat: site.culture.jQueryDatePattern,
        prevText: RESX.DateTime.PreviousMonth,
        nextText: RESX.DateTime.NextMonth
    });

    formObj = new UI.Form();
    formObj.setConfirmForInputs(null);

    $("#googleLogIn").on("click", () => {
        formObj.Submit(1);
    });

    UI.Form.isConfirmationPage = settings.isConfirmationPage;
    UI.Form.IsPostBack = settings.pageIsPostBack;

    UI.InitSubHeaderTR();
    UI.InitSubHeaderDiv();
};

UI.InitSubHeaderTR = function () {

    function setCookie(headerId, value) {
        Cookies.set(headerId, value, { expires: 365, path: "/" });
    }

    function tryGetCookie(sectionId) {
        return (Cookies.get(sectionId) === "true");
    }

    $("TR.s1_openclose").each(function () {
        const that$ = $(this);
        const sectionId = that$.attr("id");
        const sectionSelector = "[section='" + sectionId + "']";

        // check the cookies
        const isClosed = tryGetCookie(sectionId);

        // Hide closed items
        $(sectionSelector).toggle(!isClosed);

        that$.click(
            function () {

                const icon$ = $(this).find("I");

                icon$.toggleClass("icon-angle-up");
                icon$.toggleClass("icon-angle-down");

                if (icon$.hasClass("icon-angle-down")) {
                    that$.find("th").css("padding-bottom", "0");
                } else {
                    that$.find("th").css("padding-bottom", "");
                }

                $(sectionSelector).each(
                    function () {
                        $(this).toggle();
                        setCookie(sectionId, !$(this).is(":visible"));
                    });
            });
    });
};

UI.InitSubHeaderDiv = function () {

    function setCookie(headerId, value) {
        Cookies.set(headerId, value, { expires: 365, path: "/" });
    }

    function tryGetCookie(headerId) {
        return (Cookies.get(headerId) === "true");
    }

    UI.SubHeader_AddHover("DIV.openClosePanel");

    $("DIV.openClosePanel").click(
        function () {
            $(this).next("div").slideToggle();
        }
    );

    // Cookie based logic
    $("DIV.openClosePanelCookie").each(function () {
        const isClosed = tryGetCookie(this.id);

        // Hide closed items
        if (isClosed) {
            $(this).next("div").hide();
        } else {
            $(this).next("div").show();
        }
    });

    UI.SubHeader_AddHover("DIV.openClosePanelCookie");

    $("DIV.openClosePanelCookie").click(
        function () {
            $(this).next("div").slideToggle(400, () => {

                const isClosed = !$(this).next("div").is(":visible");

                // Store setting in cookie
                setCookie(this.id, isClosed);
            });
        }
    );
};

UI.SubHeader_AddHover = function (id) {
    $(id).hover(
        function () {
            $(this).stop();
            $(this).css("background-color", "#1F335B");
        },
        function () {
            $(this).animate({ backgroundColor: "#536DA0" }, 100);
        }
    );
};

// Common UI elements
UI.Button = function (label, hexColor, imgSrc) {
    const img = document.createElement("img");
    img.style.marginRight = "4px";
    img.src = imgSrc;

    this.label = label;
    this.div = document.createElement("div");
    this.div.appendChild(img);
    this.div.appendChild(document.createTextNode(label));
    this.div.className = "sone-general-button";
    this.div.style.backgroundColor = hexColor;

    return this.div;
};
UI.Button.prototype.onclick = function (e) {
    this.div = e;
};
UI.Row = function (label) {
    this.tr = document.createElement("tr");

    this.labelCell = document.createElement("td");
    this.labelCell.appendChild(document.createTextNode(label));
    this.tr.appendChild(this.labelCell);

    this.valueCell1 = document.createElement("td");
    this.valueCell1.colSpan = "2";
    this.tr.appendChild(this.valueCell1);

    return this;
};
UI.Row.prototype.SetRequired = function () {
    const span = document.createElement("span");
    span.className = "sone-general-required";
    span.appendChild(document.createTextNode("*"));
    this.labelCell.appendChild(span);
};
UI.DoubleRow = function (label) {
    this.label = label;

    this.sourceValue = null;
    this.targetInputObj = null;

    this.tr = document.createElement("tr");

    this.labelCell = document.createElement("td");
    this.labelCell.appendChild(document.createTextNode(label));
    this.tr.appendChild(this.labelCell);

    this.valueCell1 = document.createElement("td");
    // this.valueCell1.style.width = '293px';
    this.tr.appendChild(this.valueCell1);

    this.valueCell2 = document.createElement("td");
    this.tr.appendChild(this.valueCell2);

    return this;
};
UI.DoubleRow.prototype.SetRequired = function () {
    const span = document.createElement("span");
    span.className = "sone-general-required";
    span.appendChild(document.createTextNode("*"));
    this.labelCell.appendChild(span);
};
UI.DoubleRow.prototype.SetSourceMergeCell = function (sourceLabel, sValue, tInputObj) {
    this.sourceValue = sValue;
    this.targetInputObj = tInputObj;
    this.valueCell1.appendChild(document.createTextNode(sourceLabel));

    this.ColorMergeCell();
};
UI.DoubleRow.prototype.ColorMergeCell = function () {
    if ((this.targetInputObj.disabled) || (this.sourceValue === this.targetInputObj.GetValue())) {
        this.valueCell1.style.backgroundColor = "";
        this.valueCell1.style.cursor = "normal";
        this.valueCell1.onclick = null;
    } else {
        this.valueCell1.style.backgroundColor = "#ffdada";
        this.valueCell1.style.cursor = "pointer";
        this.valueCell1.onclick = function () {
            this.targetInputObj.SetValue(this.sourceValue);

            this.style.backgroundColor = "";
            this.style.cursor = "";
            this.style.onclick = null;

            const e = jQuery.Event("merge", { value: this.sourceValue });
            $(this.targetInputObj.input).trigger(e);
        };
    }
};
UI.GetWidth = function () {
    return $("#contentholder").width();
};
// Recursively set country within states
for (const country of window.serverReference.countries) {
    for (const state of country.states) {
        (state as any).Country = country;
    }
}

UI.redirectPage = function (redirectUrl) {
    document.location.href = redirectUrl;
};

UI.ActivateMySelection = function (api) {

    function mySelectionToggle(id, state) {
        let type;
        let url;

        if (state) {
            window.CoreApi.MySelection.Add({ ids: [id], mainArea: "clientFile" });
        } else {
            window.CoreApi.MySelection.Remove({ ids: [id], mainArea: "clientFile" });
        }

        return $.ajax({
            async: true,
            type: type,
            url: url
        });
    }

    function mySelectionClear() {
        return window.CoreApi.MySelection.ClearAll("clientFile");
    }

    $("#clearmyselectionbtn").css("cursor", "pointer");

    $("#clearmyselectionbtn").on("click", () => {
        $(".s1_row_selected").removeClass("s1_row_selected");
        $(".s1_cell_selectrow input[type=checkbox]").prop("checked", false);
        mySelectionClear();
    });

    $("td.s1_cell_selectrow input[type=checkbox]").on("click", function () {
        const checkbox$ = $(this) as any;
        const itemId = parseInt(checkbox$.val(), 10);
        const isChecked = checkbox$.is(":checked");
        const row$ = checkbox$.closest("tr");
        row$.toggleClass("s1_row_selected", isChecked);
        mySelectionToggle(itemId, isChecked);
    });
};

UI.ItemchoosersDict = {};

UI.itemchooserExecuteDialogResult = function (key, result) {
    UI.ItemchoosersDict[key].dialogResult(result);
};
