$.widget("sone.invoicedataexport", {
    options: {

    },
    _create: function () {
        //Connect bookkeeping select
        this.bookkeepingsSelect$ = $("#bookkeeping");
        if (!this.bookkeepingsSelect$.length) {
            throw "Cannot find Bookkeeping";
        }

        //Connect exportmask
        this.exportmaskSelect$ = $("#exportmask");

        //connect period radios
        this.periodRadios$ = $("input:radio[name=period]");
        if (!this.periodRadios$.length) {
            throw "Cannot find Period radios";
        }

        var periodRadiosRow$ = this.periodRadios$.closest("tr");

        //Connect addressee choice

        this.addresseeKeyRadios$ = $("input:radio[name=addresseekeytype]");
        if (!this.addresseeKeyRadios$.length) {
            throw "Cannot find addresseekeytype";
        }

        var addresseeKeyRadiosRow$ = this.addresseeKeyRadios$.closest("tr");

        //Connect custom values
        this.customValuesCell$ = $("#customvalues");
        if (!this.customValuesCell$.length) {
            throw "Cannot find customvalues";
        }

        var customValuesRow$ = this.customValuesCell$.closest("tr");

        var openconfigurationbutton$ = $("#openconfigurationbutton");
        openconfigurationbutton$.click(function () {
            periodRadiosRow$.toggle();
            addresseeKeyRadiosRow$.toggle();
            customValuesRow$.toggle();
        });

        this._setData(this.options.data);

        this._initOverviewLinks();
    },
    _init: function () {
        var that = this;

        this.bookkeepingsSelect$.change(function () {
            document.location.href = '?BookkeepingId=' + $(this).val();
        });

        this.exportmaskSelect$.change(function () {
            that._submitToApi()
                .then(function (resp) {
                    that._setData(resp.data);
                });
        });

        this.periodRadios$.change(function () {
            that._submitToApi();
        });

        this.addresseeKeyRadios$.change(function () {
            that._submitToApi();
        });
    },
    _setData: function (data) {
        this.exportmaskSelect$.val(data.exportMask);

        this.periodRadios$.setRadioValue(data.period);

        this.addresseeKeyRadios$.setRadioValue(data.addresseeKeyType);

        this._createCustomValueInputs(data.customValues);
    },
    _createCustomValueInputs: function (keyvalues) {
        var that = this;

        //Clear existing node & holder array
        this.customValueInputs$ = [];
        this.customValuesCell$.empty();

        $.each(keyvalues,
            function (index, kv) {
                that._addCustomValueInput(kv.key, kv.value);
            });
    },
    _addCustomValueInput: function (key, value) {
        var that = this;

        var div$ = this.customValuesCell$.appendDiv({
            style: "margin-bottom:1px;"
        });

        var id = "custom_" + key;

        var label = $("<label />", {
            "for": id,
            "style": "display: inline-block;width:260px;",
            "text": key
        });
        div$.append(label);

        var kvObj$ = {
            key: key,
            valueInput$: div$.appendInput({
                id: id,
                placeholder: "value",
                type: "text",
                maxlength: 15,
                value: value,
                style: "width:150px;",
                blur: function () {
                    that._checkShouldAddCustomValueInput();
                    that._submitToApi();
                }
            })
        };

        kvObj$.valueInput$.restrictInput({
            allowDigits: true,
            allowLetters: true,
            allowUnderscore: true
        });

        this.customValueInputs$.push(kvObj$);

    },
    _checkShouldAddCustomValueInput: function () {
        var config = this._retrieveValues();
        this._createCustomValueInputs(config.customValues);
    },
    _initOverviewLinks: function () {
        var bookkeepingId = this.bookkeepingsSelect$.val();

        $('.GetFile')
            .each(
                function () {

                    $(this).removeAttr("href");

                    $(this)
                        .click(
                            function () {

                                var year = $(this).attr('year');
                                var month = $(this).attr('month');
                                var fileType = $(this).attr('fileType');

                                document.location.href = `${window.site.coreApiBaseUrl}/salesdata/export/bookkeeping/${bookkeepingId}/${year}/${month}/${fileType}`;
                            }
                        );
                }
            );

        $('.Lock')
            .each(
                function () {

                    $(this).removeAttr("href");

                    $(this)
                        .click(
                            function () {

                                var year = $(this).attr('year');
                                var month = $(this).attr('month');

                                WebApi.Invoices.LockPerMonth(bookkeepingId, month, year)
                                    .done(
                                        function () {
                                            var json = {
                                                "bookkeepingId": bookkeepingId
                                            };

                                            var url = urlTools.addParameters('/invoice/dataexport', json);

                                            document.location.href = url;
                                        }
                                    );
                            }
                        );
                }
            );
    },
    _retrieveValues: function () {

        var exportMask = this.options.data.exportMask;

        if (this.exportmaskSelect$.length) {
            exportMask = this.exportmaskSelect$.val();
        }

        var customValues = [];

        //Remove empty row
        $.each(this.customValueInputs$, function (index, obj) {
            customValues.push({
                key: obj.key,
                value: obj.valueInput$.val()
            });
        });

        var config = {
            bookkeepingId: this.bookkeepingsSelect$.val(),
            period: this.periodRadios$.filter(":checked").val(),
            exportMask: exportMask,
            addresseeKeyType: this.addresseeKeyRadios$.filter(":checked").val(),
            customValues: customValues
        };

        return config;
    },
    _submitToApi: function () {
        var values = this._retrieveValues();
        return CoreApi.Bookkeeping.UpdateDataExportConfig(values.bookkeepingId, values);
    }
});